import React, { useEffect } from 'react';
import backArrow from '../static/img/back-arrow.svg'

const BackButton = ({backToPreviousPage, module, submodule, backLink}) => {
    useEffect(() => {
        if(module || module === 0) {
            localStorage.setItem('currentModule', module.toString());
        }
        if(submodule || submodule === 0) {
            localStorage.setItem('currentSubmodule', submodule.toString());
        }
    }, [module, submodule]);

    return <div className="backRow">
        {!backToPreviousPage ? <a href={backLink ? backLink : "/"} className="backRow__btn">
            <img className="img" src={backArrow} alt="powrót" />
            Powrót do menu
        </a> : <button onClick={() => { window.history.back(); }} className="backRow__btn">
            <img className="img" src={backArrow} alt="powrót" />
            Powrót
        </button>}
    </div>
};

export default BackButton;
