import React from 'react';

const TwoButtonsSort = ({label, sort, setSort}) => {
  const handleAscendingSort = () => {
    setSort((prevState) => (prevState === 1 ? 0 : 1));
  }

  const handleDescendingSort = () => {
    setSort((prevState) => (prevState === 2 ? 0 : 2));
  }

  return <div className="shopItem__filterWrapper shopItem__filterWrapper--array">
    <label>
      <span className="key">
        {label}
      </span>
      <button className={sort === 1 ? "btn btn--productSort bold"  : "btn btn--productSort" }
              onClick={handleAscendingSort}>
        Sortuj rosnąco
      </button>
      <button className={sort === 2 ? "btn btn--productSort bold" : "btn btn--productSort"}
              onClick={handleDescendingSort}>
        Sortuj malejąco
      </button>
    </label>
  </div>
};

export default TwoButtonsSort;
