import React from 'react';
import TableCellStringValue from './TableCellStringValue';

const TableCronJobsRow = ({item, index}) => {
  const printCreateDatetime = (datetime) => {
    return `${datetime ? datetime.split('T')[0] : ''}<br/>
                ${datetime ? datetime.split('T')[1].slice(0, 8) : ''}`;
  }

  return <div className="shopItem shopItem--product flex"
              key={index}>
    <span className="id">
        #{item.sj_id}
    </span>

    <TableCellStringValue label={'Sklep'}
                          value={item.w_name ? item.w_name : 'Wszystkie'} />
    <TableCellStringValue label={'Nazwa CRONa'}
                          value={item.sj_job_name} />
    <TableCellStringValue label={'Data utworzenia'}
                          value={printCreateDatetime(item.sj_create_datetime)} />
    <TableCellStringValue label={'Status'}
                          value={item.sj_status} />
    <TableCellStringValue label={'Kod odpowiedzi'}
                          value={item.sj_response_code}
                          valueClass={item.sj_response_code?.toString()[0] === '2' ? "value value--positive" : "value value--negative"} />
  </div>
}

export default TableCronJobsRow;
