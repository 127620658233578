import React from 'react';
import videoIcon from '../static/img/video-camera.svg';

const VideoUploader = ({label, video, setVideo, setVideoFile, handleFileUpload, clearVideoInput, videoRef, setCurrentTime}) => {
  return <div className="editor__files__section">
    <h2 className="editor__files__section__header">
      {label}
    </h2>

    <div className="editor__videoWrapper">
      {!video ? <>
        <input type="file"
               accept="video/mp4"
               className="editor__files__input"
               onChange={(e) => { handleFileUpload(e, setVideo, setVideoFile); }} />
        <div className="editor__videoWrapper__placeholderContent">
          <p className="editor__videoWrapper__placeholderContent__text">
            Kliknij tutaj lub upuść plik aby dodać video
          </p>
          <img className="editor__videoWrapper__icon" src={videoIcon} alt="video" />
        </div>
      </> : <button className="editor__videoWrapper__clearInput" onClick={clearVideoInput}>
        &times;
      </button>}

      <video className={video ? "editor__video" : "editor__video opacity-0"}
             onTimeUpdate={(e) => { setCurrentTime(e.target.currentTime); }}
             ref={videoRef}
             crossOrigin="anonymous"
             src={video}
             controls>

      </video>

      {!video ? <span className="editor__video__placeholder">

      </span> : ''}
    </div>
  </div>
};

export default VideoUploader;
