import axios from "axios";
import { getConfigWithAuthHeader, getLoggedUserUsername } from './others';
import { emptyArrayReponse } from './content';

const getProductsByUser = () => {
    try {
        return axios.get(`/externalSystemProducts/getProductsByUser/${getLoggedUserUsername()}`, getConfigWithAuthHeader());
    }
    catch(e) {
        return emptyArrayReponse;
    }
}

const forceFetchNewProducts = (websiteId) => {
    try {
        return axios.post('/externalSystemProducts/forceFetchNewProducts', {
            websiteId
        });
    }
    catch(e) {
        return emptyArrayReponse;
    }
}

const getWebsiteByProduct = (productId) => {
    try {
        return axios.get(`/externalSystemProducts/getWebsiteByProduct/${productId}`);
    }
    catch(e) {
        return {};
    }
}

export { getProductsByUser, forceFetchNewProducts, getWebsiteByProduct }
