import { useEffect } from 'react';

const useClearArray = (value, setValue) => {
  useEffect(() => {
    if(value) {
      setValue([]);
    }
  }, [value]);
}

export default useClearArray;
