import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { uniqueByProp } from '../helpers/others';
import { getProductsByUser } from '../helpers/product';

const initialState = {
  allProducts: []
}

const fetchAllProducts = createAsyncThunk('products/fetchAllProducts', async () => {
  const response = await getProductsByUser();
  return response.data;
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload.map((item) => {
        return {
          ...item,
          predefinedBlocks: item.b_json_value ? [... new Set(JSON.parse(item.b_json_value).filter((item) => {
            return item.findIndex((item) => {
              return item.findIndex((item) => {
                return item.isPredefinedBlock;
              }) !== -1;
            }) !== -1;
          }).flat(5).map((item) => (item.predefinedBlockId ? item.predefinedBlockId : 0)).filter((item) => (item)))] : [],
          categories: item.p_external_system_associated_menu_branch_name ? uniqueByProp(JSON.parse(item.p_external_system_associated_menu_branch_name), 'menuName') : []
        }
      })
    });
  }
});

export { fetchAllProducts };
export default productsSlice.reducer;
