import React from 'react';

const TableCellArrayValue = ({label, value}) => {
  return <span className="productItem__col">
      <span className="key">
          {label}
      </span>
      <span className="value">
        {value?.map((item, index) => {
          return <span className="value__arrayItem"
                       key={index}>
                {item}
            </span>
        })}
    </span>
  </span>
};

export default TableCellArrayValue;
