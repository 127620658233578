import React from 'react';
import TableCellStringValue from './TableCellStringValue';
import TableCellArrayValue from './TableCellArrayValue';
import penIcon from '../static/img/pen.svg';
import TableCellCMSBlocks from './TableCellCMSBlocks';

const TableProductsRow = ({item, index, galleries, openProductGallery, setOptionsMenu}) => {
  return <div className="shopItem shopItem--product flex"
              key={index}>
    <TableCellStringValue label={'Kod zewn. systemu'}
                          value={item.p_external_id} />
    <TableCellStringValue label={'Sklep'}
                          value={item.w_name} />
    <TableCellStringValue label={'Nazwa towaru'}
                          value={item.p_productName} />
    <TableCellStringValue label={'Kod kreskowy'}
                         value={item.p_productUniversalSizeProducerCode} />
    <TableCellStringValue label={'Producent'}
                         value={item.p_producerName} />
    <TableCellArrayValue label={'Kategoria'}
                         value={item.categories} />
    <TableCellArrayValue label={'Predefiniowane bloki CMS'}
                          value={item.predefinedBlocks} />

    <TableCellCMSBlocks item={item} />

    <TableCellStringValue label={'Parametry oraz ich wartości'}
                          value={'TODO'} />
    <TableCellStringValue label={'Stan magazynowy'}
                          value={item.p_stocks_total_availability} />

    <span className="productItem__col productItem__col--buttons">
        <button className="productItem__btn productItem__btn--gallery productItem__btn--gallery--fontSmall"
                disabled={galleries[index]?.length === 0}
                onClick={() => { openProductGallery(index); }}>
        Wyświetl zdjęcia hostowane w idosell dla tego towaru
        </button>
        <button className="productItem__btn productItem__btn--editor"
                onClick={() => { setOptionsMenu(index); }}>
            Opcje
            <img className="img" src={penIcon} alt="edycja" />
        </button>
    </span>
  </div>
};

export default TableProductsRow;
