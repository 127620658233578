import React from 'react';

const ChooseVariant = ({currentVariantIndex, setCurrentVariantIndex, currentContextIndex,
                         blockJsonValue, addVariant, deleteVariant}) => {
  return <h3 className="blockEditor__header">
    Wariant: <span>
        <select className="select"
                value={currentVariantIndex}
                onChange={(e) => { setCurrentVariantIndex(parseInt(e.target.value)); }}>
            {blockJsonValue[currentContextIndex].map((item, index) => {
              return <option key={index} value={index}>{index+1}</option>
            })}
        </select>
    </span>

    <button className="btn btn--variant btn--add"
            onClick={addVariant}>
      Dodaj
    </button>
    <button className="btn btn--variant btn--delete"
            onClick={deleteVariant}>
      Usuń
    </button>
  </h3>
}

export default ChooseVariant;
