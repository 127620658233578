import React from 'react';
import { Circles } from 'react-loader-spinner'

const Loader = () => {
    return <div className="center w-100">
        <Circles color="#0A73FE" height={50} width={50} />
    </div>
};

export default Loader;
