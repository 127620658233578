import React from 'react';

const ToolbarColorPicker = ({label, color, onClick}) => {
  return <div className="blockEditor__section__header__type">
    <h4>
      {label}
    </h4>
    <button className="blockEditor__section__header__colorBtn"
            style={{background: color}}
            onClick={onClick}>

    </button>
  </div>
};

export default ToolbarColorPicker;
