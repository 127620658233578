import React from 'react';
import DatePicker from 'react-datepicker';
import ClearRangeCheckbox from './ClearRangeCheckbox';

const DateRangeFilter = ({label, start, setStart, end, setEnd, clear, setClear}) => {
  return <div className="shopItem__filterWrapper shopItem__filterWrapper--array">
    <div className="shopItem__filterWrapper--flex">
      <span className="key">
          {label}
      </span>
      <span className="rangeSpan">
          <span>od:</span>
          <DatePicker locale="pl"
                     dateFormat="dd.MM.yyyy"
                     selected={start}
                     onChange={setStart} />
          </span>
      <span className="rangeSpan">
          <span>do:</span>
          <DatePicker locale="pl"
                       dateFormat="dd.MM.yyyy"
                       selected={end}
                       onChange={setEnd} />
      </span>
    </div>

    <ClearRangeCheckbox clear={clear}
                        setClear={setClear} />
  </div>
};

export default DateRangeFilter;
