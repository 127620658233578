import React from 'react';
import closeIcon from '../static/img/close-icon.svg';

const CloseModalButton = ({onClick}) => {
  return <button className="btn--close" onClick={onClick}>
    <img className="img" src={closeIcon} alt="zamknij" />
  </button>
};

export default CloseModalButton;
