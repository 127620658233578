import React, { useEffect, useState } from 'react';
import ImageDropdown from "./ImageDropdown";
import ContentEditable from 'react-contenteditable';
import { getImageById } from '../helpers/richBlocks';
import setImageUrl from '../helpers/setImageUrl';

const Type3Block = ({block, changeTexts, changeImages, removeImage, readOnly, editor, editorType}) => {
  const [img1, setImg1] = useState('');

  useEffect(() => {
    if(block) {
      if(typeof block.imagesArray[0] !== 'number') {
        setImg1(block.imagesArray[0]);
      }
      else {
        getImageById(block.imagesArray[0])
          .then((res) => {
            if(res?.data) {
              setImg1(setImageUrl(res?.data.file_name));
            }
          });
      }
    }
  }, [block]);

    const handleChange = (e) => {
      changeTexts(0, e.target.value);
    }

    return <div className="blockEditor__block blockEditor__block--flex" style={{
        background: block.backgroundColor
    }}>
      {!readOnly ? <ImageDropdown handleFileUpload={changeImages}
                                  removeImage={removeImage}
                                  imageIndex={0}
                                  image={img1}
                                  block={block} /> : <figure>
        <img className="img" src={img1} alt="img" />
      </figure>}

      <label className="blockEditor__block__textareaWrapper">
        {!readOnly ? (editorType === 0 ? <ContentEditable html={block.textsArray[0]}
                                                          className="blockEditor__textarea blockEditor__textarea--fullWidth"
                                                          innerRef={editor}
                                                          style={{
                                                            color: block.textColor,
                                                            background: block.backgroundColor
                                                          }}
                                                          onChange={handleChange} /> : <textarea className="blockEditor__textarea blockEditor__textarea--html"
                                                                                                 value={block.textsArray[0]}
                                                                                                 onChange={handleChange}>

          </textarea>) : <div className="blockEditor__textarea blockEditor__textarea--fullWidth"
                                                                        dangerouslySetInnerHTML={{
                                        __html: block.textsArray[0]
        }}>

        </div>}
      </label>
    </div>
};

export default Type3Block;
