import React, { useEffect, useRef, useState } from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import {updateShop} from "../helpers/shop";
import BackButton from "../components/BackButton";
import Select from 'react-select'
import LoadingPage from "../components/LoadingPage";
import { error as err } from "../helpers/content";
import Loader from "../components/Loader";
import SubmitButton from '../components/SubmitButton';
import ErrorTextInfo from '../components/ErrorTextInfo';
import SuccessTextInfo from '../components/SuccessTextInfo';
import InputPrimary from '../components/InputPrimary';
import useUserShops from '../hooks/useUserShops';
import copyIcon from '../static/img/clipboard.svg';
import checkIcon from '../static/img/updated.svg';
import { copyToClipboard } from '../helpers/others';

const UserShops = () => {
    const { shops, shopChoices } = useUserShops();

    const copyIconRef = useRef(null);
    const checkIconRef = useRef(null);

    const [currentShop, setCurrentShop] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [apiRestriction, setApiRestriction] = useState('');
    const [imageHosting, setImageHosting] = useState(false);

    useEffect(() => {
        if(shops?.length && currentShop >= 0) {
            const shop = shops[currentShop];

            setId(shop.id);
            setName(shop.name);
            setUrl(shop.url);
            setLogin(shop.api_user);
            setPassword(shop.api_password);
            setImageHosting(shop.image_hosting);
            setApiKey(shop.api_key);
            setApiRestriction(shop.api_key_restriction);
        }
    }, [currentShop, shops]);

    const handleSelect = (data) => {
        setCurrentShop(data.value);
    }

    const toggleCheckbox = (e, setValue) => {
        e.preventDefault();
        setValue(p => !p);
    }

    const generateApiKey = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for(let i=0; i<40; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        setApiKey(randomString);
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const updateResult = await updateShop(id, name, url, login, password,
              apiKey, apiRestriction, imageHosting);

            if(updateResult) {
                setSuccess('Sklep został zaktualizowany');
                setLoading(false);
            }
            else {
                setError(err);
                setLoading(false);
            }
        }
        catch(err) {
            setError(err);
            setLoading(false);
        }
    }

    const copyApiKeyToClipboard = () => {
        copyToClipboard(apiKey);

        copyIconRef.current.style.visibility = 'hidden';
        checkIconRef.current.style.visibility = 'visible';

        setTimeout(() => {
            copyIconRef.current.style.visibility = 'visible';
            checkIconRef.current.style.visibility = 'hidden';
        }, 5000);
    }

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton module={0} />

        {shops?.length ? <main className="main w">
            <h1 className="main__header">
                Edycja sklepów
            </h1>

            <div className="shopEditor">
                <label className="label">
                    Wybierz sklep:
                    <Select options={shopChoices}
                            placeholder="Wybierz sklep"
                            value={shopChoices[currentShop]}
                            onChange={handleSelect}
                            isSearchable={true} />
                </label>
            </div>

            {currentShop >= 0 ? <div className="shopSettings">
                <div className="shopSettings__form">
                    <InputPrimary label={'Nazwa sklepu w panelu Extendommerce'}
                                  inputClass={'input--color input--big'}
                                  value={name}
                                  setValue={setName} />
                    <InputPrimary label={'Skrócona nazwa (snippet)'}
                                  inputClass={'input--color input--big'}
                                  value={shops[currentShop]?.custom_snippet_name}
                                  disabled={true} />
                    <InputPrimary label={'Adres URL do sklepu IdoSell'}
                                  inputClass={'input--color input--big'}
                                  value={url}
                                  setValue={setUrl} />
                    <InputPrimary label={'Login konta API'}
                                  inputClass={'input--color input--big'}
                                  value={login}
                                  setValue={setLogin} />
                    <InputPrimary label={'Hasło do konta API'}
                                  inputClass={'input--color input--big'}
                                  value={password}
                                  setValue={setPassword} />

                    <div className="label label--apiKey">
                        <span className={'w-100'}>
                            Klucz API Extendommerce
                        </span>
                        <input className={'input--color input--big input--apiKey'}
                               type={'text'}
                               value={apiKey}
                               disabled={true} />
                        <button className={'btn btn--generateKey'}
                                onClick={generateApiKey}>
                            Generuj nowy
                        </button>
                        <button className={'btn btn--copyToClipboard'}
                                onClick={copyApiKeyToClipboard}>
                            <img className={'img'} ref={copyIconRef} src={copyIcon} alt={'kopiuj'} />
                            <img className={'img img--checkIcon'} ref={checkIconRef} src={checkIcon} alt={'kopiuj'} />
                        </button>
                    </div>

                    <InputPrimary label={'Host, z którego API akceptuje połączenie (* oznacza dowolny host)'}
                                  inputClass={'input--color input--big'}
                                  value={apiRestriction}
                                  setValue={setApiRestriction} />
                </div>

                <div className="shopSettings__checkboxes">
                    <div className="labelCheckboxContainer">
                        <label className="label label--checkbox">
                            <button className="btn--checkbox"
                                    onClick={(e) => { toggleCheckbox(e, setImageHosting); }}>
                                {imageHosting ? <span className="btn--checkbox__selected"></span> : ''}
                            </button>
                            Zapisuj i hostuj na serwerze Extendommerce źródłowe zdjęcie (obraz) bez jakiejkolwiek obróbki
                        </label>
                        <p className="label--checkbox__description">
                            Umożliwa późniejsze generowanie dowolnych rozdzielczości (funcje lupy i inne), lecz pochłania dysk,
                            co związane jest ze zwiększoną opłatą abonamentową. POnadto umożliwa masowe usuwanie zdjęć z IdoSell
                            i wstawianie tych z Extendommerce - wtedy Extendommerce jest priorytetowym źródłem zdjęc towarów.
                            Ułatwia generowanie aukcji i np. wgrywanie im zdjęć z bloków predefiniowanych CMS.
                        </p>
                    </div>
                </div>

                <ErrorTextInfo content={error} />
                <SuccessTextInfo content={success} />

                {loading ? <Loader /> : <SubmitButton onClick={handleSubmit}
                                                      content={'Edytuj sklep'} />}
            </div> : ''}

        </main> : <LoadingPage />}
    </div>
}

export default UserShops;
