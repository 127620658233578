import { API_URL } from './video';

const setImageUrl = (filename) => {
  console.log(filename);

  if(filename) {
    if(filename.substring(0, 4) === 'http') {
      return filename;
    }
    else {
      return `${API_URL}/${filename}`;
    }
  }
  else {
    return '';
  }
}

export default setImageUrl;
