import React from 'react';

const SectionTypeButton = ({templateId, sectionIndex, index, chooseType, icon}) => {
  return <button className={templateId === index + 1 ? "blockEditor__section__header__type__item blockEditor__section__header__type__item--current" : "blockEditor__section__header__type__item"}
                key={index}
                onClick={() => { chooseType(sectionIndex, index + 1); }}>
    <img className="img" src={icon} alt="typ-1" />
  </button>;
};

export default SectionTypeButton;
