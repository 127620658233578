import React from 'react';
import { getDate } from '../helpers/others';
import TableCellStringValue from './TableCellStringValue';

const TablePackagesRow = ({item, index, ordersCostVisible}) => {
  return <div className="shopItem shopItem--product flex packing__packages__item"
              key={index}>
    <TableCellStringValue label={'Id zamówienia'}
                          value={item.orderId} />
    {ordersCostVisible ? <TableCellStringValue label={'Wartość zamówienia'}
                                               value={item.cost} /> : ''}
    <TableCellStringValue label={'Ilość paczek'}
                          value={item.numberOfPackages} />
    <TableCellStringValue label={'Przypisano'}
                          value={getDate(item.assignmentDate.substring(0, 10))} />
  </div>
};

export default TablePackagesRow;
