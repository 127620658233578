import axios from 'axios';
import { getLoggedUserId } from './others';

const getParametersByWebsite = (websiteId) => {
  return axios.get(`/parameters/getByWebsite/${websiteId}`);
}

const getParametersByProduct = (id) => {
  return axios.get(`/parameters/getByProduct/${id}`);
}

const getParametersByUser = () => {
  return axios.get(`/parameters/getByUser/${getLoggedUserId()}`);
}

const updateParameters = (websiteId, productId, externalId, parameters) => {
  return axios.post(`/externalSystemProducts/massEdition`, {
    id: productId,
    externalId: externalId,
    websiteId: websiteId,
    parameters: parameters,
    productEdition: true
  });
}

export { getParametersByWebsite, getParametersByUser,
  getParametersByProduct, updateParameters }
