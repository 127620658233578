import React from 'react';

const ModulesMenuButton = ({item, index, showSubmodules}) => {
  return item.link ? <a href={item.link}
                        className="moduleItem"
                        key={index}>
    <img className="icon" src={item.icon} alt={item.header} />
    <span className="header">
        {item.header}
    </span>
  </a> : <button onClick={() => { showSubmodules(index, 1); }}
                 className="moduleItem"
                 key={index}>
    <img className="icon" src={item.icon} alt={item.header} />
    <span className="header">
        {item.header}
    </span>
  </button>
};

export default ModulesMenuButton;
