import React, {useEffect, useState} from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import { error as err } from "../helpers/content";
import {isPasswordStrong} from "../helpers/others";
import {changeUserPassword} from "../helpers/user";
import Loader from "../components/Loader";
import InputPrimary from '../components/InputPrimary';
import ErrorTextInfo from '../components/ErrorTextInfo';
import SuccessTextInfo from '../components/SuccessTextInfo';
import SubmitButton from '../components/SubmitButton';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        setError('');
    }, [password, repeatPassword, oldPassword]);

    const validateForm = () => {
        if(!oldPassword || !password || !repeatPassword) {
            setError('Uzupełnij wymagane pola');
            return 0;
        }
        if(password !== repeatPassword) {
            setError('Podane hasła nie są identyczne');
            return 0;
        }
        if(!isPasswordStrong(password)) {
            setError('Hasło powinno mieć co najmniej 8 znaków długości, zawierać co najmniej jedną cyfrę i co najmniej jedną wielką literę');
            return 0;
        }

        handleSubmit();
    }

    const handleSubmit = () => {
        setLoading(true);

        changeUserPassword(oldPassword, password)
            .then((res) => {
                if(res?.status === 200) {
                    setSuccess('Hasło zostało zmienione');
                }
                else {
                    setError(err);
                }

                setLoading(false);
            })
            .catch((err) => {
               if(err?.response?.data?.statusCode === 400) {
                   setError(err.response.data.message);
               }
               else {
                   setError(err);
               }
               setLoading(false);
            });
    }

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton />

        <main className="main w">
            <h1 className="main__header">
                Zmiana hasła
            </h1>
            <div className="changePassword">
                <InputPrimary label={'Stare hasło'}
                              type={'password'}
                              value={oldPassword}
                              setValue={setOldPassword} />
                <InputPrimary label={'Nowe hasło'}
                              type={'password'}
                              value={password}
                              setValue={setPassword} />
                <InputPrimary label={'Powtórz nowe hasło'}
                              type={'password'}
                              value={repeatPassword}
                              setValue={setRepeatPassword} />

                <ErrorTextInfo content={error} />
                <SuccessTextInfo content={success} />

                {loading ? <Loader /> : <SubmitButton content={'Zmień hasło'}
                                                      onClick={validateForm} />}
            </div>
        </main>
    </div>
};

export default ChangePassword;
