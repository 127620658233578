import React from 'react';

const InputPrimary = ({label, disabled, value, setValue,
                        type = 'text', inputClass = 'input--color'}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  }

  return <label className="label">
    {label}
    <input className={inputClass}
           type={type}
           value={value}
           disabled={disabled}
           onChange={handleChange} />
  </label>
};

export default InputPrimary;
