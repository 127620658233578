import React from 'react';

const ChooseFrameHeader = ({frameType, setFrameType}) => {
  return <div className="flex flex--start editor__secondRow__headerWrapper">
    <h3 className="editor__secondRow__header">
      Wybierz odpowiednią klatkę
    </h3>
    <div className="flex flex--start frameTypeButtons">
      <button className={frameType === 0 ? "btn btn--frameType btn--frameType--selected" : "btn btn--frameType"}
              onClick={() => { setFrameType(0); }}>
        Main video
      </button>
      <button className={frameType === 1 ? "btn btn--frameType btn--frameType--selected" : "btn btn--frameType"}
              onClick={() => { setFrameType(1); }}>
        Snippet video
      </button>
    </div>
  </div>
};

export default ChooseFrameHeader;
