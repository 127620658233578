import React from 'react';
import { Tooltip } from 'react-tippy';
import penIcon from '../static/img/pen.svg';
import { getImageById } from '../helpers/richBlocks';

const ChooseTitleAndPreview = ({name, setName, currentVariant, productId, websiteAddress, productExternalId}) => {
  const handleChange = (e) => {
    setName(e.target.value);
  }

  const getImage = async (id) => {
    const res = await getImageById(id);
    return res.data.file_name;
  }

  const showHtmlPreview = async () => {
    let currentVariantCopy = currentVariant;

    // Generate images links
    let newCurrentVariantCopy = [];
    for(const item of currentVariantCopy) {
      if(item?.imagesArray) {
        let newImagesArray = [];
        for(const image of item.imagesArray) {
          if(typeof image === 'string') {
            newImagesArray.push(image);
          }
          else if(typeof image === 'number') {
            newImagesArray.push(await getImage(image));
          }
          else {
            newImagesArray.push(window.URL.createObjectURL(image));
          }
        }

        newCurrentVariantCopy.push({
          ...item,
          imagesArray: newImagesArray
        });
      }
      else {
        newCurrentVariantCopy.push(item);
      }
    }

    // Add values to localStorage and open preview in new tab
    localStorage.setItem('block_json', JSON.stringify(newCurrentVariantCopy));
    localStorage.setItem('block_name', name);

    window.open('/podglad-bloku-cms', '_blank');
  }

  return <h2 className="blockEditor__header">
    Edytujesz właśnie: <span>
        <input className="input input--blockName"
               placeholder="Nazwa bloku CMS"
               value={name}
               onChange={handleChange} />
    </span>

    <button className="btn btn--htmlPreview"
            onClick={showHtmlPreview}>
      Podgląd HTML
    </button>

    {productId > 0 ? <Tooltip title="Uwaga! pamiętaj, aby nie edytować długiego opisu produktu lub szablonu aukcji Allegro, bowiem te dane zostaną nadpisane przez extendommerce"
                              position="top"
                              followCursor={true}
                              trigger="mouseenter">
      <a className="btn btn--editProductLink"
         href={`${websiteAddress}/panel/product.php?idt=${productExternalId}`}
         target="_blank">
        <img className="img" src={penIcon} alt="edycja" />
        Edytuj kartę towaru w IdoSell
      </a>
    </Tooltip> : ''}
  </h2>
};

export default ChooseTitleAndPreview;
