import React, {useEffect, useState} from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import {getJobsByUser} from "../helpers/cronJobs";
import TableCronJobsRow from '../components/TableCronJobsRow';

const UserCronJobs = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        getJobsByUser()
            .then((res) => {
                if(res?.status === 200) {
                    setJobs(sortByCreateDatetime(res?.data));
                }
            });
    }, []);

    const sortByCreateDatetime = (jobs = []) => {
        return jobs.sort((a, b) => (a.sj_create_datetime < b.sj_create_datetime ? 1 : -1));
    }

    return <div className="container container--shopList container--cronList">
        <LoggedUserHeader />
        <BackButton module={0}
                    submodule={1} />

        <main className="main w">
            <h1 className="main__header">
                Lista zleconych operacji CRON
            </h1>

            {jobs?.map((item, index) => {
                return <TableCronJobsRow item={item}
                                         index={index} />
            })}
        </main>
    </div>
};

export default UserCronJobs;
