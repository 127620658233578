import React from 'react';
import refreshIcon from '../static/img/refresh.svg';

const PackagesTimeFilterButtons = ({currentFilter, setCurrentFilter, numberOfHours, setNumberOfHours, setRefresh}) => {
  const handleChange = (e) => {
    setNumberOfHours(e.target.value);
  }

  const refresh = () => {
    setRefresh(p => !p);
  }

  return <div className="packing__filterButtons flex">
    <button className={currentFilter === 0 ? "btn btn--packagesFilter btn--packagesFilter--selected" : "btn btn--packagesFilter"}
            onClick={() => { setCurrentFilter(0); }}>
      dziś
    </button>
    <button className={currentFilter === 1 ? "btn btn--packagesFilter btn--packagesFilter--selected" : "btn btn--packagesFilter"}
            onClick={() => { setCurrentFilter(1); }}>
      w ciągu 24 h
    </button>
    <button className={currentFilter === 2 ? "btn btn--packagesFilter btn--packagesFilter--selected" : "btn btn--packagesFilter"}
            onClick={() => { setCurrentFilter(2); }}>
      w ciągu 12 h
    </button>
    <button className={currentFilter === 3 ? "btn btn--packagesFilter btn--packagesFilter--selected" : "btn btn--packagesFilter"}
            onClick={() => { setCurrentFilter(3); }}>
      w ciągu <input className="input input--numberOfHours"
                     type="number"
                     value={numberOfHours}
                     onChange={handleChange} /> h
    </button>
    <button className={currentFilter === 4 ? "btn btn--packagesFilter btn--packagesFilter--selected" : "btn btn--packagesFilter"}
            onClick={() => { setCurrentFilter(4); }}>
      w skali całej dostępnej historii
    </button>
    <button className="btn btn--refresh"
            onClick={refresh}>
      <img className="img" src={refreshIcon} alt="odśwież" />
    </button>
  </div>
};

export default PackagesTimeFilterButtons;
