import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  access: []
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAccess: (state, action) => {
      return {
        ...state,
        access: action.payload
      }
    },
    setUserData: (state, action) => {
      return {
        ...state,
        data: action.payload
      }
    }
  }
});

export const { setUserAccess, setUserData } = userSlice.actions;
export default userSlice.reducer;
