import axios from 'axios'
import { getConfigWithAuthHeader, getLoggedUserId } from './others';
import Cookies from "universal-cookie";

const auth = () => {
    return axios.post('/users/auth', {}, getConfigWithAuthHeader());
}

const loginUser = (username, password) => {
    return axios.post('/users/login', {
        username, password
    });
}

const logoutUser = () => {
    const cookies = new Cookies();
    cookies.remove('access_token', { path: '/' });
    cookies.remove('username', { path: '/' });
    cookies.remove('id', { path: '/' });
    window.location = '/';
}

const changeUserPassword = (oldPassword, newPassword) => {
    return axios.patch('/users/changePassword', {
        userId: getLoggedUserId(),
        newPassword,
        oldPassword
    });
}

const getUserData = () => {
    return axios.get(`/users/getUserData/${getLoggedUserId()}`);
}

export { loginUser, auth, logoutUser, changeUserPassword, getUserData }
