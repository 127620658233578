import Cookies from "universal-cookie";

const getAuthHeader = () => {
    const cookies = new Cookies();
    const jwt = cookies.get('access_token');
    return `Bearer ${jwt}`;
}

const getConfigWithAuthHeader = () => {
    return {
        headers: {
            Authorization: getAuthHeader()
        }
    }
}

const isElementInArray = (el, arr) => {
    return arr.findIndex((item) => {
        return item === el;
    }) !== -1;
}

const showElement = (el) => {
    el.current.style.opacity = '1';
    el.current.style.height = 'auto';
    el.current.style.position = 'relative';
    el.current.style.visibility = 'visible';
}

const hideElement = (el) => {
    el.current.style.opacity = '0';
    el.current.style.height = '0';
    el.current.style.visibility = 'hidden';
    el.current.style.position = 'absolute';
}

const isPasswordStrong = (password) => {
    if(!password) return false;
    if(password.length < 8) return false; // min. 8 characters
    if(!(/\d/.test(password))) return false; // number
    if(password.toLowerCase() === password) return false; // uppercase

    return true;
}

const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
]

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
      ...result,
      [item[key]]: [
          ...(result[item[key]] || []),
          item,
      ],
  }),
  {},
)

const getDate = (str) => {
    const dateArray = str.substring(0, 10)?.split('-');
    return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
}

const addTrailingZero = (n) => {
    if(n <= 9) return `0${n}`;
    return n;
}

const getDateTime = (date) => {
    return `${addTrailingZero(date.getDate())}.${addTrailingZero(date.getMonth()+1)}.${date.getFullYear()}<br/>
    ${addTrailingZero(date.getHours())}:${addTrailingZero(date.getMinutes())}:${addTrailingZero(date.getSeconds())}`;
}

const uniqueByProp = (arr, key) => {
    let newArr = [];

    for(const el of arr) {
        if(!isElementInArray(el[key], newArr)) {
            newArr.push(el[key]);
        }
    }

    return newArr;
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

function focusNextElement () {
    //add all elements we want to include in our selection
    var focussableElements = 'a:not([disabled]), button:not([disabled]), input, [tabindex]:not([disabled]):not([tabindex="-1"])';
    console.log(focussableElements);
    if (document.activeElement && document.activeElement.form) {
        var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
          function (element) {
              //check for visibility while always include the current activeElement
              return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
          });
        var index = focussable.indexOf(document.activeElement);
        if(index > -1) {
            var nextElement = focussable[index + 1] || focussable[0];
            nextElement.focus();
        }
    }
}

function copyToClipboard(text) {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}

const isEscapeClicked = (event) => {
    return event.keyCode === 27;
}

const getQueryParam = (param) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
}

const getLoggedUserUsername = () => {
    const cookies = new Cookies();
    const username = cookies.get('username');

    if(username) {
        return username;
    }
    else {
        return '';
    }
}

const getLoggedUserId = () => {
    const cookies = new Cookies();
    const userId = cookies.get('id');

    if(userId) {
        return userId;
    }
    else {
        return '';
    }
}

const hasAccessTo = (access, right) => {
    return access && (access.includes('all') || access.includes(right));
}

const createUniqueArray = (arr) => {
    return [...new Set(arr)];
}

const removeFalseValues = (arr) => {
    return arr.filter((item) => (item));
}

const redirectToHomepage = () => {
    window.location = '/';
}

const redirectToLoggedUserMenu = () => {
    window.location = '/home';
}

const refreshPage = () => {
    window.location.reload();
}

export { getAuthHeader, isElementInArray, showElement, hideElement, getDateTime, focusNextElement, getQueryParam,
    isPasswordStrong, insert, groupBy, getDate, uniqueByProp, scrollToTop, copyToClipboard, isEscapeClicked,
    getLoggedUserUsername, getLoggedUserId, hasAccessTo, createUniqueArray, removeFalseValues, redirectToHomepage,
    redirectToLoggedUserMenu, getConfigWithAuthHeader, refreshPage }
