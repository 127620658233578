import React from 'react';

const InputLogin = ({label, type, placeholder, icon, value, setValue}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  }

  return <label className="loginForm__label">
    {label}
    <figure className="labelIconWrapper">
      <img className="labelIcon" src={icon} alt={label} />
    </figure>
    <input className="input"
           type={type}
           value={value}
           onChange={handleChange}
           placeholder={placeholder} />
  </label>
};

export default InputLogin;
