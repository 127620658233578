import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Oval } from 'react-loader-spinner'
import checkIcon from '../static/img/green-check.svg'
import ButtonApiQueueFilter from './ButtonApiQueueFilter';
import OvalLoader from './OvalLoader';

const statusesTranslations = {
  success: 'sukces',
  error: 'błąd',
  request: 'oczekujące',
  notAvailableStillTry: 'API nieosiągalne'
}

const FilterApiQueueContext = React.createContext(null);

const ApiQueueView = ({columnToExtractFromPayload}) => {
  const [currentFilter, setCurrentFilter] = useState('request');
  const [filteredApiQueue, setFilteredApiQueue] = useState([]);

  const apiQueue = useSelector((state) => (state.apiQueue.apiQueue));

  useEffect(() => {
    if(apiQueue) {
      const apiQueueCopy = [...apiQueue];
      if(currentFilter) {
        setFilteredApiQueue(apiQueueCopy.filter((item) => (item?.status === currentFilter))?.reverse());
      }
      else {
        setFilteredApiQueue(apiQueueCopy?.reverse());
      }
    }
  }, [currentFilter, apiQueue]);

  return <div className="apiQueue">
    <div className="apiQueue__filters flex">
      <FilterApiQueueContext.Provider value={{ currentFilter, setCurrentFilter }}>
        <ButtonApiQueueFilter value={'request'}>
          Oczekujące
        </ButtonApiQueueFilter>
        <ButtonApiQueueFilter value={''}>
          Wszystkie
        </ButtonApiQueueFilter>
        <ButtonApiQueueFilter value={'error'}>
          Błędne
        </ButtonApiQueueFilter>
        <ButtonApiQueueFilter value={'notAvailableStillTry'}>
          API nieosiągalne
        </ButtonApiQueueFilter>
      </FilterApiQueueContext.Provider>
    </div>

    <div className="apiQueue__main customScroll">
      {filteredApiQueue?.map((item, index) => {
        return <div className="apiQueue__main__item flex"
                    key={index}>
          <span className="apiQueue__main__item__col">
            {item.payload[columnToExtractFromPayload]}
          </span>
          <span className="apiQueue__main__item__col"
                dangerouslySetInnerHTML={{
            __html: item.payload.datetime
          }}>

          </span>
          <span className="apiQueue__main__item__col">
            {statusesTranslations[item.status]}
          </span>
          <span className="apiQueue__main__item__col">
            {item.status === 'request' ? <OvalLoader /> :
              (item.status === 'success' ? <img className="img" src={checkIcon} alt="check" /> :
                <span className="red">{item.errorValue}</span>)}
          </span>
        </div>
      })}
    </div>
  </div>
};

export default ApiQueueView;
export { FilterApiQueueContext }
