import React, {useEffect, useRef, useState} from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import {modules} from "../helpers/content";
import { hasAccessTo, hideElement, showElement } from '../helpers/others';
import Synchronization from "../components/Synchronization";
import { useSelector } from 'react-redux';
import SubmodulesMenuHeader from '../components/SubmodulesMenuHeader';
import ModulesMenuButton from '../components/ModulesMenuButton';

const Homepage = () => {
    const access = useSelector((state) => (state.user.access));

    const [currentModule, setCurrentModule] = useState(-1);
    const [currentSubmodule, setCurrentSubmodule] = useState(-1);
    const [modulesToDisplay, setModulesToDisplay] = useState([]);

    const modulesViewWrapper = useRef(null);
    const firstLvlSubmodulesViewWrapper = useRef(null);
    const secondLvlSubmodulesViewWrapper = useRef(null);

    useEffect(() => {
        // Control which modules to display based on access prop
        let modulesToDisplayTmp = [];

        if(access) {
            if(hasAccessTo('packer_assignment_manager')) {
                modulesToDisplayTmp.push(modules[4]);
            }
            if(hasAccessTo('products_cms_editor') || hasAccessTo('products_list')) {
                modulesToDisplayTmp.push(modules[1]);

                if(hasAccessTo('products_cms_editor')) {

                }
                if(hasAccessTo('products_list')) {

                }
            }
            if(hasAccessTo('all')) {
                modulesToDisplayTmp = modules;
            }

            setModulesToDisplay(modules.map((item) => {
                if(modulesToDisplayTmp.includes(item)) {
                    return item;
                }
                else {
                    return {};
                }
            }));
        }
    }, [access]);

    useEffect(() => {
        const localStorageModule = localStorage.getItem('currentModule');
        const localStorageSubmodule = localStorage.getItem('currentSubmodule');

        if(localStorageModule && localStorageSubmodule) {
            setCurrentModule(parseInt(localStorageModule));
            setCurrentSubmodule(parseInt(localStorageSubmodule));
            localStorage.removeItem('currentModule');
            localStorage.removeItem('currentSubmodule');
        }
        else if(localStorageModule) {
            setCurrentModule(parseInt(localStorageModule));
            localStorage.removeItem('currentModule');
        }
    }, []);

    useEffect(() => {
        if(currentModule === -1) {
            showElement(modulesViewWrapper);
            hideElement(firstLvlSubmodulesViewWrapper);
            hideElement(secondLvlSubmodulesViewWrapper);
        }
        else {
            hideElement(modulesViewWrapper);
            setTimeout(() => {
                showElement(firstLvlSubmodulesViewWrapper);
            }, 200);
        }
    }, [currentModule]);

    useEffect(() => {
        if(currentSubmodule === -1 && currentModule !== -1) {
            showElement(firstLvlSubmodulesViewWrapper);
            hideElement(secondLvlSubmodulesViewWrapper);
        }
        else if(currentModule !== -1) {
            hideElement(firstLvlSubmodulesViewWrapper);
            setTimeout(() => {
                showElement(secondLvlSubmodulesViewWrapper);
            }, 200);
        }

        if(currentSubmodule !== -1) {
            setTimeout(() => {
                hideElement(firstLvlSubmodulesViewWrapper);
            }, 300);
        }
    }, [currentSubmodule]);

    const showSubmodules = (i, submodule = null) => {
        setCurrentModule(i);
    }

    const showSubmodulesSecondLvl = (i, submodule) => {
        setCurrentSubmodule(submodule);
    }

    const isModuleSelected = () => {
        return currentModule !== -1;
    }

    const isSubmoduleSelected = () => {
        return currentSubmodule !== -1;
    }

    return <div className="container container--homepage">
        <LoggedUserHeader />

        <main className="main modulesContainer center">
            {/* ALL MODULES VIEW */}
            <span className="modulesViewWrapper flex" ref={modulesViewWrapper}>
                {modulesToDisplay.map((item, index) => {
                    return item.header ? <ModulesMenuButton item={item}
                                                            index={index}
                                                            showSubmodules={showSubmodules} /> : '';
                })}
            </span>

            {/* 1ST SUBMODULES VIEW */}
            <div className="submodulesViewWrapper" ref={firstLvlSubmodulesViewWrapper}>
                <SubmodulesMenuHeader currentModule={currentModule}
                                      moduleObject={modulesToDisplay[currentModule]}
                                      setCurrentModule={setCurrentModule} />

                {isModuleSelected() ? <span className="modulesViewWrapper flex">
                    {modulesToDisplay[currentModule]?.submodules?.map((item, index) => {
                        return <ModulesMenuButton item={item}
                                                  index={index}
                                                  showSubmodules={item.submodules ? showSubmodulesSecondLvl : showSubmodules} />
                    })}
                </span> : ''}
            </div>

            {/* 2ND SUBMODULES VIEW */}
            <div className="submodulesViewWrapper" ref={secondLvlSubmodulesViewWrapper}>
                {isSubmoduleSelected() ? <>
                    <SubmodulesMenuHeader currentModule={currentSubmodule}
                                          setCurrentModule={setCurrentSubmodule}
                                          moduleObject={modulesToDisplay[currentModule]?.submodules[currentSubmodule]} />

                    <Synchronization modules={modulesToDisplay}
                                     currentModule={currentModule}
                                     currentSubmodule={currentSubmodule} />
                </> : ''}
            </div>
        </main>
    </div>
};

export default Homepage;
