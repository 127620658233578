import React from 'react';
import addIcon from '../static/img/add.svg';

const ButtonAddSection = ({children = 'Dodaj sekcję', onClick}) => {
  return <button className="blockEditor__addSectionBtn blockEditor__addSectionBtn--addSection"
                 onClick={onClick}>
    {children}
    <img className="img" src={addIcon} alt="dodaj-sekcje" />
  </button>
};

export default ButtonAddSection;
