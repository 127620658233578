import React, { useEffect, useState } from 'react';
import LoggedUserHeader from '../components/LoggedUserHeader';
import BackButton from '../components/BackButton';
import { generateHtmlByJson } from '../helpers/richBlocks';

const BlockHtmlPreview = () => {
  const [content, setContent] = useState('');
  const [blockName, setBlockName] = useState('');

  useEffect(() => {
    // Get block/block variant JSON from localStorage
    const jsonValue = localStorage.getItem('block_json');

    if(jsonValue) {
      setBlockName(localStorage.getItem('block_name'));

      generateHtmlByJson(jsonValue)
        .then((res) => {
            if(res?.status === 201) {
              setContent(res?.data);
            }
        });
    }
    else {
      window.location = '/home';
    }
  }, []);

  return <div className="container container--shopList">
    <LoggedUserHeader />
    <BackButton />

    <main className="main w">
      <h1 className="main__header main__header--flex">
        Podgląd bloku CMS {blockName}
      </h1>

      <div dangerouslySetInnerHTML={{
        __html: content
      }}>

      </div>

    </main>
  </div>
};

export default BlockHtmlPreview;
