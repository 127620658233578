import { useEffect } from 'react';

const useClearDateRange = (value, setStart, setEnd) => {
  useEffect(() => {
    if(value) {
      setStart(null);
      setEnd(null);
    }
  }, [value]);
}

export default useClearDateRange;
