import React from 'react';
import Select from "react-select";
import Loader from "./Loader";
import CloseModalButton from './CloseModalButton';

const ShopPicker = ({closeModal, shopChoices, currentShop, handleSelect,
                        action, loading, buttonLabel}) => {
    return <div className="modal modal--shopPicker"
                onClick={closeModal}>
        <div className="modal__inner"
             onClick={(e) => { e.stopPropagation(); }}>
            <CloseModalButton onClick={closeModal} />

            {loading ? <Loader /> : <div className="shopEditor">
                <label className="label">
                    Wybierz sklep:
                    <Select options={shopChoices}
                            placeholder="Wybierz sklep"
                            value={shopChoices[currentShop]}
                            onChange={handleSelect}
                            isSearchable={true} />
                </label>

                <button className="btn btn--submit"
                        onClick={action}>
                    {!buttonLabel ? 'Wymuś pobranie najnowszych produktów' : buttonLabel}
                </button>
            </div>}
        </div>
    </div>
};

export default ShopPicker;
