import React, {useEffect, useState} from 'react';
import SynchronizationOptions from "./SynchronizationOptions";
import ShopPicker from "./ShopPicker";
import { error as err } from "../helpers/content";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { scheduleNewJob } from '../helpers/cronJobs';
import InfoModal from './InfoModal';
import checkIcon from '../static/img/green-check.svg';
import { generateHtmlFromCms } from '../helpers/richBlocks';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedUserId, groupBy } from '../helpers/others';
import { fetchAllProducts } from '../reducers/productsSlice';
import { Tooltip } from 'react-tippy';
import useUserShops from '../hooks/useUserShops';
import ShopAndAllegroPicker from './ShopAndAllegroPicker';
import { getConnectedAllegroAccountsByUser } from '../helpers/allegro';

const Synchronization = ({modules, currentModule, currentSubmodule}) => {
    const { shops, shopChoices } = useUserShops();

    const [syncOptionsVisible, setSyncOptionsVisible] = useState(false);
    const [shopPickerForAllProductsVisible, setShopPickerForAllProductsVisible] = useState(false);
    const [shopPickerForNewProductsVisible, setShopPickerForNewProductsVisible] = useState(false);
    const [shopPickerForImagesBackupVisible, setShopPickerForImagesBackupVisible] = useState(false);
    const [shopPickerForOrdersVisible, setShopPickerForOrdersVisible] = useState(false);
    const [shopPickerForPackagesVisible, setShopPickerForPackagesVisible] = useState(false);
    const [shopPickerForAllegroVisible, setShopPickerForAllegroVisible] = useState(false);
    const [shopPickerForParametersVisible, setShopPickerForParametersVisible] = useState(false);
    const [allegroAccounts, setAllegroAccounts] = useState([]);
    const [syncHtmlVisible, setSyncHtmlVisible] = useState(false);
    const [currentShop, setCurrentShop] = useState(0);
    const [allegroChoices, setAllegroChoices] = useState([]);
    const [currentAllegro, setCurrentAllegro] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    {/* Synchronization options */}
    const [syncCMS, setSyncCMS] = useState(true);
    const [syncParams, setSyncParams] = useState(true);
    const [syncCodes, setSyncCodes] = useState(true);
    const [syncImages, setSyncImages] = useState(true);
    const [syncAllegro, setSyncAllegro] = useState(true);
    const [syncOption, setSyncOption] = useState(0);
    const [syncProductsList, setSyncProductsList] = useState([]);

    const dispatch = useDispatch();
    const userData = useSelector((state) => (state.user.data));
    const products = useSelector((state) => (state.products.allProducts.map((item) => ({
        id: item.p_id,
        externalId: item.p_external_id,
        websiteId: item.p_website_id,
        lastInternalEditDatetime: item.p_last_internal_edit_datetime,
        lastExternalSystemSyncDatetime: item.p_last_external_system_sync_datetime
    }))));

    const getProductById = (id) => {
        return products.find((item) => (item.id === id));
    }

    useEffect(() => {
        getConnectedAllegroAccountsByUser()
          .then((res) => {
              if(res.data) {
                  setAllegroAccounts(res.data);
                  setAllegroChoices(res.data.map((item, index) => {
                      return {
                          label: item.name,
                          value: index
                      }
                  }));
              }
          })
    }, []);

    useEffect(() => {
        if(success || error) {
            setLoading(false);
            setShopPickerForAllProductsVisible(false);
            setShopPickerForNewProductsVisible(false);
            setSyncOptionsVisible(false);
        }
    }, [success, error]);

    const invokeSyncAction = (n) => {
        switch(n) {
            case 1:
                setShopPickerForNewProductsVisible(true);
                break;
            case 2:
                setSyncOptionsVisible(true);
                break;
            case 3:
                setSyncHtmlVisible(true);
                generateHtmlFromCms();
                break;
            case 4:
                setShopPickerForAllProductsVisible(true);
                break;
            case 5:
                setShopPickerForImagesBackupVisible(true);
                break;
            case 6:
                setShopPickerForOrdersVisible(true);
                break;
            case 7:
                setShopPickerForPackagesVisible(true);
                break;
            case 8:
                setShopPickerForAllegroVisible(true);
                break;
            case 9:
                setShopPickerForParametersVisible(true);
                break;
            default:
                break;
        }
    }

    const imagesBackup = () => {
        try {
            // Add imagesBackup job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'imagesBackup', { website_id: websiteId });
            setSuccess('Zadanie kopii zapasowej zdjęć zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const forceFetchNewProductsFromIdoSell = () => {
        try {
            // Add forceFetchAllProducts job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'forceFetchNewProducts', { website_id: websiteId, userId: userData.u_id });
            setSuccess('Zadanie pobrania najnowszych produktów zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const forceFetchAllProductsFromIdoSell = () => {
        console.log('forceFetchAll');

        try {
            // Add forceFetchAllProducts job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'forceFetchAllProducts', { website_id: websiteId, userId: userData.u_id });
            setSuccess('Zadanie pobrania całej bazy produktowej z IdoSell zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const forceFetchOrdersFromIdoSell = () => {
        try {
            // Add forceFetchAllProducts job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'fetchAndUpdateOrdersFromExternalSystem', { website_id: websiteId });
            setSuccess('Zadanie pobrania zamówień z IdoSell zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const forceFetchPackagesFromIdoSell = () => {
        try {
            // Add forceFetchAllProducts job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'fetchAndUpdatePackagesFromExternalSystem', { website_id: websiteId });
            setSuccess('Zadanie pobrania paczek z IdoSell zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const forceFetchParametersFromIdoSell = () => {
        try {
            // Add forceFetchAllParameters job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'forceFetchAllParameters', { website_id: websiteId });
            setSuccess('Zadanie pobierania parametrów z IdoSell zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const updateDataInIdoSellBasedOnExtendommerce = async () => {
        let productsToUpdate;

        if(syncOption === 0) {
            productsToUpdate = products
              .filter((item) => {
                  return (item.lastInternalEditDatetime > item.lastExternalSystemSyncDatetime) || (!item.lastExternalSystemSyncDatetime && item.lastInternalEditDatetime);
              })
              .map((item) => {
                return {
                    id: item.externalId,
                    websiteId: item.websiteId
                }
            })
        }
        else if(syncOption === 1) {
            productsToUpdate = products.map((item) => {
                return {
                    id: item.externalId,
                    websiteId: item.websiteId
                }
            });
        }
        else {
            productsToUpdate = syncProductsList.map((item) => {
                const product = getProductById(parseInt(item.value));

                return {
                    id: product.externalId,
                    websiteId: product.websiteId
                }
            });
        }

        const productsGroupedByWebsite = Object.entries(groupBy(productsToUpdate, 'websiteId'));

        try {
            setLoading(true);

            // Schedule new cron job for each website
            const userId = getLoggedUserId();
            for(const websiteAndProducts of productsGroupedByWebsite) {
                scheduleNewJob(websiteAndProducts[0], 'updateProductsInIdoSell', {
                    syncOptions: {
                        syncCMS,
                        syncParams,
                        syncCodes,
                        syncImages,
                        syncAllegro,
                        updateEditedInExtendommerce: syncOption === 0
                    },
                    website_id: websiteAndProducts[0],
                    products: websiteAndProducts[1],
                    user_id: userId
                });
            }

            setSuccess('Zadanie aktualizowania danych towarów w IdoSell na podstawie danych z Extendommerce zostało dodane do kolejki');

            // Get new datetime of last_external_system_sync_datetime
            dispatch(fetchAllProducts());
        }
        catch(e) {
            console.log(e);
            setError(err);
        }
    }

    const updateDataInAllegroBasedOnExtendommerce = () => {
        try {
            // Add forceFetchAllProducts job to endless cron service
            const websiteId = shops[currentShop].id;
            scheduleNewJob(websiteId, 'updateAllegroAuctions', { website_id: websiteId, allegro_account_id: allegroAccounts[currentAllegro].id });
            setSuccess('Zadanie aktualizacji opisów aukcji na Allegro zostało dodane do kolejki');
        }
        catch(e) {
            setError(err);
        }
    }

    const handleSelectShop = (data) => {
        setCurrentShop(data.value);
    }

    const handleSelectAllegro = (data) => {
        setCurrentAllegro(data.value);
    }

    return <span className="modulesViewWrapper flex">
        {syncOptionsVisible ? <SynchronizationOptions closeModal={() => { setSyncOptionsVisible(false); }}
                                                      loading={!products?.length}
                                                      syncCMS={syncCMS}
                                                      setSyncCMS={setSyncCMS}
                                                      syncParams={syncParams}
                                                      setSyncParams={setSyncParams}
                                                      syncCodes={syncCodes}
                                                      setSyncCodes={setSyncCodes}
                                                      syncImages={syncImages}
                                                      setSyncImages={setSyncImages}
                                                      syncAllegro={syncAllegro}
                                                      setSyncAllegro={setSyncAllegro}
                                                      syncOption={syncOption}
                                                      setSyncOption={setSyncOption}
                                                      syncProductsList={syncProductsList}
                                                      setSyncProductsList={setSyncProductsList}
                                                      action={updateDataInIdoSellBasedOnExtendommerce} /> : ''}

        {shopPickerForNewProductsVisible ? <ShopPicker closeModal={() => { setShopPickerForNewProductsVisible(false); }}
                                                     shopChoices={shopChoices}
                                                     currentShop={currentShop}
                                                     loading={loading}
                                                     buttonLabel="Wymuś pobranie najnowszych produktów"
                                                     action={forceFetchNewProductsFromIdoSell}
                                                     handleSelect={handleSelectShop} /> : ''}

        {shopPickerForAllProductsVisible ? <ShopPicker closeModal={() => { setShopPickerForAllProductsVisible(false); }}
                                               shopChoices={shopChoices}
                                               currentShop={currentShop}
                                               loading={loading}
                                               buttonLabel="Wymuś pobranie wszystkich produktów"
                                               action={forceFetchAllProductsFromIdoSell}
                                               handleSelect={handleSelectShop} /> : ''}

        {shopPickerForImagesBackupVisible ? <ShopPicker closeModal={() => { setShopPickerForImagesBackupVisible(false); }}
                                                       shopChoices={shopChoices}
                                                       currentShop={currentShop}
                                                       loading={loading}
                                                       buttonLabel="Wykonaj backup zdjęć"
                                                       action={imagesBackup}
                                                       handleSelect={handleSelectShop} /> : ''}

        {shopPickerForOrdersVisible ? <ShopPicker closeModal={() => { setShopPickerForOrdersVisible(false); }}
                                                        shopChoices={shopChoices}
                                                        currentShop={currentShop}
                                                        loading={loading}
                                                        buttonLabel="Wymuś pobranie zamówień"
                                                        action={forceFetchOrdersFromIdoSell}
                                                        handleSelect={handleSelectShop} /> : ''}

        {shopPickerForPackagesVisible ? <ShopPicker closeModal={() => { setShopPickerForPackagesVisible(false); }}
                                                  shopChoices={shopChoices}
                                                  currentShop={currentShop}
                                                  loading={loading}
                                                  buttonLabel="Wymuś pobranie paczek"
                                                  action={forceFetchPackagesFromIdoSell}
                                                  handleSelect={handleSelectShop} /> : ''}

        {shopPickerForAllegroVisible ? <ShopAndAllegroPicker closeModal={() => { setShopPickerForAllegroVisible(false); }}
                                                             shopChoices={shopChoices}
                                                             currentShop={currentShop}
                                                             allegroChoices={allegroChoices}
                                                             currentAllegro={currentAllegro}
                                                             handleSelectAllegro={handleSelectAllegro}
                                                             loading={loading}
                                                             buttonLabel="Zaktualizuj treść aukcji Allegro"
                                                             action={updateDataInAllegroBasedOnExtendommerce}
                                                             handleSelect={handleSelectShop} /> : ''}

        {shopPickerForParametersVisible ? <ShopPicker closeModal={() => { setShopPickerForParametersVisible(false); }}
                                                      shopChoices={shopChoices}
                                                      currentShop={currentShop}
                                                      loading={loading}
                                                      buttonLabel="Wymuś pobranie parametrów"
                                                      action={forceFetchParametersFromIdoSell}
                                                      handleSelect={handleSelectShop} /> : ''}

        {syncHtmlVisible ? <InfoModal closeModal={() => { setSyncHtmlVisible(false); }}
                                      text="Zadanie generowowania HTML towarów na podstawie CMS Extendommerce zostało dodane do kolejki"
                                      icon={checkIcon} /> : ''}

        {success ? <SuccessModal closeModal={() => { setSuccess(''); }}
                                 text={success} /> : ''}

        {error ? <ErrorModal closeModal={() => { setError(''); }}
                             text={error} /> : ''}

        {modules[currentModule].submodules[currentSubmodule].submodules?.map((item, index) => {
            return item.link ? <a href={item.link}
                                  className="moduleItem"
                                  key={index}>
                <img className="icon" src={item.icon} alt={item.header} />
                <span className="header">
                    {item.header}
                </span>
            </a> : <button onClick={() => { invokeSyncAction(item.action); }}
                           className="moduleItem"
                           key={index}>
                {item.tooltip ? <Tooltip title={item.tooltip}
                                         position="top"
                                         followCursor={true}
                                         trigger="mouseenter">
                    <p className="tooltipIcon">?</p>
                </Tooltip> : ''}

                <img className="icon" src={item.icon} alt={item.header} />
                <span className="header">
                    {item.header}
                </span>
            </button>
        })}
    </span>
}

export default Synchronization;
