import React from 'react';
import ClearRangeCheckbox from './ClearRangeCheckbox';

const NumberRangeFilter = ({label, start, setStart, end, setEnd, clear, setClear}) => {
  const handleStart = (e) => {
    setStart(parseFloat(e.target.value));
  }

  const handleEnd = (e) => {
    setEnd(parseFloat(e.target.value));
  }

  return <div className="shopItem__filterWrapper shopItem__filterWrapper--array shopItem__filterWrapper--lastRow">
    <div className="shopItem__filterWrapper--flex">
      <span className="key">
          {label}
      </span>
      <span className="rangeSpan rangeSpan--margin">
          <span>od:</span>
          <input className="input input--filter input--filter--standard input--range"
                 placeholder="od"
                 value={start}
                 onChange={handleStart}
                 type="number" />
      </span>
      <span className="rangeSpan">
          <span>do:</span>
          <input className="input input--filter input--filter--standard input--range"
                 placeholder="do"
                 value={end}
                 onChange={handleEnd}
                 type="number" />
      </span>
    </div>

    <ClearRangeCheckbox clear={clear}
                        setClear={setClear} />
  </div>
};

export default NumberRangeFilter;
