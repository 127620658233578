import React from 'react';

const ButtonDeleteSection = ({onClick}) => {
  return <div className="blockEditor__section__header__type">
    <button className="blockEditor__section__header__deleteBtn btn btn--delete"
            onClick={onClick}>
      Usuń sekcję
    </button>
  </div>
};

export default ButtonDeleteSection;
