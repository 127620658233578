import React from 'react';
import TableCellStringValue from './TableCellStringValue';
import eyeIcon from '../static/img/external-link.svg';
import TableCellArrayValue from './TableCellArrayValue';

const TableOrdersRow = ({item, index}) => {
  const printDate = (date) => {
    return `${date?.substring(0, 10)}<br/>
            ${date?.substring(11, 19)}`;
  }

  const printVolume = (orderVolume) => {
    return `${orderVolume?.toFixed(2)} ${item.orderVolume ? 'cm<sup>3</sup>' : '-'}`;
  }

  return <div className="shopItem shopItem--product flex" key={index}>
    <TableCellStringValue label={'Sklep'}
                          value={item.websiteName} />
    <TableCellStringValue label={'Nr z zewn. systemu'}
                          value={item.externalSystemId} />
    <TableCellStringValue label={'Status'}
                          value={item.status} />
    <TableCellArrayValue label={'Przypisane towary'}
                         value={item.products.map((item) => (item.productName))} />
    <TableCellArrayValue label={'Przypisani pakowacze'}
                         value={item.packers} />
    <TableCellArrayValue label={'Przypisane paczki'}
                         value={item.packages} />
    <TableCellStringValue label={'Data złożenia zamówienia'}
                          value={item.orderDate} />
    <TableCellStringValue label={'Data spakowania'}
                          injectHtml={true}
                          value={printDate(item.packingDate)} />
    <TableCellStringValue label={'Wartość zamówienia'}
                          value={item.orderValue} />
    <TableCellStringValue label={'Waga zamówienia'}
                          value={item.orderWeight} />
    <TableCellStringValue label={'Objętość produktów'}
                          injectHtml={true}
                          value={printVolume(item.orderVolume)} />

    <span className="productItem__col productItem__col--buttons">
        <a className="productItem__btn orderItem__btn"
           href={`${item.websiteUrl}/panel/orderd.php?idt=${item.externalSystemId}`}
           target="_blank">
            <img className="img" src={eyeIcon} alt="link" />
        </a>
    </span>
  </div>
};

export default TableOrdersRow;
