import axios from 'axios';
import { apiQueueSlice } from '../reducers/apiQueueSlice';

const startQueue = (apiQueue, dispatch) => {
  const start = async () => {
    if(!window.isApiQueuedRequestsExecutionFunctionRunning) {
      window.isApiQueuedRequestsExecutionFunctionRunning = true;

      const recordsToDo = apiQueue.filter((item) => (item.status === 'request' || item.status === 'notAvailableStillTry'));

      for(const record of recordsToDo) {
        try {
          const res = await axios.post(record.url, record.payload);

          if(res?.data?.code === 0) {
            // Success
            dispatch(apiQueueSlice.actions.update({
              id: record.id,
              status: 'success',
              errorValue: null
            }));
          }
          else {
            // Error
            const error = res?.data?.response;

            dispatch(apiQueueSlice.actions.update({
              id: record.id,
              status: 'error',
              errorValue: error
            }));
          }
        }
        catch(e) {
          // API not available
          dispatch(apiQueueSlice.actions.update({
            id: record.id,
            status: 'notAvailableStillTry',
            errorValue: null
          }));
        }
      }

      window.isApiQueuedRequestsExecutionFunctionRunning = false;
    }
  }

  start();
  setInterval(async () => {
    start();
  }, 15000);
}

export default startQueue;
