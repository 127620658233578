import axios from 'axios'

const API_URL = 'http://localhost:5000/api';

const getVideoHtml = (id) => {
  return axios.get(`/video/getVideoHtml/${id}`);
}

const getVideoById = (id) => {
  return axios.get(`${API_URL}/getVideoById/${id}`);
}

const addVideo = (mainVideo, snippetVideo, placeholder, teamId, productId) => {
  const formData = new FormData();
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  formData.append('videoSrc', mainVideo);
  formData.append('snippetVideoSrc', snippetVideo);
  formData.append('placeholderImage', placeholder);
  formData.append('teamId', teamId);
  formData.append('productId', productId);

  return axios.post(`video/saveVideo`, formData, config);
}

const updateVideo = (id, mainVideo, snippetVideo, placeholder, teamId, productId,
                     mainVideoData, snippetVideoData, placeholderImageData) => {
  const formData = new FormData();
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  formData.append('videoSrc', mainVideo);
  formData.append('snippetVideoSrc', snippetVideo);
  formData.append('placeholderImage', placeholder);
  formData.append('teamId', teamId);
  formData.append('productId', productId);
  formData.append('mainVideoData', JSON.stringify(mainVideoData));
  formData.append('snippetVideoData', JSON.stringify(snippetVideoData));
  formData.append('placeholderImageData', JSON.stringify(placeholderImageData));

  return axios.patch(`video/updateVideo`, formData, config);
}

const deleteVideo = (id) => {
  return axios.delete(`${API_URL}/deleteVideo/${id}`);
}

export { getVideoHtml, getVideoById, addVideo, updateVideo,
  deleteVideo, API_URL }
