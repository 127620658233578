import React, { useEffect, useState } from 'react';
import Type1Block from './Type1Block';
import Type2Block from './Type2Block';
import Type3Block from './Type3Block';
import Type4Block from './Type4Block';
import Type5Block from './Type5Block';
import { useSelector } from 'react-redux';
import arrowUpIcon from '../static/img/arrow-up.svg';
import SingleBlockWrapper from './SingleBlockWrapper';

const PredefinedBlockView = ({id, index, context, moveBlockUp, moveBlockDown, removeBlock}) => {
  const blocks = useSelector((state) => (state.richBlocks.predefinedBlocks));

  const [block, setBlock] = useState({});
  const [sections, setSections] = useState([]);
  const [firstVariant, setFirstVariant] = useState([]);

  useEffect(() => {
    if(blocks?.length && id) {
      const contextsArray = JSON.parse(blocks.find((item) => (item.id === id)).json_value)

      setBlock(blocks.find((item) => (item.id === id)));
      setSections(contextsArray?.length > context ? contextsArray[context] : contextsArray[0]);
    }
  }, [blocks, id]);

  useEffect(() => {
    if(sections?.length) {
      setFirstVariant(sections[0]);
    }
  }, [sections]);

  const getBlockComponentByType = (block) => {
    return <SingleBlockWrapper n={block.sectionHtmlTemplateId}
                               block={block}
                               readOnly={true} />
  }

  return <div className="predefinedBlockView">
      <div className="predefinedBlockView__header">
        <div className="predefinedBlockView__header__section">
          <h3 className="predefinedBlockView__header__name">
            {block.name}
          </h3>

          {removeBlock ? <div className="blockEditor__section__header__type">
            <h4>
              Zmień kolejność sekcji:
            </h4>
            <button className="blockEditor__section__header__arrowBtn"
                    onClick={() => { moveBlockUp(index); }}>
              <img className="img" src={arrowUpIcon} alt="wyzej" />
            </button>
            <button className="blockEditor__section__header__arrowBtn"
                    onClick={() => { moveBlockDown(index); }}>
              <img className="img" src={arrowUpIcon} alt="wyzej" />
            </button>
          </div> : ''}
        </div>
        {removeBlock ? <div className="predefinedBlockView__header__section predefinedBlockView__header__section--right">
          <button className="btn btn--delete btn--detach"
                  onClick={() => { removeBlock(); }}>
            Odłącz blok
          </button>
          <a href={`/edytor-bloku-cms?id=${block.id}`} target="_blank" className="btn btn--editInNewTab">
            Edytuj blok w osobnej karcie
          </a>
        </div> : ''}
      </div>

    {firstVariant?.map((item, index) => {
      if(item.isPredefinedBlock) {
        return <div className="predefinedBlockView__section" key={index}>
          <PredefinedBlockView index={index}
                               context={context}
                               id={item.predefinedBlockId} />
        </div>
      }
      else {
        return <div className="predefinedBlockView__section" key={index}>
          {getBlockComponentByType(item)}
        </div>
      }
    })}
  </div>
};

export default PredefinedBlockView;
