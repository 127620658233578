import React from 'react';

const PackagesSummaryItem = ({label, value, injectHtml = false, displayIf = true}) => {
  return displayIf ? <span className="packing__packagesSummary__item">
    {label}:
    {injectHtml ? <span className="packing__packagesSummary__item__value"
                        dangerouslySetInnerHTML={{
      __html: value
    }}></span> : <span className="packing__packagesSummary__item__value">
      {value}
    </span>}
  </span> : '';
}

export default PackagesSummaryItem;
