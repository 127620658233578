import React from 'react';
import ClearRangeCheckbox from './ClearRangeCheckbox';

const InputFilter = ({label, type = 'text', value, setValue, placeholder, clear, setClear}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  }

  return <div className="shopItem__filterWrapper">
    <label>
      <span className="key">
          {label}
      </span>
      <input className="input input--filter input--filter--standard"
             type={type}
             placeholder={placeholder}
             value={value}
             onChange={handleChange} />
    </label>

    <ClearRangeCheckbox clear={clear}
                        setClear={setClear} />
  </div>
};

export default InputFilter;
