import React, { useEffect, useRef, useState } from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import Select from 'react-select'
import LoadingPage from "../components/LoadingPage";
import useUserShops from '../hooks/useUserShops';
import { deleteAllegroAccount, getAllegroAccountsByUser, getAllegroTokenByAccount } from '../helpers/allegro';
import AddAllegroShopForm from '../components/AddAllegroShopForm';
import Loader from '../components/Loader';
import checkIcon from '../static/img/green-check.svg';
import penIcon from '../static/img/pen.svg';
import deleteIcon from '../static/img/x-button.svg';
import DeleteModal from '../components/DeleteModal';
import { ALLEGRO_CLIENT_ID, ALLEGRO_REDIRECT_URI, error as err } from '../helpers/content';

const AllegroAccountsPage = () => {
    const { shops, shopChoices } = useUserShops();

    let allegroList = useRef(null);

    const [currentShopIndex, setCurrentShopIndex] = useState(0);
    const [currentShop, setCurrentShop] = useState({});
    const [userAllegroAccounts, setUserAllegroAccounts] = useState(null);
    const [currentShopAllegroAccounts, setCurrentShopAllegroAccounts] = useState(null);
    const [currentShopAllegroTokens, setCurrentShopAllegroTokens] = useState([]);
    const [accountToEdit, setAccountToEdit] = useState(null);
    const [formVisible, setFormVisible] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(null);
    const [updateAccountsList, setUpdateAccountsList] = useState(false);

    useEffect(() => {
        getAllegroAccountsByUser()
          .then((res) => {
              if(res) {
                  setUserAllegroAccounts(res.data);
              }
          });
    }, [updateAccountsList]);

    useEffect(() => {
        if(userAllegroAccounts && currentShop) {
            const currentShopId = currentShop.id;

            setCurrentShopAllegroAccounts(userAllegroAccounts.filter((item) => {
                return item.website_id === currentShopId;
            }));
        }
    }, [userAllegroAccounts, currentShop]);

    useEffect(() => {
        (async () => {
            let tokens = [];

            for(const account of currentShopAllegroAccounts) {
                const tokenResponse = await getAllegroTokenByAccount(account.id);

                if(tokenResponse) {
                    tokens.push(tokenResponse.data);
                }
            }

            setCurrentShopAllegroTokens(tokens);
        })();
    }, [currentShopAllegroAccounts])

    useEffect(() => {
        setCurrentShop(shops[currentShopIndex]);
    }, [shops, currentShopIndex]);

    const addAllegroAccount = () => {
        setFormVisible(true);
        setAccountToEdit(null);

        allegroList.current.style.width = '50%';
        allegroList.current.style.marginRight = '5%';
    }

    const handleSelect = (data) => {
        setCurrentShopIndex(data.value);
    }

    const handleDeleteAllegroAccount = () => {
        setDeleteLoading(true);

        deleteAllegroAccount(deleteCandidate)
          .then((res) => {
             setDeleteLoading(false);
             setDeleteStatus('Konto zostało usunięte');
             setUpdateAccountsList(p => !p);
          })
          .catch(() => {
              setDeleteLoading(false);
              setDeleteStatus(err);
              setUpdateAccountsList(p => !p);
          });
    }

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton module={0} />

        {deleteCandidate ? <DeleteModal text={'Czy na pewno chcesz usunąć to konto Allegro?'}
                                        header={'Usuwanie konta Allegro'}
                                        deleteFunction={handleDeleteAllegroAccount}
                                        deleteStatus={deleteStatus}
                                        deleteLoading={deleteLoading}
                                        backText={'Wróć do edycji kont Allegro'}
                                        closeModalFunction={() => { setDeleteCandidate(null); }} /> : ''}

        {shops?.length ? <main className="main w">
            <h1 className="main__header">
                Dodawanie i edycja powiązanych kont Allegro
            </h1>

            <div className="shopEditor">
                <label className="label">
                    Wybierz sklep:
                    <Select options={shopChoices}
                            placeholder="Wybierz sklep"
                            value={shopChoices[currentShopIndex]}
                            onChange={handleSelect}
                            isSearchable={true} />
                </label>

                <div className="flex flex--allegro">
                    <div className="allegro__list" ref={allegroList}>
                        <h4 className="allegro__list__header">
                            Lista kont Allegro podpiętych do tego sklepu:
                        </h4>

                        {currentShopAllegroAccounts?.length ? currentShopAllegroAccounts.map((item, index) => {
                            return <div className="allegro__list__item"
                                        key={index}>
                                <div>
                                    <span>
                                        {item.name}
                                    </span>

                                    <button className="btn btn--editAllegro" onClick={() => { addAllegroAccount(); setAccountToEdit(item); }}>
                                        <img className="img" src={penIcon} alt="olowek" />
                                    </button>
                                    <button className="btn btn--deleteAllegro" onClick={() => { setDeleteCandidate(item.id); }}>
                                        <img className="img" src={deleteIcon} alt="usun" />
                                    </button>
                                </div>

                                {!currentShopAllegroTokens[index] ? <a className="btn btn--allegro btn--allegroLogin"
                                                                       target="_blank"
                                                                      href={`https://allegro.pl/auth/oauth/authorize?response_type=code&client_id=${ALLEGRO_CLIENT_ID}&redirect_uri=${ALLEGRO_REDIRECT_URI}/${item.id}`}>
                                    Zaloguj się na konto Allegro
                                </a> : <div className="allegroConnectedInfo">
                                    <img className="img" src={checkIcon} alt="check" />
                                    Konto połączone
                                </div>}
                            </div>
                        }) : (currentShopAllegroAccounts?.length === 0 ? <p className="allegro__list__noAccounts">
                            Brak przypisanych kont Allegro do tego sklepu
                        </p> : <Loader />)}

                        <button className="btn btn--allegro"
                                onClick={addAllegroAccount}>
                            Dodaj konto Allegro
                        </button>
                    </div>

                    {formVisible ? <AddAllegroShopForm websiteId={currentShop?.id}
                                                       setUpdateAccountsList={setUpdateAccountsList}
                                                       accountToEdit={accountToEdit} /> : ''}
                </div>
            </div>
        </main> : <LoadingPage />}
    </div>
}

export default AllegroAccountsPage;
