import React, {useEffect, useState} from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import penIcon from '../static/img/pen.svg'
import {useDispatch, useSelector} from "react-redux";
import DeleteModal from "../components/DeleteModal";
import {deletePredefinedBlockById} from "../helpers/richBlocks";
import Cookies from "universal-cookie";
import {fetchPredefinedBlocks} from "../reducers/richBlocksSlice";
import TableCellStringValue from '../components/TableCellStringValue';

const UserPredefinedBlocks = () => {
    const blocks = useSelector((state) => (state.richBlocks.predefinedBlocks));
    const dispatch = useDispatch();

    const [deleteCandidate, setDeleteCandidate] = useState(0);
    const [deleteStatus, setDeleteStatus] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(false);

    const deletePredefinedBlock = () => {
        setDeleteLoading(true);

        deletePredefinedBlockById(deleteCandidate)
            .then((res) => {
                if(res) {
                    setDeleteStatus('Blok został usunięty');
                }
                setDeleteLoading(false);
            })
            .catch(() => {
                setDeleteLoading(false);
                setDeleteStatus('Coś poszło nie tak');
            });
    }

    useEffect(() => {
        if(deleteCandidate) {
            setDeleteStatus('');
        }
    }, [deleteCandidate]);

    useEffect(() => {
        if(deleteStatus) {
            dispatch(fetchPredefinedBlocks());
        }
    }, [deleteStatus]);

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton module={1} backToPreviousPage={true} />

        {deleteCandidate ? <DeleteModal header="Usuwanie bloku CMS"
                                        text="Czy na pewno chcesz usunąć ten blok CMS?"
                                        deleteFunction={deletePredefinedBlock}
                                        deleteLoading={deleteLoading}
                                        deleteStatus={deleteStatus}
                                        closeModalFunction={() => { setDeleteCandidate(0); }} /> : ''}

        <main className="main w">
            <h1 className="main__header flex">
                <span>
                    Lista predefiniowanych bloków CMS
                </span>

                <a href="/edytor-bloku-cms"
                   className="btn btn--addNewPredefinedBlock">
                    Dodaj nowy blok predefiniowany
                </a>
            </h1>

            {blocks?.map((item, index) => {
                return <div className="shopItem shopItem--block flex"
                            key={index}>
                    <span className="id">
                        #{item.id}
                    </span>

                    <TableCellStringValue label={'Nazwa'}
                                          value={item.name} />

                    <span className="productItem__col">
                        <span className="key">
                            Edycja bloku
                        </span>
                        <span className="value">
                            <a className="productItem__btn productItem__btn--editor"
                               href={`/edytor-bloku-cms?id=${item.id}`}>
                                    Edytor bloku
                                    <img className="img" src={penIcon} alt="edycja" />
                            </a>
                        </span>
                    </span>
                    <span className="productItem__col">
                        <span className="key">
                            Usuń blok i przypisane do niego zdjęcia
                        </span>
                        <span className="value">
                            <button className="productItem__btn productItem__btn--gallery btn--delete"
                                    onClick={() => { setDeleteCandidate(item.id); }}>
                                Usuń
                            </button>
                        </span>
                    </span>
                </div>
            })}
        </main>
    </div>
};

export default UserPredefinedBlocks;
