import React, { useContext } from 'react';
import { FilterApiQueueContext } from './ApiQueueView';

const ButtonApiQueueFilter = ({children, value}) => {
  const { currentFilter, setCurrentFilter } = useContext(FilterApiQueueContext);

  return <button className={currentFilter === value ? "btn btn--apiQueueFilter btn--apiQueueFilter--selected" : "btn btn--apiQueueFilter"}
                 onClick={() => { setCurrentFilter(value); }}>
    {children}
  </button>
};

export default ButtonApiQueueFilter;
