import { useEffect } from 'react';
import { isEscapeClicked } from '../helpers/others';

const useCloseOnEscapeClick = ({closeModalFunction}) => {
  useEffect(() => {
    document.addEventListener('keyup', (event) => {
      if(isEscapeClicked(event)) {
        closeModalFunction();
      }
    });
  }, []);
}

export default useCloseOnEscapeClick;
