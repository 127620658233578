import React, { useEffect } from 'react';
import Select from 'react-select';
import { addNewPackerErrors } from '../helpers/content';
import { addNewPacker } from '../helpers/orders';
import ErrorTextInfo from './ErrorTextInfo';
import SuccessTextInfo from './SuccessTextInfo';
import { useSelector } from 'react-redux';

const AddPacker = ({packers, newPacker, setNewPacker, addPackerStatus, setAddPackerStatus,
                     currentPackerId, currentPacker, setCurrentPacker}) => {
  const teamId = useSelector((state) => (state.user.data.u_team_id));

  const addPacker = () => {
    if(newPacker) {
      addNewPacker(teamId, newPacker)
        .then((res) => {
          if(res?.status === 201) {
            setAddPackerStatus(1);
          }
          else if(res?.status === 400) {
            setAddPackerStatus(-2);
          }
        })
        .catch(() => {
          setAddPackerStatus(-3);
        })
    }
    else {
      setAddPackerStatus(-1);
    }
  }

  useEffect(() => {
    if(addPackerStatus !== 0) {
      setTimeout(() => {
        setAddPackerStatus(0);
      }, 2000);
    }

    if(addPackerStatus === 1) {
      setNewPacker('');
    }
  }, [addPackerStatus]);

  const handlePackerName = (e) => {
    setNewPacker(e.target.value);
  }

  const submitOnEnter = (e) => {
    if(e.key === 'Enter') {
      addPacker();
    }
  }

  return <div className="packing__section packing__section--addPacker">
    <h4 className="packing__header">
      Wybierz nick pakowacza, który pakuje paczki na tym stanowisku
    </h4>

    <div className="packing__choose">
      <Select defaultValue={''}
              options={packers}
              placeholder="Wybierz pakowacza"
              value={currentPacker}
              onChange={setCurrentPacker} />
    </div>

    <div className="packing__addNew">
      <span className="packing__addNew__text">
        Lub dodaj nowego pakowacza:
      </span>

      <label>
        <input className="input input--addNewPacker"
               value={newPacker}
               onKeyDown={submitOnEnter}
               onChange={handlePackerName}
               placeholder="Nick pakowacza" />
      </label>
      <button className="btn btn--addNewPacker"
              onClick={addPacker}>
        Dodaj nowego pakowacza
      </button>

      {addPackerStatus ? (addPackerStatus === 1 ? <SuccessTextInfo content={'Pakowacz został dodany'} /> :
        <ErrorTextInfo content={addNewPackerErrors[addPackerStatus]} />) : ''}
    </div>

    {currentPackerId ? <h5 className="currentPackerHeader">
      Aktualnie wybrany pakowacz:
        <span className="packerFrame">
          {currentPacker.label}
        </span>
    </h5> : ''}
  </div>
};

export default AddPacker;
