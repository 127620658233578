import React from 'react';
import imageIcon from '../static/img/image-icon.svg';

const ImageUploader = ({label, imageInputRef, imageRef, handleFileUpload, setImage, setImageFile, clearImageInput, image}) => {
  return <div className="editor__files__section">
    <h2 className="editor__files__section__header">
      {label}
    </h2>

    <div className="editor__videoWrapper">
      {!image ? <>
        <input type="file" className="editor__files__input" ref={imageInputRef}
               onChange={(e) => { handleFileUpload(e, setImage, setImageFile); }} />
        <div className="editor__videoWrapper__placeholderContent">
          <p className="editor__videoWrapper__placeholderContent__text">
            Kliknij tutaj lub upuść plik aby dodać zdjęcie
          </p>
          <img className="editor__videoWrapper__icon" src={imageIcon} alt="video" />
        </div>
      </> : <button className="editor__videoWrapper__clearInput" onClick={clearImageInput}>
        &times;
      </button> }

      <img className={image ? "editor__video" : "editor__video opacity-0"}
           ref={imageRef}
           src={image}
           alt="placeholder" />
      {!image ? <span className="editor__video__placeholder">

      </span> : ''}
    </div>
  </div>
};

export default ImageUploader;
