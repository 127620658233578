import React, { useEffect, useRef } from 'react';
import ContentEditable from 'react-contenteditable'

const Type1Block = ({block, readOnly, editor, changeTexts, editorType}) => {
    const handleChange = (e) => {
      changeTexts(0, e.target.value, false);
    }

    return <div className="blockEditor__block">
        <label>
          {!readOnly ? (editorType === 0 ? <ContentEditable html={block.textsArray[0]}
                                                            className="blockEditor__textarea blockEditor__textarea--fullWidth"
                                                            innerRef={editor}
                                                            style={{
                                                                color: block.textColor,
                                                                background: block.backgroundColor
                                                            }}
                                                            onChange={handleChange} /> : <textarea className="blockEditor__textarea blockEditor__textarea--html"
                                                                                                  defaultValue={block.textsArray[0]}
                                                                                                   onChange={handleChange}>

          </textarea>) : <div className="blockEditor__textarea blockEditor__textarea--fullWidth" dangerouslySetInnerHTML={{
                              __html: block.textsArray[0]
          }}>

          </div>}
        </label>
    </div>
};

export default Type1Block;
