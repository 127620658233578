import axios from "axios";
import { getAuthHeader, getConfigWithAuthHeader, getLoggedUserId } from './others';
import { scheduleNewJob } from './cronJobs';
import { getUserData } from './user';

const getPredefinedBlocksByUser = () => {
    return axios.get(`/products-description-rich-blocks/getPredefinedBlocksByUser/${getLoggedUserId()}`,
      getConfigWithAuthHeader());
}

const getAllBlocksByUser = () => {
    return axios.get(`/products-description-rich-blocks/getBlocksByUser/${getLoggedUserId()}`,
      getConfigWithAuthHeader());
}

const getBlockById = (id) => {
    return axios.get(`/products-description-rich-blocks/getBlockById/${id}`,
      getConfigWithAuthHeader());
}

const getImageById = (id) => {
    return axios.get(`/products-description-rich-blocks/getImageById/${id}`,
      getConfigWithAuthHeader());
}

const appendUserInfoToFormData = async (name) => {
    const userData = await getUserData();

    console.log('append');

    let formData = new FormData();
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: getAuthHeader()
        }
    }

    formData.append('name', name);
    formData.append('userId', getLoggedUserId());
    formData.append('teamId', userData?.data[0]?.team_id);

    return { formData, config }
}

const parseImages = (blocks) => {
    return blocks.map((item) => {
        return item.map((item) => {
            return item.map((item) => ((item.imagesArray ? item.imagesArray : [])));
        })
    }).flat(3);
}

const appendImagesToAddFormData = (formData, blocks) => {
    const images = parseImages(blocks);

    for(const img of images) {
        formData.append('images', img);
    }

    return formData;
}

const appendImagesToUpdateFormData = (formData, blocks) => {
    const images = parseImages(blocks);

    for(const img of images) {
        if(typeof img !== 'string') {
            formData.append('images', img);
        }
    }

    return formData;
}

const addPredefinedBlock = async (blocks, name) => {
    let { formData, config } = await appendUserInfoToFormData(name);

    formData.append('blocks', JSON.stringify(blocks));

    formData = appendImagesToAddFormData(formData, blocks);

    return axios.post(`/products-description-rich-blocks/addPredefinedBlock`, formData, config);
}

const updatePredefinedBlock = async (blocks, id, name) => {
    let { formData, config } = await appendUserInfoToFormData(name);

    formData.append('blocks', JSON.stringify(blocks));
    formData.append('id', id);

    formData = appendImagesToUpdateFormData(formData, blocks);

    return axios.patch(`/products-description-rich-blocks/updatePredefinedBlock`, formData, config);
}

const addProductBlock = async (blocks, name, productId) => {
    let { formData, config } = await appendUserInfoToFormData(name);

    formData.append('blocks', JSON.stringify(blocks));
    formData.append('productId', productId);

    formData = appendImagesToAddFormData(formData, blocks);

    return axios.post(`/products-description-rich-blocks/addProductBlock`, formData, config);
}

const updateProductBlock = async (blocks, id, name, productId) => {
    let { formData, config } = await appendUserInfoToFormData(name);

    formData.append('blocks', JSON.stringify(blocks));
    formData.append('id', id);
    formData.append('productId', productId);

    formData = appendImagesToUpdateFormData(formData, blocks);

    return axios.patch(`/products-description-rich-blocks/updateProductBlock`, formData, config);
}

const deletePredefinedBlockById = (id) => {
    return axios.delete(`/products-description-rich-blocks/delete/${id}`, getConfigWithAuthHeader());
}

const generateHtmlFromCms = () => {
    return scheduleNewJob(null, 'generateHtmlFromCms', { user_id: getLoggedUserId() });
}

const generateHtmlByJson = (jsonValue) => {
    return axios.post(`/products-description-rich-blocks/generateHtmlFromJson`, {
        jsonValue
    });
}

export { getPredefinedBlocksByUser, getBlockById, addPredefinedBlock, generateHtmlFromCms, getImageById, getAllBlocksByUser,
    updatePredefinedBlock, deletePredefinedBlockById, addProductBlock, updateProductBlock, generateHtmlByJson }
