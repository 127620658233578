import React, {useEffect, useState} from 'react';
import userIcon from '../static/img/user-icon.svg'
import passwordIcon from '../static/img/password-icon.svg'
import {auth, loginUser} from "../helpers/user";
import Cookies from 'universal-cookie';
import LoadingPage from "../components/LoadingPage";
import { error as err } from "../helpers/content";
import InputLogin from '../components/InputLogin';
import ErrorTextInfo from '../components/ErrorTextInfo';
import SubmitButton from '../components/SubmitButton';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [render, setRender] = useState(false);

    useEffect(() => {
        // Redirect if already logged in
        auth()
            .then((res) => {
                if(res?.status === 201) {
                    window.location = '/home';
                }
                else {
                    setRender(true);
                }
            })
            .catch(() => {
                setRender(true);
            });
    }, []);

    useEffect(() => {
        setError('');
    }, [username, password]);

    const validateInputs = (e) => {
        e.preventDefault();

        if(username && password) {
            handleLogin();
        }
        else {
            setError('Wpisz swój login i hasło');
        }
    }

    const handleLogin = async () => {
        try {
            const loginResult = await loginUser(username, password);

            if(loginResult?.status === 201 && loginResult?.data) {
                handleSession(loginResult.data);
            }
            else {
                setError('Niepoprawny login lub hasło');
            }
        }
        catch(err) {
            setError('Niepoprawny login lub hasło');
        }
    }

    const handleSession = (loginData) => {
        const jwt = loginData.access_token;
        const id = loginData.id;

        if(jwt) {
            const cookies = new Cookies();
            cookies.set('access_token', jwt, { path: '/' });
            cookies.set('username', username, { path: '/' });
            cookies.set('id', id, { path: '/' });

            window.location = '/home';
        }
        else {
            setError(err);
        }
    }

    return render ? <div className="container container--login center">
        <h1 className="container--login__header">
            Extendommerce
        </h1>

        <form className="loginForm">
            <InputLogin label={'Login'}
                        type={'text'}
                        icon={userIcon}
                        placeholder={'Twoja nazwa użytkownika'}
                        value={username}
                        setValue={setUsername} />

            <InputLogin label={'Hasło'}
                        type={'password'}
                        icon={passwordIcon}
                        placeholder={'Twoje hasło'}
                        value={password}
                        setValue={setPassword} />

           <ErrorTextInfo content={error} />

            <SubmitButton content={'Zaloguj się'}
                          onClick={validateInputs} />
        </form>
    </div> : <LoadingPage />
}

export default LoginPage;
