import React from 'react';

const TableCellStringValue = ({label, value, valueClass = 'value', injectHtml = false}) => {
  return <span className="productItem__col">
      <span className="key">
          {label}
      </span>
    {injectHtml ? <span className={valueClass}
                        dangerouslySetInnerHTML={{
      __html: value
    }}></span> : <span className={valueClass}>
          {value ? value : '-'}
      </span>}
  </span>
};

export default TableCellStringValue;
