import { useEffect } from 'react';

const useFilterOrders = (orders, setFilteredOrders, filters) => {
  useEffect(() => {
    let newFilteredOrders = orders;

    const { idFilterValue, shopFilterValue,
      packersFilterValue, packagesFilterValue, receivedDateStart, receivedDateEnd,
      packingDateStart, packingDateEnd, orderValueStart, orderValueEnd,
      orderWeightStart, orderWeightEnd, orderVolumeStart, orderVolumeEnd,
      statusFilterValue, productsFilterValue, clearShop } = filters;

    if(idFilterValue) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return parseInt(item.externalSystemId) === parseInt(idFilterValue);
      });
    }
    if(shopFilterValue) {
      const shopFilterParts = shopFilterValue.toLowerCase().split(' ');

      newFilteredOrders = newFilteredOrders.filter((item) => {
        const shopNameParts = item.websiteName.toLowerCase().split(' ');

        // Filter orders with match for every part (not necessary in order)
        return shopFilterParts.findIndex((item) => {
          const filterPart = item;
          return shopNameParts.findIndex((item) => {
            return item.includes(filterPart);
          }) === -1;
        }) === -1;
      });
    }
    if(statusFilterValue) {
      newFilteredOrders = newFilteredOrders.filter((item) => (item.status === statusFilterValue.value));
    }
    if(productsFilterValue.length) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        let productsMatched = 0;

        const productsNamesParts = item.products.map((item) => {
          return item.productName.split(' ');
        }); // [['laptop', 'duzy'], ['fajerwerki', 'na', 'sylwestra']]

        for(const product of productsFilterValue) {
          const productFilterParts = product.toLowerCase().split(' '); // ['duz', 'lap']

          const productFound = productsNamesParts.findIndex((item) => {
            return item.filter((item) => {
              const productNamePart = item;

              return productFilterParts.findIndex((item) => {
                return productNamePart.toLowerCase().includes(item.toLowerCase());
              }) !== -1;
            }).length === productFilterParts.length;
          }) !== -1;

          if(productFound) {
            productsMatched++;
          }
        }

        return (productsMatched === productsFilterValue.length) && (productsNamesParts.length !== 0);
      });
    }
    if(packersFilterValue.length) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        let packersMatched = 0;

        const packersNamesParts = item.packers.map((item) => {
          return item.split(' ');
        }); // [['laptop', 'duzy'], ['fajerwerki', 'na', 'sylwestra']]

        for(const packer of packersFilterValue) {
          const packerFilterParts = packer.toLowerCase().split(' '); // ['duz', 'lap']

          const packerFound = packersNamesParts.findIndex((item) => {
            return item.filter((item) => {
              const packerNamePart = item;

              return packerFilterParts.findIndex((item) => {
                return packerNamePart.toLowerCase().includes(item.toLowerCase());
              }) !== -1;
            }).length === packerFilterParts.length;
          }) !== -1;

          if(packerFound) {
            packersMatched++;
          }
        }

        return (packersMatched === packersFilterValue.length) && (packersNamesParts.length !== 0);
      });
    }
    if(packagesFilterValue.length) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        let packagesMatched = 0;

        const packagesNamesParts = item.packages.map((item) => {
          return item.split(' ');
        }); // [['laptop', 'duzy'], ['fajerwerki', 'na', 'sylwestra']]

        for(const packageId of packagesFilterValue) {
          const packageFilterParts = packageId.toLowerCase().split(' '); // ['duz', 'lap']

          const packageFound = packagesNamesParts.findIndex((item) => {
            return item.filter((item) => {
              const packageNamePart = item;

              return packageFilterParts.findIndex((item) => {
                return packageNamePart.toLowerCase().includes(item.toLowerCase());
              }) !== -1;
            }).length === packageFilterParts.length;
          }) !== -1;

          if(packageFound) {
            packagesMatched++;
          }
        }

        return (packagesMatched === packagesFilterValue.length) && (packagesNamesParts.length !== 0);
      });
    }

    if(receivedDateStart) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return new Date(item.orderDate) >= receivedDateStart;
      });
    }
    if(receivedDateEnd) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return new Date(item.orderDate) <= receivedDateEnd;
      });
    }

    if(packingDateStart) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return new Date(item.packingDate) >= packingDateStart;
      });
    }
    if(packingDateEnd) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return new Date(item.packingDate) <= packingDateEnd;
      });
    }

    if((orderValueStart || orderValueStart === 0) && (orderValueEnd)) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return ((item.orderValue >= orderValueStart) && (item.orderValue <= orderValueEnd));
      });
    }
    if((orderWeightStart || orderWeightStart === 0) && (orderWeightEnd)) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return ((item.orderWeight >= orderWeightStart) && (item.orderWeight <= orderWeightEnd)) || !item.orderWeight;
      });
    }
    if((orderVolumeStart || orderVolumeStart === 0) && (orderVolumeEnd)) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return ((item.orderVolume >= orderVolumeStart) && (item.orderVolume <= orderVolumeEnd)) || !item.orderVolume;
      });
    }

    if(clearShop) {
      newFilteredOrders = newFilteredOrders.filter((item) => {
        return item.w_name === '';
      });
    }

    setFilteredOrders(newFilteredOrders);
  }, Object.values(filters));
}

export default useFilterOrders;
