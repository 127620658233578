import React, {useEffect, useState} from 'react';
import LoadingPage from "./LoadingPage";
import { auth, getUserData } from '../helpers/user';
import Homepage from "../pages/Homepage";
import UserShops from "../pages/UserShops";
import UserProducts from "../pages/UserProducts";
import ProductEditor from "../pages/ProductEditor";
import UserCronJobs from "../pages/UserCronJobs";
import ChangePassword from "../pages/ChangePassword";
import UserPredefinedBlocks from "../pages/UserPredefinedBlocks";
import BlockEditor from "../pages/BlockEditor";
import {fetchAllBlocks, fetchPredefinedBlocks} from "../reducers/richBlocksSlice";
import {useDispatch} from "react-redux";
import BlockHtmlPreview from '../pages/BlockHTMLPreview';
import { fetchAllProducts } from '../reducers/productsSlice';
import PackagesPoint from '../pages/PackagesPoint';
import MassEdition from '../pages/MassEdition';
import VideoEditor from '../pages/VideoEditor';
import UserOrders from '../pages/UserOrders';
import { fetchAllOrders } from '../reducers/ordersSlice';
import { redirectToHomepage } from '../helpers/others';
import { setUserAccess, setUserData } from '../reducers/userSlice';
import AllegroAccountsPage from '../pages/AllegroAccountsPage';
import AllegroConfirmLoginPage from '../pages/AllegroConfirmLoginPage';

const UserWrapper = ({page}) => {
    const [component, setComponent] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        auth()
            .then(async (res) => {
                if(res?.status === 201) {
                    const userDataResponse = await getUserData();
                    const userData = userDataResponse.data;

                    if(userData) {
                        const userAccess = getUserAccess(userData);

                        // Set user data and access in redux store
                        dispatch(setUserData(userData[0]));
                        dispatch(setUserAccess(userAccess));

                        // Fetch data from API to redux store with async thunks
                        dispatch(fetchPredefinedBlocks());
                        dispatch(fetchAllBlocks());
                        dispatch(fetchAllProducts());
                        dispatch(fetchAllOrders());

                        switch(page) {
                            case 1:
                                setComponent(<Homepage />);
                                break;
                            case 2:
                                setComponent(<UserShops />);
                                break;
                            case 3:
                                setComponent(<UserProducts />);
                                break;
                            case 4:
                                setComponent(<ProductEditor />);
                                break;
                            case 5:
                                setComponent(<UserCronJobs />);
                                break;
                            case 6:
                                setComponent(<ChangePassword />);
                                break;
                            case 7:
                                setComponent(<UserPredefinedBlocks />);
                                break;
                            case 8:
                                setComponent(<BlockEditor />);
                                break;
                            case 9:
                                setComponent(<BlockHtmlPreview />);
                                break;
                            case 10:
                                setComponent(<PackagesPoint />);
                                break;
                            case 11:
                                setComponent(<MassEdition />);
                                break;
                            case 12:
                                setComponent(<VideoEditor />);
                                break;
                            case 13:
                                setComponent(<UserOrders />);
                                break;
                            case 14:
                                setComponent(<AllegroAccountsPage />);
                                break;
                            case 15:
                                setComponent(<AllegroConfirmLoginPage />);
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        redirectToHomepage();
                    }
                }
                else {
                    redirectToHomepage();
                }
            })
            .catch((e) => {
                redirectToHomepage();
            });
    }, []);

    const getUserAccess = (userData) => {
        const availableModules = userData[0]?.u_available_modules;
        return JSON.parse(availableModules);
    }

    return component ? component : <LoadingPage />
}

export default UserWrapper;
