import React, { useEffect, useState } from 'react';
import LoggedUserHeader from '../components/LoggedUserHeader';
import BackButton from '../components/BackButton';
import MassEditionConfiguration from '../components/MassEditionConfiguration';
import MassEditionForm from '../components/MassEditionForm';
import { getParametersByUser } from '../helpers/parameters';

const paramsToEditFixedLabels = ['długość [mm]', 'szerokość [mm]', 'wysokość [mm]', 'waga [kg]'];

const MassEdition = () => {
  const [paramsLabels, setParamsLabels] = useState([]);
  const [paramsToEdit, setParamsToEdit] = useState([]);
  const [sectionVisible, setSectionVisible] = useState(0);

  useEffect(() => {
    getParametersByUser()
      .then((res) => {
        if(res?.data) {
          setParamsLabels(paramsToEditFixedLabels.map((item) => ({
            value: -1,
            label: item
          })).concat(res.data.map((item) => ({
            value: item.id,
            label: item.parameter_name
          }))));
        }
      })
  }, []);

  useEffect(() => {
    setParamsToEdit(paramsLabels.map(() => false));
  }, [paramsLabels]);

  return <div className="container container--shopList container--cronList">
    <LoggedUserHeader />
    <BackButton />

    <main className="main w">
      <h1 className="main__header main__header--flex">
        Błyskawiczna i masowa edycja

        <div className="packing__buttonsWrapper flex">
          <button className={sectionVisible === 0 ? "packing__btn packing__btn--selected" : "packing__btn"}
                  onClick={() => { setSectionVisible(0); }}>
            Konfiguracja
          </button>
          <button className={sectionVisible === 1 ? "packing__btn packing__btn--selected" : "packing__btn"}
                  onClick={() => { setSectionVisible(1); }}>
            Masowe przypisywanie danych
          </button>
        </div>
      </h1>

      {sectionVisible === 0 ? <MassEditionConfiguration paramsToEdit={paramsToEdit}
                                                        setParamsToEdit={setParamsToEdit}
                                                        paramsLabels={paramsLabels}
                                                        setSectionVisible={setSectionVisible} /> :
        <MassEditionForm paramsToEdit={paramsToEdit}
                         paramsLabels={paramsLabels} />}
    </main>
  </div>
};

export default MassEdition;
