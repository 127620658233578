import { useEffect } from 'react';
import { scrollToTop } from '../helpers/others';

const useScrollToTop = (trigger) => {
  useEffect(() => {
    if(trigger) {
      scrollToTop();
    }
  }, [trigger]);
}

export default useScrollToTop;
