import React from 'react';
import { SketchPicker } from "react-color";
import useCloseOnEscapeClick from '../hooks/useCloseOnEscapeClick';

const ColorModal = ({color, onChange, closeModalFunction}) => {
    useCloseOnEscapeClick(closeModalFunction);

    return <div className="colorModal">
        <div className="colorModal__inner">
            <button className="colorModal__close"
                    onClick={closeModalFunction}>
                &times;
            </button>

            <h3 className="colorModal__header">
                Zmień kolor
            </h3>

            <SketchPicker color={color}
                          onChange={(color, event) => { onChange(color, event) }} />

            <button className="btn btn--changeColor"
                    onClick={closeModalFunction}>
                Potwierdź
            </button>
        </div>
    </div>
};

export default ColorModal;
