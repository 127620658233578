import { useEffect } from 'react';

const useResizeCanvas = (id) => {
  const resizeCanvas = (id) => {
    const canvas = document.getElementById(id);
    if(canvas) {
      const heightRatio = 0.75;
      canvas.height = canvas.width * heightRatio;
    }
  }

  useEffect(() => {
    resizeCanvas(id);

    window.addEventListener('resize', () => {
      resizeCanvas(id);
    });
  }, []);
}

export default useResizeCanvas;
