import React from 'react';

const Type6Block = ({block, changeTexts, readOnly}) => {
    return <div className="blockEditor__block">
        <label>
            <textarea className="blockEditor__textarea blockEditor__textarea--fullWidth textarea--codeEditor"
                      disabled={readOnly}
                      placeholder="Wpisz kod HTML..."
                      value={block.textsArray[0]}
                      onChange={(e) => { if(!readOnly) changeTexts(0, e.target.value) }}>

            </textarea>
        </label>
    </div>
};

export default Type6Block;
