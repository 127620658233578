import { hasAccessTo, redirectToLoggedUserMenu } from '../helpers/others';
import React from 'react';
import LoadingPage from '../components/LoadingPage';
import { useSelector } from 'react-redux';

const withAccessRequired = (WrappedComponent, requiredAccess) => {
  return (props) => {
    const access = useSelector((state) => (state.user.access));

    if(!access || !requiredAccess) {
      return <LoadingPage />
    }
    else if(hasAccessTo(access, requiredAccess)) {
      return <WrappedComponent {...props} />
    }
    else {
      redirectToLoggedUserMenu();
      return null;
    }
  }
}

export default withAccessRequired;
