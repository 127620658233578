import axios from "axios";
import { getConfigWithAuthHeader, getLoggedUserId } from './others';
import { emptyArrayReponse } from './content';

const getJobsByUser = () => {
    try {
        return axios.get(`/cron-jobs/getJobsByUser/${getLoggedUserId()}`, getConfigWithAuthHeader());
    }
    catch(e) {
        return emptyArrayReponse;
    }
}

const scheduleNewJob = (website_id, job_name, parameters) => {
    return axios.post(`/cron-jobs/scheduleNewJob`, {
        user_id: getLoggedUserId(),
        website_id,
        job_name,
        parameters
    });
}

export { getJobsByUser, scheduleNewJob }
