import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { getAllBlocksByUser, getPredefinedBlocksByUser } from '../helpers/richBlocks';

const initialState = {
    predefinedBlocks: [],
    allBlocks: []
}

const fetchPredefinedBlocks = createAsyncThunk('richBlocks/fetchPredefinedBlocks', async () => {
    const response = await getPredefinedBlocksByUser();
    return response.data;
});

const fetchAllBlocks = createAsyncThunk('richBlocks/fetchAllBlocks', async () => {
    const response = await getAllBlocksByUser();
    return response.data;
})

const richBlocksSlice = createSlice({
    name: 'richBlocks',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPredefinedBlocks.fulfilled, (state, action) => {
            state.predefinedBlocks = action.payload;
        });
        builder.addCase(fetchAllBlocks.fulfilled, (state, action) => {
            state.allBlocks = action.payload;
        });
    }
})

export { fetchPredefinedBlocks, fetchAllBlocks, richBlocksSlice }
export default richBlocksSlice.reducer;
