import React from 'react';
import Loader from "./Loader";
import useCloseOnEscapeClick from '../hooks/useCloseOnEscapeClick';

const DeleteModal = ({header, text, deleteFunction, deleteStatus, deleteLoading, closeModalFunction, backText}) => {
    useCloseOnEscapeClick(closeModalFunction);

    return <div className="colorModal colorModal--delete">
        <div className="colorModal__inner">
            <button className="colorModal__close"
                    onClick={closeModalFunction}>
                &times;
            </button>
            {!deleteLoading ? <>
                <h3 className="colorModal__header">
                    {header}
                </h3>
                {!deleteStatus ? <>
                    <p className="text text-center">
                        {text}
                    </p>
                    <button className="btn btn--changeColor"
                            onClick={deleteFunction}>
                        Usuń
                    </button>
                </> : <>
                    <p className="text">
                        {deleteStatus}
                    </p>
                    <button className="btn btn--changeColor"
                            onClick={closeModalFunction}>
                        {backText ? backText : 'Wróć do listy bloków'}
                    </button>
                </>}
            </> : <div className="center">
                <Loader />
            </div>}
        </div>
    </div>
};

export default DeleteModal;
