import React from 'react';
import closeIcon from '../static/img/close-icon.svg'
import successIcon from '../static/img/green-check.svg'
import CloseModalButton from './CloseModalButton';

const SuccessModal = ({closeModal, text}) => {
    return <div className="modal modal--shopPicker" onClick={closeModal}>
        <div className="modal__inner"
             onClick={(e) => { e.stopPropagation(); }}>
            <CloseModalButton onClick={closeModal} />

            <img className="modalImg" src={successIcon} alt="sukces" />

            <h4 className="modal__subheader">
                {text}
            </h4>
        </div>
    </div>
};

export default SuccessModal;
