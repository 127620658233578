import { useEffect } from 'react';

const useClearString = (value, setValue) => {
  useEffect(() => {
    if(value) {
      setValue('');
    }
  }, [value]);
}

export default useClearString;
