import axios from 'axios';
import { getLoggedUserId } from './others';

const getAllegroAccountsByUser = () => {
  return axios.get(`/allegro/getAccountsByUser/${getLoggedUserId()}`);
}

const getConnectedAllegroAccountsByUser = () => {
  return axios.get(`/allegro/getConnectedAccountsByUser/${getLoggedUserId()}`);
}

const getAllegroTokenByAccount = (accountId) => {
  return axios.get(`/allegro/getCurrentTokenByAccount/${accountId}`);
}

const getAllegroAccountsByWebsite = (websiteId) => {
  return axios.get(`/allegro/getAccountsByWebsite/${websiteId}`);
}

const createAllegroAccount = (userId, websiteId, name) => {
  return axios.post(`/allegro/createAccount`, {
    userId, websiteId, name
  });
}

const updateAllegroAccount = (id, websiteId, name) => {
  return axios.patch(`/allegro/updateAccount`, {
    id, websiteId, name
  });
}

const deleteAllegroAccount = (id) => {
  return axios.delete(`/allegro/deleteAccount/${id}`);
}

export { getAllegroAccountsByWebsite, createAllegroAccount, updateAllegroAccount,
  deleteAllegroAccount, getAllegroAccountsByUser, getAllegroTokenByAccount, getConnectedAllegroAccountsByUser }
