import React, { useEffect, useState } from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import iconBlockType1 from '../static/img/text.svg'
import iconBlockType2 from '../static/img/image.svg'
import iconBlockType3 from '../static/img/image-and-text.svg'
import iconBlockType4 from '../static/img/text-and-image.svg'
import iconBlockType5 from '../static/img/image-and-image.svg'
import iconBlockType6 from '../static/img/injection.svg'
import { useDispatch, useSelector } from 'react-redux';
import ColorModal from "../components/ColorModal";
import Loader from "../components/Loader";
import {
    addPredefinedBlock,
    addProductBlock,
    updatePredefinedBlock,
    updateProductBlock,
} from '../helpers/richBlocks';
import ListModal from "../components/ListModal";
import PredefinedBlockView from '../components/PredefinedBlockView';
import { getLoggedUserId, getQueryParam, insert } from '../helpers/others';
import { scheduleNewJob } from '../helpers/cronJobs';
import { error as err } from '../helpers/content';
import SingleBlockWrapper from '../components/SingleBlockWrapper';
import { fetchAllBlocks } from '../reducers/richBlocksSlice';
import { getWebsiteByProduct } from '../helpers/product';
import ButtonAddSection from '../components/ButtonAddSection';
import ButtonAddPredefinedBlock from '../components/ButtonAddPredefinedBlock';
import ErrorTextInfo from '../components/ErrorTextInfo';
import ToolbarColorPicker from '../components/ToolbarColorPicker';
import Row from '../components/Row';
import SuccessTextInfoWithBorder from '../components/SuccessTextInfoWithBorder';
import useScrollToTop from '../hooks/useScrollToTop';
import ChangeSectionsOrder from '../components/ChangeSectionsOrder';
import SectionTypeButton from '../components/SectionTypeButton';
import ChooseVariant from '../components/ChooseVariant';
import ButtonDeleteSection from '../components/ButtonDeleteSection';
import ChooseContext from '../components/ChooseContext';
import ChooseTitleAndPreview from '../components/ChooseTitleAndPreview';
import { getParametersByProduct, updateParameters } from '../helpers/parameters';
import ParametersEdition from '../components/ParametersEdition';

const blockTypeIcons = [iconBlockType1, iconBlockType2, iconBlockType3, iconBlockType4, iconBlockType5, iconBlockType6];
const defaultBlockObject = {
    sectionHtmlTemplateId: 1,
    name: '',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    imagesArray: [],
    textsArray: ['']
};

const BlockEditor = () => {
    const allBlocks = useSelector(state => state.richBlocks.allBlocks);
    const allPredefinedBlocks = useSelector(state => state.richBlocks.predefinedBlocks);
    const allProducts = useSelector(state => state.products.allProducts);

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [refr, setRefr] = useState(true);
    const [id, setId] = useState(-1);
    const [productId, setProductId] = useState(-1);
    const [productName, setProductName] = useState('');
    const [productExternalId, setProductExternalId] = useState(-1);
    const [websiteId, setWebsiteId] = useState(null);
    const [websiteAddress, setWebsiteAddress] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const [predefinedBlockModalVisible, setPredefinedBlockModalVisible] = useState(false);
    const [insertPredefinedBlockPosition, setInsertPredefinedBlockPosition] = useState(0);
    const [currentContextIndex, setCurrentContextIndex] = useState(0);
    const [currentVariantIndex, setCurrentVariantIndex] = useState(0);
    const [currentVariant, setCurrentVariant] = useState([defaultBlockObject]);
    const [parameters, setParameters] = useState([]);

    // Predefined block by default - array (contexts) of arrays (variants) of arrays (sections)
    const [blockJsonValue, setBlockJsonValue] = useState([[
        [defaultBlockObject]
    ]]);
    const [colorModalForBackground, setColorModalForBackground] = useState(-1);
    const [colorModalForText, setColorModalForText] = useState(-1);

    useScrollToTop(success);

    useEffect(() => {
        const success = localStorage.getItem('successText');
        if(success) {
            localStorage.removeItem('successText');
            setSuccess(success);
        }
    }, []);

    useEffect(() => {
        if(allProducts && allBlocks) {
            const idParam = parseInt(getQueryParam('id'));
            const productParam = parseInt(getQueryParam('product'));

            const setCurrentBlockData = (currentBlock) => {
                setName(currentBlock.name);
                setBlockJsonValue(JSON.parse(currentBlock.json_value));
                setUpdateMode(true);
            }

            const setCurrentProductData = (productObject, id) => {
                setProductName(productObject?.p_productName);
                setWebsiteAddress(productObject?.w_url);
                setProductId(id);
            }

            let currentBlock;

            if(idParam) {
                setId(idParam);
                currentBlock = allBlocks.find((item) => (item.id === idParam));
                console.log('no');
            }
            else if(productParam) {
                setCurrentProductData(allProducts.find((item) => (item.p_id === productParam)), productParam);
                currentBlock = allBlocks.find((item) => (item.product_id === productParam));
            }

            if(currentBlock) {
                setCurrentBlockData(currentBlock);
            }
        }
    }, [allBlocks, allProducts]);

    useEffect(() => {
        if(isProduct()) {
            getParametersByProduct(productId)
              .then((res) => {
                  if(res.data) {
                      setParameters(res.data);
                  }
              });

            getWebsiteByProduct(productId)
              .then((res) => {
                  if(res.data) {
                      setProductExternalId(res.data.externalId);
                      setWebsiteId(res.data.websiteId);
                  }
              });
        }
    }, [productId]);

    useEffect(() => {
        setCurrentVariant(blockJsonValue[currentContextIndex][currentVariantIndex]);
    }, [blockJsonValue, currentContextIndex, currentVariantIndex]);

    const addVariant = () => {
        setBlockJsonValue(prevState => {
            return prevState.map((item, index) => {
                if(index === currentContextIndex) {
                    return [...item, [defaultBlockObject]]
                }
                else {
                    return item;
                }
            });
        });
    }

    const deleteVariant = () => {
        if(blockJsonValue.length > 1) {
            setCurrentVariantIndex(prevState => (prevState === 0 ? 1 : prevState-1));
            setBlockJsonValue(prevState => {
                return prevState.map((item, index) => {
                    if(index === currentContextIndex) {
                        return item.filter((item, index) => (index !== currentVariantIndex));
                    }
                    else {
                        return item;
                    }
                })
            });
        }
    }

    const addSectionBefore = (i) => {
        if(blockJsonValue.length) {
            setBlockJsonValue(prevState => {
                return prevState.map((item, index) => {
                    if(index === currentContextIndex) {
                        return item.map((item, index) => {
                            if(index === currentVariantIndex) {
                                return insert(item, i, defaultBlockObject);
                            }
                            else {
                                return item;
                            }
                        })
                    }
                    else {
                        return item;
                    }
                })
            });
        }
        else {
            setBlockJsonValue([defaultBlockObject]);
        }
    }

    const addSectionAfter = (i) => {
        setBlockJsonValue(prevState => {
            return prevState.map((item, index) => {
                if(index === currentContextIndex) {
                    return item.map((item, index) => {
                        if(index === currentVariantIndex) {
                            return insert(item, i + 1, defaultBlockObject);
                        }
                        else {
                            return item;
                        }
                    });
                }
                else {
                    return item;
                }
            });
        });
    }

    const insertPredefinedBlock = (block) => {
        setPredefinedBlockModalVisible(false);

        setBlockJsonValue(prevState => {
            return prevState.map((item, index) => {
                if(index === currentContextIndex) {
                    return item.map((item, index) => {
                        if(index === currentVariantIndex) {
                            return insert(item, insertPredefinedBlockPosition, {
                                isPredefinedBlock: true,
                                predefinedBlockId: block.id
                            });
                        }
                        else {
                            return item;
                        }
                    });
                }
                else {
                    return item;
                }
            })
        });
    }

    const chooseType = (section, type) => {
        setBlockJsonValue(prevState => (prevState.map((item, index) => {
            if(index === currentContextIndex) {
                return item.map((item, index) => {
                    if(index === currentVariantIndex) {
                        return item.map((item, index) => {
                            if(index === section) {
                                return {...item, sectionHtmlTemplateId: type}
                            }
                            else {
                                return item;
                            }
                        })
                    }
                    else {
                        return item;
                    }
                })
            }
            else {
                return item;
            }
        })));
    }

    const changeCurrentBlockBackgroundColor = (color) => {
        setBlockJsonValue(prevState => (prevState.map((item, index) => {
            if(index === currentContextIndex) {
                return item.map((item, index) => {
                    if(index === currentVariantIndex) {
                        return item.map((item, index) => {
                            if(index === colorModalForBackground) {
                                return {
                                    ...item,
                                    backgroundColor: color.hex
                                }
                            }
                            else {
                                return item;
                            }
                        });
                    }
                    else {
                        if(index === colorModalForBackground) {
                            return {
                                ...item,
                                backgroundColor: color.hex
                            }
                        }
                        else {
                            return item;
                        }
                    }
                });
            }
            else {
                return item;
            }
        })))
    }

    const changeCurrentBlockTextColor = (color) => {
        setBlockJsonValue(prevState => (prevState.map((item, index) => {
            if (index === currentContextIndex) {
                return item.map((item, index) => {
                    if (index === currentVariantIndex) {
                        return item.map((item, index) => {
                            if (index === colorModalForText) {
                                return {
                                    ...item,
                                    textColor: color.hex
                                }
                            } else {
                                return item;
                            }
                        });
                    } else {
                        return item;
                    }
                });
            } else {
                return item;
            }
        })));
    }

    const changeBlockTexts = (block, textIndex, text, append= false) => {
        setBlockJsonValue(prevState => (prevState.map((item, index) => {
            if(index === currentContextIndex) {
                return item.map((item, index) => {
                    if(index === currentVariantIndex) {
                        return item.map((item, index) => {
                            if(index === block) {
                                return {
                                    ...item,
                                    textsArray: textIndex < item.textsArray.length ? item.textsArray.map((item, index) => {
                                        if(index === textIndex) {
                                            return append ? `${item}${text}` : text;
                                        }
                                        else {
                                            return item;
                                        }
                                    }) : [...item.textsArray, text]
                                }
                            }
                            else {
                                return item;
                            }
                        });
                    }
                    else {
                        return item;
                    }
                });
            }
            else {
                return item;
            }
        })));
    }

    const isImageSizeRight = (w, h, files) => {
        return (w < 4000 && h < 4000) && Array.from(files).length;
    }

    const showImageTooLargeAlert = () => {
        alert('Maksymalny dopuszczalny rozmiar pliku to 5 MB i 4000x4000px');
    }

    const changeBlockImages = (block, imageIndex, files) => {
        if(files[0].size > 5000000) {
            showImageTooLargeAlert();
            return 0;
        }

        let file, img;
        if((file = files[0])) {
            const URL = window.URL || window.webkitURL;
            let objectUrl = URL.createObjectURL(file);
            img = new Image();

            img.onload = function () {
                if(isImageSizeRight(this.width, this.height, files)) {
                    const file = files[0];
                    addImagesToJSON(file, block, imageIndex);
                }
                else {
                    showImageTooLargeAlert();
                }

                URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }
    }

    const addImagesToJSON = (file, block, imageIndex) => {
        setBlockJsonValue(prevState => (prevState.map((item, index) => {
            if(index === currentContextIndex) {
                return item.map((item, index) => {
                    if(index === currentVariantIndex) {
                        return item.map((item, index) => {
                            if(index === block) {
                                return {
                                    ...item,
                                    imagesArray: imageIndex < item.imagesArray.length ? item.imagesArray.map((item, index) => {
                                        if(index === imageIndex) {
                                            return file;
                                        }
                                        else {
                                            return item;
                                        }
                                    }) : [...item.imagesArray, file]
                                }
                            }
                            else {
                                return item;
                            }
                        });
                    }
                    else {
                        return item;
                    }
                });
            }
            else {
                return item;
            }
        })));
    }

    const removeImage = (block, imageIndex) => {
        setBlockJsonValue(prevState => (prevState.map((item, index) => {
            if(index === currentContextIndex) {
                return item.map((item, index) => {
                    if(index === currentVariantIndex) {
                        return item.map((item, index) => {
                            if(index === block) {
                                return {
                                    ...item,
                                    imagesArray: item.imagesArray.map((item, index) => (index === imageIndex ? '' : item))
                                }
                            }
                            else {
                                return item;
                            }
                        });
                    }
                    else {
                        return item;
                    }
                });
            }
            else {
                return item;
            }
        })));
    }

    const getBlockComponentByType = (block, blockIndex) => {
        switch(block.sectionHtmlTemplateId) {
            case 1:
                return <SingleBlockWrapper n={1}
                                           block={block}
                                           changeTexts={(textIndex, val, append= false) => { changeBlockTexts(blockIndex, textIndex, val, append); }} />
            case 2:
                return <SingleBlockWrapper n={2}
                                           block={block}
                                           removeImage={(imageIndex) => { removeImage(blockIndex, imageIndex) }}
                                           changeImages={(imageIndex, file) => { changeBlockImages(blockIndex, imageIndex, file) }} />
            case 3:
                return <SingleBlockWrapper n={3}
                                           block={block}
                                           changeTexts={(textIndex, val, append= false) => { changeBlockTexts(blockIndex, textIndex, val, append); }}
                                           removeImage={(imageIndex) => { removeImage(blockIndex, imageIndex) }}
                                           changeImages={(imageIndex, file) => { changeBlockImages(blockIndex, imageIndex, file) }} />
            case 4:
                return <SingleBlockWrapper n={4}
                                           block={block}
                                           changeTexts={(textIndex, val, append=false) => { changeBlockTexts(blockIndex, textIndex, val, append); }}
                                           removeImage={(imageIndex) => { removeImage(blockIndex, imageIndex) }}
                                           changeImages={(imageIndex, file) => { changeBlockImages(blockIndex, imageIndex, file) }} />
            case 5:
                return <SingleBlockWrapper n={5}
                                           block={block}
                                           removeImage={(imageIndex) => { removeImage(blockIndex, imageIndex) }}
                                           changeImages={(imageIndex, file) => { changeBlockImages(blockIndex, imageIndex, file) }} />
            case 6:
                return <SingleBlockWrapper n={6}
                                           block={block}
                                           changeTexts={(textIndex, val, append=false) => { changeBlockTexts(blockIndex, textIndex, val, append); }} />;
            default:
                break;
        }
    }

    const moveBlockUp = (blockIndex) => {
        if(blockIndex > 0) {
            const switchedBlocks = switchBlocks(blockIndex, blockIndex-1);
            updateJsonAfterBlockMove(switchedBlocks);
        }
    }

    const moveBlockDown = (blockIndex) => {
        if(blockIndex < currentVariant.length - 1) {
            const switchedBlocks = switchBlocks(blockIndex, blockIndex+1);
            updateJsonAfterBlockMove(switchedBlocks);
        }
    }

    const switchBlocks = (a, b) => {
        let tmpBlocks = currentVariant;
        let tmp = tmpBlocks[a];
        tmpBlocks[a] = tmpBlocks[b];
        tmpBlocks[b] = tmp;

        return tmpBlocks;
    }

    const updateJsonAfterBlockMove = (tmpBlocks) => {
        setBlockJsonValue(prevState => prevState.map((item, index) => {
            if(index === currentContextIndex) {
                return item.map((item, index) => {
                    if(index === currentVariantIndex) {
                        return [...tmpBlocks];
                    }
                    else {
                        return item;
                    }
                });
            }
            else {
                return item;
            }
        }));
    }

    const deleteSection = (sectionIndex) => {
        if(currentVariant?.length > 0) {
            setBlockJsonValue(prevState => (prevState.map((item, index) => {
                if(index === currentContextIndex) {
                    return item.map((item, index) => {
                        if(index === currentVariantIndex) {
                            return item.filter((item, index) => {
                                return index !== sectionIndex;
                            });
                        }
                        else {
                            return item;
                        }
                    });
                }
                else {
                    return item;
                }
            })));
        }
    }

    const isProduct = () => {
        return productId !== -1;
    }

    const saveBlock = () => {
        setLoading(true);
        setSuccess('');

        if(updateMode) {
            updateBlock();
        }
        else {
            addBlock();
        }
    }

    const updateBlock = async () => {
        try {
            if(isProduct()) {
                const blockId = allBlocks.find((item) => (item.product_id === productId))?.id;
                const blockSaveResult = await updateProductBlock(blockJsonValue, blockId, name, productId);

                if(blockSaveResult) {
                    blockSaveSuccess('Produkt został zaktualizowany', 'product', productId);
                }
                else {
                    blockSaveError();
                }
            }
            else {
                const blockSaveResult = await updatePredefinedBlock(blockJsonValue, id, name);

                if(blockSaveResult) {
                    blockSaveSuccess('Blok CMS został zaktualizowany', 'id', id);
                }
                else {
                    blockSaveError();
                }
            }
        }
        catch(e) {
            console.log(e);
            blockSaveError();
        }
    }

    const addBlock = async () => {
        try {
            if(isProduct()) {
                const addBlockResult = await addProductBlock(blockJsonValue, name, productId);

                if(addBlockResult) {
                    blockSaveSuccess('Produkt został dodany', 'product', productId);
                }
                else {
                    blockSaveError();
                }
            }
            else {
                const addBlockResult = await addPredefinedBlock(blockJsonValue, name);

                if(addBlockResult) {
                    blockSaveSuccess('Blok CMS został dodany', 'id', addBlockResult?.data?.id);
                }
                else {
                    blockSaveError();
                }
            }
        }
        catch(e) {
            blockSaveError();
        }
    }

    const blockSaveSuccess = (info, key, value) => {
        localStorage.setItem('successText', info);
        window.location = `/edytor-bloku-cms?${key}=${value}`;
    }

    const blockSaveError = () => {
        setLoading(false);
        setError(err);
    }

    const updateProductInIdoSell = async () => {
        try {
            setSuccess('');

            await updateParameters(websiteId, productId, productExternalId, parameters);

            // Save changes and then send it to IdoSell
            const blockId = allBlocks.find((item) => (item.product_id === productId))?.id;
            const userId = getLoggedUserId();

            if(updateMode) {
                await updateProductBlock(blockJsonValue, blockId, name, productId);
            }
            else {
                await addProductBlock(blockJsonValue, name, productId);
                setUpdateMode(true);
                dispatch(fetchAllBlocks());
            }

            await scheduleNewJob(websiteId, 'updateProductsInIdoSell', {
                syncOptions: {
                    syncCMS: true,
                    syncParams: true,
                    syncCodes: true,
                    syncImages: true,
                    syncAllegro: true,
                    updateNew: false
                },
                website_id: websiteId,
                products: [{
                    id: productExternalId,
                    websiteId: websiteId
                }],
                user_id: userId
            });

            setSuccess('Zadanie aktualizacji produktu w IdoSell na podstawie danych z Extendommerce zostało dodane do kolejki');
        }
        catch(e) {
            console.log(e);
            setError(err);
        }
    }

    const currentContextIndexQuickChange = (a, b) => {
        setCurrentVariantIndex(a);
        setCurrentVariantIndex(b);
    }

    const changeContext = (n) => {
        setCurrentVariantIndex(0);
        setCurrentContextIndex(n);
    }

    useEffect(() => {
        setRefr(false);
    }, [currentContextIndex]);

    useEffect(() => {
        if(!refr) {
            setRefr(true);
        }
    }, [refr]);

    const updateParametersNames = (i, val) => {
        setParameters(prevState => (prevState.map((item, index) => {
            if(index === i) {
                return {
                    ...item,
                    name: val
                }
            }
            else {
                return item;
            }
        })));
    }

    const updateParametersValues = (i, val) => {
        setParameters(prevState => (prevState.map((item, index) => {
            if(index === i) {
                return {
                    ...item,
                    value: val
                }
            }
            else {
                return item;
            }
        })));
    }

    const openModalWithPredefinedBlocks = (index) => {
        setInsertPredefinedBlockPosition(index);
        setPredefinedBlockModalVisible(true);
    }

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton backLink={productId <= 0 ? '/predefiniowane-bloki-cms' : '/twoje-produkty'} />

        {colorModalForBackground !== -1 ? <ColorModal color={currentVariant[colorModalForBackground].backgroundColor}
                                                      closeModalFunction={() => { setColorModalForBackground(-1); }}
                                                      onChange={changeCurrentBlockBackgroundColor} /> : ""}

        {colorModalForText !== -1 ? <ColorModal color={currentVariant[colorModalForText].textColor}
                                                closeModalFunction={() => { setColorModalForText(-1); }}
                                                onChange={changeCurrentBlockTextColor} /> : ""}

        {predefinedBlockModalVisible ? <ListModal list={allPredefinedBlocks}
                                                  closeModalFunction={() => { setPredefinedBlockModalVisible(false); }}
                                                  chooseItem={insertPredefinedBlock} /> : ''}

        <main className="main w">
            <h1 className="main__header main__header--flex main__header--cms">
                Edytor CMS dla {productId ? 'towaru:' : 'bloku predefiniowanego:'} {productId ? productName : name}

                <SuccessTextInfoWithBorder content={success} />
            </h1>

            <ChooseTitleAndPreview name={name}
                                   setName={setName}
                                   currentVariant={currentVariant}
                                   productId={productId}
                                   productExternalId={productExternalId}
                                   websiteAddress={websiteAddress} />

            <ChooseContext currentContextIndex={currentContextIndex}
                           currentVariantIndex={currentVariantIndex}
                           changeContext={changeContext}
                           blockJsonValue={blockJsonValue}
                           setBlockJsonValue={setBlockJsonValue}
                           currentContextIndexQuickChange={currentContextIndexQuickChange} />

            {!isProduct() ? <ChooseVariant currentVariantIndex={currentContextIndex}
                                           setCurrentVariantIndex={setCurrentVariantIndex}
                                           currentContextIndex={currentContextIndex}
                                           blockJsonValue={blockJsonValue}
                                           addVariant={addVariant}
                                           deleteVariant={deleteVariant}/> : ''}

            {isProduct() && parameters?.length ? <ParametersEdition parameters={parameters}
                                                                    updateParametersValues={updateParametersValues}
                                                                    updateParametersNames={updateParametersNames} /> : ''}

            {currentVariant?.length === 0 ? <Row>
                <ButtonAddSection onClick={() => { addSectionBefore(0); }} />
                <ButtonAddPredefinedBlock onClick={() => { openModalWithPredefinedBlocks(0); }} />
            </Row> : ''}

            {currentVariant.map((item, index) => {
                if(item.isPredefinedBlock) {
                    return <>
                        {index === 0 ? <Row>
                            <ButtonAddSection onClick={() => { addSectionBefore(index); }} />
                            <ButtonAddPredefinedBlock onClick={() => { openModalWithPredefinedBlocks(index); }} />
                        </Row> : ''}

                        <PredefinedBlockView id={item.predefinedBlockId}
                                             context={currentContextIndex}
                                             moveBlockDown={moveBlockDown}
                                             moveBlockUp={moveBlockUp}
                                             removeBlock={() => { deleteSection(index); }}
                                             index={index} />

                        <Row>
                            <ButtonAddSection onClick={() => { addSectionAfter(index); }} />
                            <ButtonAddPredefinedBlock onClick={() => { openModalWithPredefinedBlocks(index+1); }} />
                        </Row>
                    </>
                }
                else {
                    const templateId = item.sectionHtmlTemplateId;
                    const sectionIndex = index;

                    return <div className="blockEditor__section" key={index}>
                        {index === 0 ? <Row>
                            <ButtonAddSection onClick={() => { addSectionBefore(index); }} />
                            <ButtonAddPredefinedBlock onClick={() => { openModalWithPredefinedBlocks(index); }} />
                        </Row> : ''}

                        <div className="blockEditor__section__header">
                            <div className="blockEditor__section__header__type">
                                <h4>
                                    Typ sekcji:
                                </h4>
                                {blockTypeIcons.map((item, index) => {
                                    return <SectionTypeButton chooseType={() => { chooseType(sectionIndex, index + 1); }}
                                                              templateId={templateId}
                                                              sectionIndex={sectionIndex}
                                                              index={index}
                                                              icon={item} />
                                })}
                            </div>

                            <ToolbarColorPicker label={'Kolor tekstu:'}
                                                color={item.textColor}
                                                onClick={() => { setColorModalForText(sectionIndex); }} />
                            <ToolbarColorPicker label={'Kolor tła:'}
                                                color={item.backgroundColor}
                                                onClick={() => { setColorModalForBackground(sectionIndex); }} />

                            <ChangeSectionsOrder moveBlockUp={moveBlockUp}
                                                 moveBlockDown={moveBlockDown}
                                                 index={index} />

                            <ButtonDeleteSection onClick={() => { deleteSection(index); }} />
                        </div>

                        {refr ? getBlockComponentByType(item, index) : ''}

                        <Row>
                            <ButtonAddSection onClick={() => { addSectionAfter(index); }} />
                            <ButtonAddPredefinedBlock onClick={() => { openModalWithPredefinedBlocks(index+1); }} />
                        </Row>
                    </div>
                }
            })}

            {!error && !loading ? <button className="btn btn--submit btn--submit--blockEditor"
                                                      onClick={saveBlock}>
                Zapisz blok CMS
            </button> : (loading ? <Loader /> : <ErrorTextInfo content={error} />)}

            {isProduct() ? <button className="btn btn--option"
                                        onClick={updateProductInIdoSell}>
                Wczytaj dane z Extendommerce do IdoSell dla aktualnie edytowanego towaru
            </button> : ''}
        </main>
    </div>
}

export default BlockEditor;
