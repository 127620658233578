import React from 'react';
import Select from 'react-select';
import ClearRangeCheckbox from './ClearRangeCheckbox';

const OrderStatusFilter = ({label, statuses, value, setValue, clear, setClear}) => {
  return <div className="shopItem__filterWrapper">
    <label>
      <span className="key">
        {label}
      </span>
      <Select className="select--orderStatuses"
              defaultValue={statuses[0]}
              options={statuses}
              placeholder="Wybierz status"
              value={value}
              onChange={setValue} />
    </label>

    <ClearRangeCheckbox clear={clear}
                        setClear={setClear} />
  </div>
};

export default OrderStatusFilter;
