import React, { useEffect, useState } from 'react';
import { getAllPackagesAssignedToSelectedUser } from '../helpers/orders';
import Loader from './Loader';
import TablePackagesRow from './TablePackagesRow';
import PackagesSummaryItem from './PackagesSummaryItem';
import PackagesTimeFilterButtons from './PackagesTimeFilterButtons';
import { hasAccessTo } from '../helpers/others';
import { useSelector } from 'react-redux';

const PackagesFilter = ({currentPackerId}) => {
  const access = useSelector((state) => (state.user.access));

  const [currentFilter, setCurrentFilter] = useState(0);
  const [numberOfHours, setNumberOfHours] = useState(5);
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [numberOfPackages, setNumberOfPackages] = useState(0);
  const [allOrdersCost, setAllOrdersCost] = useState(0);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [weightSum, setWeightSum] = useState(0);
  const [volumeSum, setVolumeSum] = useState(0);
  const [weightMean, setWeightMean] = useState(0);
  const [volumeMean, setVolumeMean] = useState(0);
  const [numberOfProductsWithZeroWeight, setNumberOfProductsWithZeroWeight] = useState(0);
  const [numberOfProductsWithZeroVolume, setNumberOfProductsWithZeroVolume] = useState(0);
  const [numberOfPackagesPackedWithAnotherUser, setNumberOfPackagesPackedWithAnotherUser] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ordersCostVisible, setOrdersCostVisible] = useState(false);

  useEffect(() => {
    if(hasAccessTo(access, 'orders_values')) {
      setOrdersCostVisible(true);
    }
  }, [access]);

  useEffect(() => {
    if(currentPackerId) {
      getAllPackagesAssignedToSelectedUser(currentPackerId)
        .then((res) => {
          if(res?.status === 201) {
            setLoading(false);
            setPackages(res?.data);
            setFilteredPackages(res?.data);
          }
        });
    }
  }, [currentPackerId]);

  useEffect(() => {
    let dateBegin = new Date();

    switch(currentFilter) {
      case 0:
        dateBegin.setHours(0);
        dateBegin.setMinutes(0);
        dateBegin.setSeconds(1);
        break;
      case 1:
        dateBegin.setHours(dateBegin.getHours() - 24);
        break;
      case 2:
        dateBegin.setHours(dateBegin.getHours() - 12);
        break;
      case 3:
        dateBegin.setHours(dateBegin.getHours() - numberOfHours);
        break;
      default:
        dateBegin = new Date('2000-01-01 00:00:00');
        break;
    }

    setFilteredPackages(packages.filter((item) => {
      return new Date(item.assignmentDate) > dateBegin;
    }));
  }, [currentFilter, numberOfHours, packages, refresh]);

  useEffect(() => {
    // Update summary
    setNumberOfPackages(filteredPackages.reduce((prev, curr) => {
      return prev + curr.numberOfPackages;
    }, 0));

    setAllOrdersCost(filteredPackages.reduce((prev, curr) => {
      return prev + curr.cost;
    }, 0).toFixed(2));

    setNumberOfProducts(filteredPackages.reduce((prev, curr) => {
      return prev + curr.products.length;
    }, 0));

    setNumberOfPackagesPackedWithAnotherUser(filteredPackages.filter((item) => {
      return item.packWithAnotherUser;
    }).length);

    setWeightSum(filteredPackages.reduce((prev, curr) => {
      return prev + curr.products.reduce((prev, curr) => {
        return prev + curr.productWeight;
      }, 0);
    }, 0));

    setVolumeSum(filteredPackages.reduce((prev, curr) => {
      return prev + curr.products.reduce((prev, curr) => {
        return prev + curr.productWidth * curr.productLength * curr.productHeight;
      }, 0);
    }, 0));

    setNumberOfProductsWithZeroWeight(filteredPackages.reduce((prev, curr) => {
      return prev + curr.products.filter((item) => {
        return !item.productWeight;
      }).length;
    }, 0));

    setNumberOfProductsWithZeroVolume(filteredPackages.reduce((prev, curr) => {
      return prev + curr.products.filter((item) => {
        return !(item.productWidth * item.productLength * item.productHeight);
      }).length;
    }, 0));
  }, [filteredPackages]);

  useEffect(() => {
    if(numberOfProducts) {
      setWeightMean(weightSum / numberOfProducts);
    }
  }, [numberOfProducts, weightSum]);

  useEffect(() => {
    if(numberOfProducts) {
      setVolumeMean(volumeSum / numberOfProducts);
    }
  }, [numberOfProducts, volumeSum]);

  return <div className="packing__section">
    <h4 className="packing__header">
      Lista paczek, które spakowałeś
    </h4>

    <PackagesTimeFilterButtons currentFilter={currentFilter}
                               setCurrentFilter={setCurrentFilter}
                               numberOfHours={numberOfHours}
                               setNumberOfHours={setNumberOfHours}
                               setRefresh={setRefresh} />

    {filteredPackages?.length ? <div className="packing__packagesSummary">
      <PackagesSummaryItem label={'Łączna ilość paczek'}
                           value={numberOfPackages} />
      <PackagesSummaryItem label={'Ilość zamówień, do których jesteś przypisany'}
                           value={filteredPackages.length} />
      <PackagesSummaryItem label={'Ilość zamówień pakowanych niesamodzielnie'}
                           value={numberOfPackagesPackedWithAnotherUser} />
      <PackagesSummaryItem displayIf={ordersCostVisible}
                           label={'Łączna wartość zamówień'}
                           value={allOrdersCost} />
      <PackagesSummaryItem label={'Łączna ilość produktów we wszystkich paczkach'}
                           value={numberOfProducts} />
      <PackagesSummaryItem label={'Łączna waga wszystkich zamówień'}
                           value={weightSum} />
      <PackagesSummaryItem label={'Łączna objętość wszystkich zamówień'}
                           injectHtml={true}
                           value={`${volumeSum / 1000} cm <sup>3</sup>`} />
      <PackagesSummaryItem label={'Średnia waga jednej paczki'}
                           value={weightMean} />
      <PackagesSummaryItem label={'Średnia objętość jednej paczki'}
                           injectHtml={true}
                           value={`${volumeMean / 1000} cm <sup>3</sup>`} />
      <PackagesSummaryItem label={'Ilość towarów z zerową wagą'}
                           value={numberOfProductsWithZeroWeight} />
      <PackagesSummaryItem label={'Ilość towarów z zerową objętością'}
                           value={numberOfProductsWithZeroVolume} />
    </div> : (loading ? <div className="marginTop">
      <Loader />
    </div> : '')}

    <div className="packing__packages">
      {filteredPackages.map((item, index) => {
        return <TablePackagesRow item={item}
                                 index={index}
                                 ordersCostVisible={ordersCostVisible} />
      })}
    </div>

  </div>
};

export default PackagesFilter;
