import React from 'react';

const PackagesPointHeader = ({sectionVisible, setSectionVisible}) => {
  return <h1 className="main__header main__header--flex">
    Punkt stanowiska pakowania

    <div className="packing__buttonsWrapper flex">
      <button className={sectionVisible === 0 ? "packing__btn packing__btn--selected" : "packing__btn"}
              onClick={() => { setSectionVisible(0); }}>
        Przypisywanie pakowacza
      </button>
      <button className={sectionVisible === 1 ? "packing__btn packing__btn--selected" : "packing__btn"}
              onClick={() => { setSectionVisible(1); }}>
        Przegląd spakowanych zamówień
      </button>
    </div>
  </h1>
};

export default PackagesPointHeader;
