import React from 'react';
import { contextNames } from '../helpers/content';
import ButtonBlueBorder from './ButtonBlueBorder';

const ChooseContext = ({currentContextIndex, currentVariantIndex, currentContextIndexQuickChange,
                         changeContext, blockJsonValue, setBlockJsonValue}) => {
  const copyFirstVariant = () => {
    const firstVariantIdoSellContext = blockJsonValue[0][0];
    setBlockJsonValue(prevState => {
      return prevState.map((item, index) => {
        if(index === currentContextIndex) {
          return item.map((item, index) => {
            if(index === currentVariantIndex) {
              return firstVariantIdoSellContext;
            }
            else {
              return item;
            }
          });
        }
        else {
          return item;
        }
      });
    })
  }

  const createNewContext = () => {
    setBlockJsonValue(prevState => {
      if(prevState.length === 1) {
        return [...prevState, prevState[currentContextIndex]];
      }
      else {
        return [prevState[0], prevState[0]];
      }
    });
    currentContextIndexQuickChange(1, 0);
  }

  const deleteAllegroContext = () => {
    currentContextIndexQuickChange(1, 0);
    setTimeout(() => {
      setBlockJsonValue(prevState => [prevState[0]]);
    }, 300);
  }


  return <h3 className="blockEditor__header">
    Kontekst: <span className="blockEditor__context">
      <select className="select"
              value={currentContextIndex}
              onChange={(e) => { changeContext(parseInt(e.target.value)); }}>
          {contextNames.map((item, index) => {
            return index < blockJsonValue.length ? <option key={index} value={index}>{item}</option> : ''
          })}
      </select>

      <ButtonBlueBorder onClick={createNewContext}>
          Utwórz dodatkowy kontekst dla Allegro na podstawie IdoSell
      </ButtonBlueBorder>
      <ButtonBlueBorder onClick={copyFirstVariant}>
          Skopiuj opis z wariantu nr. 1 kontekstu idosell dla obecnie edytowanego wariantu i kontekstu
      </ButtonBlueBorder>

      {blockJsonValue?.length > 1 ? <button className="btn btn--copyContext btn--deleteAllegroContext"
                                            onClick={deleteAllegroContext}>
        Usuń kontekst Allegro
      </button> : ''}
    </span>
  </h3>
};

export default ChooseContext;
