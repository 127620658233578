import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import useSound from 'use-sound';
import notificationSound from '../static/sounds/positiveNotification.wav';
import errorSound from '../static/sounds/negativeNotification.wav';
import { apiQueueSlice } from '../reducers/apiQueueSlice';
import startQueue from '../helpers/startQueue';
import ApiQueueView from './ApiQueueView';
import ErrorTextInfo from './ErrorTextInfo';
import InputMassEditionForm from './InputMassEditionForm';

const MassEditionForm = ({paramsToEdit, paramsLabels}) => {
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [length, setLength] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [error, setError] = useState('');

  const [paramsValues, setParamsValues] = useState([]);

  const [playNotificationSound] = useSound(notificationSound);
  const [playErrorSound] = useSound(errorSound);

  let selectRef = useRef(null);

  const apiQueue = useSelector((state) => (state.apiQueue.apiQueue));
  const products = useSelector((state) => (state.products.allProducts));
  const dispatch = useDispatch();

  useEffect(() => {
    if(paramsLabels?.length) {
      setParamsValues(paramsLabels.map(() => ('')));
    }
  }, [paramsLabels]);

  useEffect(() => {
    setProductList(products.map((item) => {
      return {
        label: `${item.p_productName} (id sys. zewn: ${item.p_external_id} | kod kreskowy: ${item.p_productUniversalSizeProducerCode ? item.p_productUniversalSizeProducerCode : 'brak'})`,
        value: item.p_id,
        externalId: item.p_external_id,
        websiteId: item.p_website_id
      }
    }));
  }, [products]);

  useEffect(() => {
    if(selectedProduct) {
      setError('');
      skipToNextForm('first');
    }
  }, [selectedProduct]);

  const addProductToEdition = (e) => {
    e.preventDefault();
    skipToNextForm('first');
  }

  const skipToNextForm = (e, forceSkip = false, double = false, byTwo = false) => {
    if(e === 'first') {
      const form = document.querySelector('.massEditionForm');
      form.elements[0]?.focus();
      return false;
    }
    else {
      if(e.key === 'Enter' || forceSkip) {
        e.preventDefault();
        const form = e.target.closest('form');
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + (byTwo ? 2 : 1)]?.focus();
        if(double) {
          skipToNextForm(e, true, false, true);
        }
        else {
          return false;
        }
      }
    }
  }

  const massEditionSubmit = (e) => {
    e.preventDefault();

    if(selectedProduct) {
      dispatch(apiQueueSlice.actions.add({
        url: 'externalSystemProducts/massEdition',
        payload: {
          id: selectedProduct.value,
          externalId: selectedProduct.externalId,
          websiteId: selectedProduct.websiteId,
          productWeight: paramsValues[3],
          productWidth: paramsValues[1],
          productLength: paramsValues[0],
          productHeight: paramsValues[2],
          parameters: paramsValues.map((item, index) => ({
            parameterId: paramsLabels[index].value,
            parameterName: paramsLabels[index].label,
            parameterValue: item
          })).filter((item) => (item.parameterValue || item.parameterId === -1))
        },
        status: 'request',
        errorValue: null
      }));

      setParamsValues(prevState => (prevState.map(() => (''))));

      playNotificationSound();
      setError('');

      selectRef.current.focus();
    }
    else if(!selectedProduct) {
      playErrorSound();
      setError('Wybierz produkt, który chcesz edytować');
    }
  }

  useEffect(() => {
    startQueue(apiQueue, dispatch);
  }, [apiQueue]);

  const isParamToEdit = (n) => {
    return paramsToEdit.includes(n);
  }

  const handleParamsValuesChange = (i, val) => {
    setParamsValues(prevState => (prevState.map((item, index) => {
      if(index === i) {
        return val;
      }
      else {
        return item;
      }
    })));
  }

  return <div className="packing__section">
    <form className="packing__form flex"
          onSubmit={addProductToEdition}>
      <div className="packing__form__input">
        <Select ref={selectRef}
                defaultValue=""
                options={productList}
                placeholder="Wybierz produkt"
                value={selectedProduct}
                onChange={setSelectedProduct} />
      </div>
      <button type="submit"
              className="btn btn--scanShippingNumber btn--addProductToMassEdition">
        Zatwierdź
      </button>
    </form>

    <h3 className="massEdition__header">
      Przypisz wartości:
    </h3>
    <div className="massEdition__mainWrapper">
      <form className="massEditionForm"
            onSubmit={massEditionSubmit}>

        {paramsLabels.map((item, index) => {
          return isParamToEdit(index) ? <InputMassEditionForm label={item.label}
                                                              value={paramsValues[index]}
                                                              setValue={(val) => { handleParamsValuesChange(index, val); }}
                                                              skipToNextForm={skipToNextForm}
                                                              tabIndex={(index+2).toString()} /> : '';
        })}

        <ErrorTextInfo content={error}
                       className={'info info--error info--error--massEdition'} />

        <button type="submit"
                onFocus={massEditionSubmit}
                className="btn btn--submitMassEdition">
          Zapisz
        </button>
      </form>

      <ApiQueueView columnToExtractFromPayload="id" />
    </div>
  </div>
};

export default MassEditionForm;
