import React, {useEffect, useRef, useState} from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import Gallery from "../components/Gallery";
import filterIcon from '../static/img/filter.svg'
import { isElementInArray } from '../helpers/others';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../components/Loader';
import useUserShops from '../hooks/useUserShops';
import useClearString from '../hooks/useClearString';
import useClearArray from '../hooks/useClearArray';
import InputFilter from '../components/InputFilter';
import ArrayFilter from '../components/ArrayFilter';
import TwoButtonsSort from '../components/TwoButtonsSort';
import TableProductsRow from '../components/TableProductsRow';
import useFilterProducts from '../hooks/useFilterProducts';

const UserProducts = () => {
    const products = useSelector((state) => (state.products.allProducts));
    const predefinedBlocks = useSelector((state => (state.richBlocks.predefinedBlocks)));

    const { shops } = useUserShops();

    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filteredProductsToRender, setFilteredProductsToRender] = useState([]);
    const [page, setPage] = useState(1);
    const [galleries, setGalleries] = useState([]);
    const [currentGallery, setCurrentGallery] = useState(-1);
    const [filteredShops, setFilteredShops] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [idFilterValue, setIdFilterValue] = useState('');
    const [shopFilterValue, setShopFilterValue] = useState('');
    const [nameFilterValue, setNameFilterValue] = useState('');
    const [codeFilterValue, setCodeFilterValue] = useState('');
    const [producerFilterValue, setProducerFilterValue] = useState('');
    const [categoriesFilterValue, setCategoriesFilterValue] = useState([]);
    const [blocksFilterValue, setBlocksFilterValue] = useState([]);
    const [newCategoryFilterValue, setNewCategoryFilterValue] = useState('');
    const [newBlockFilterValue, setNewBlockFilterValue] = useState('');
    const [stockSort, setStockSort] = useState(0);
    const [clearShop, setClearShop] = useState(false);
    const [clearId, setClearId] = useState(false);
    const [clearName, setClearName] = useState(false);
    const [clearCode, setClearCode] = useState(false);
    const [clearProducer, setClearProducer] = useState(false);
    const [clearCategory, setClearCategory] = useState(false);
    const [clearBlock, setClearBlock] = useState(false);
    const [optionsMenu, setOptionsMenu] = useState(-1);
    const [loadingProducts, setLoadingProducts] = useState(true);

    const filtersSection = useRef(null);

    const filters = {idFilterValue, shopFilterValue, nameFilterValue, codeFilterValue, producerFilterValue, categoriesFilterValue, blocksFilterValue,
        clearShop, clearName, clearCode, clearProducer, clearCategory, clearBlock, clearId};

    useFilterProducts(products, setFilteredProducts, filters, predefinedBlocks);

    // Clear filters
    useClearString(clearShop, setShopFilterValue);
    useClearString(clearId, setIdFilterValue);
    useClearString(clearName, setNameFilterValue);
    useClearString(clearCode, setCodeFilterValue);
    useClearString(clearProducer, setProducerFilterValue);
    useClearArray(clearCategory, setCategoriesFilterValue);
    useClearArray(clearBlock, setBlocksFilterValue);

    useEffect(() => {
        if(shops?.length) {
            setFilteredShops(shops.map((item) => {
                return item.id;
            }));
        }
    }, [shops]);

    useEffect(() => {
        if(products?.length) {
            setGalleries(products?.map((item) => {
                const productImagesInExternalSystem = JSON.parse(item?.p_productImagesInExternalSystem);
                return [productImagesInExternalSystem?.productIcon?.productIconLargeUrl].concat(productImagesInExternalSystem?.productImages?.map((item) => {
                    return item?.productImageLargeUrl;
                }));
            }));

            setFilteredProducts(products);
        }
    }, [products]);

    useEffect(() => {
        setFilteredProducts(products?.filter((item) => {
            return isElementInArray(item.w_id, filteredShops);
        }));
    }, [filteredShops]);

    useEffect(() => {
        if(filteredProducts?.length) {
            setFilteredProductsToRender(prevState => {
                return [...prevState, ...filteredProducts.slice((page-1) * 12, page * 12)]
            });
            setLoadingProducts(false);
        }
    }, [page, filteredProducts]);

    useEffect(() => {
        setPage(1);
        setFilteredProductsToRender([]);
    }, [nameFilterValue, idFilterValue, shopFilterValue, codeFilterValue,
        producerFilterValue, categoriesFilterValue, blocksFilterValue, stockSort]);

    useEffect(() => {
        if(stockSort === 1) {
            setFilteredProducts(prevState => {
                return [...prevState].sort((a, b) => {
                    return a.p_stocks_total_availability > b.p_stocks_total_availability ? 1 : -1;
                });
            });
        }
        else if(stockSort === 2) {
            setFilteredProducts(prevState => {
                return [...prevState].sort((a, b) => {
                    return a.p_stocks_total_availability > b.p_stocks_total_availability ? 1 : -1;
                });
            });
        }
        else {
            setFilteredProducts(prevState => {
                return [...prevState].sort((a, b) => {
                    return a.p_external_id > b.p_external_id ? 1 : -1;
                });
            });
        }
    }, [stockSort]);

    const openProductGallery = (i) => {
        setCurrentGallery(i);
    }

    const toggleFilter = (i) => {
        if(isElementInArray(i, filteredShops)) {
            setFilteredShops(prevState => (prevState.filter((item) => (item !== i))));
        }
        else {
            setFilteredShops(prevState => ([...prevState, i]));
        }
    }

    const toggleFilters = () => {
        if(filtersVisible) {
            filtersSection.current.style.height = '0';
            filtersSection.current.style.visibility = 'hidden';
            filtersSection.current.style.padding = '0';
        }
        else {
            filtersSection.current.style.height = 'auto';
            filtersSection.current.style.padding = '5px 0 10px';
            filtersSection.current.style.visibility = 'visible';
        }

        setFiltersVisible(p => !p);
    }

    const addNewValueToCategoryFilter = () => {
        if(newCategoryFilterValue !== '') {
            setCategoriesFilterValue(prevState => ([...prevState, newCategoryFilterValue]));
            setNewCategoryFilterValue('');
        }
    }

    const addNewValueToBlockFilter = () => {
        if(newBlockFilterValue !== '') {
            setBlocksFilterValue(prevState => ([...prevState, newBlockFilterValue]));
            setNewBlockFilterValue('');
        }
    }

    const deleteValueFromCategoryFilter = (i) => {
        setCategoriesFilterValue(prevState => (prevState.filter((_, index) => (index !== i))));
    }

    const deleteValueFromBlockFilter = (i) => {
        setBlocksFilterValue(prevState => (prevState.filter((_, index) => (index !== i))));
    }

    const nextPage = () => {
        setPage(prevState => (prevState+1));
    }

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton module={1} />

        {currentGallery !== -1 ? <Gallery images={galleries[currentGallery]}
                                          index={0}
                                          setIndex={setCurrentGallery} /> : ''}

        <main className="main w">
            <h1 className="main__header">
                Lista Twoich produktów
            </h1>

            <button className="filterBtn"
                    onClick={toggleFilters}>
                Filtruj wg sklepów
                <img className="icon" src={filterIcon} alt="filtrowanie" />
            </button>

            <div className="filterSection" ref={filtersSection}>
                {shops?.map((item, index) => {
                    return <label key={index}>
                        <button className={isElementInArray(item.id, filteredShops) ? "filterSection__btn filterSection__btn--selected" : "filterSection__btn"}
                                onClick={() => { toggleFilter(item.id); }}>

                        </button>
                        {item.name}
                    </label>
                })}
            </div>

            <div className="columnsFilterSection">
                <h4 className="columnsFilterSection__header">
                    Filtry:
                </h4>
                <div className="shopItem shopItem--product shopItem--product--filters flex">
                    <InputFilter label={'Kod zewnętrznego systemu'}
                                 placeholder={'Kod zewnętrznego systemu'}
                                 type={'number'}
                                 value={idFilterValue}
                                 setValue={setIdFilterValue}
                                 clear={clearId}
                                 setClear={setClearId} />
                    <InputFilter label={'Sklep'}
                                 placeholder={'Nazwa sklepu'}
                                 value={shopFilterValue}
                                 setValue={setShopFilterValue}
                                 clear={clearShop}
                                 setClear={setClearShop} />
                    <InputFilter label={'Nazwa towaru'}
                                 placeholder={'Nazwa towaru'}
                                 value={nameFilterValue}
                                 setValue={setNameFilterValue}
                                 clear={clearName}
                                 setClear={setClearName} />
                    <InputFilter label={'Kod kreskowy'}
                                 placeholder={'Kod kreskowy'}
                                 value={codeFilterValue}
                                 setValue={setCodeFilterValue}
                                 clear={clearCode}
                                 setClear={setClearCode} />
                    <InputFilter label={'Producent'}
                                 placeholder={'Producent'}
                                 value={producerFilterValue}
                                 setValue={setProducerFilterValue}
                                 clear={clearProducer}
                                 setClear={setClearProducer} />
                    <ArrayFilter label={'Kategorie'}
                                 placeholder={'Kategorie'}
                                 newValue={newCategoryFilterValue}
                                 setNewValue={setNewCategoryFilterValue}
                                 addNewValueToFilter={addNewValueToCategoryFilter}
                                 deleteValueFromFilter={deleteValueFromCategoryFilter}
                                 filterList={categoriesFilterValue}
                                 clear={clearCategory}
                                 setClear={setClearCategory} />
                    <ArrayFilter label={'Pred. bloki CMS'}
                                 placeholder={'Bloki CMS'}
                                 newValue={newBlockFilterValue}
                                 setNewValue={setNewBlockFilterValue}
                                 addNewValueToFilter={addNewValueToBlockFilter}
                                 deleteValueFromFilter={deleteValueFromBlockFilter}
                                 filterList={blocksFilterValue}
                                 clear={clearBlock}
                                 setClear={setClearBlock} />

                    <TwoButtonsSort label={'Ilość dostępna w magazynach'}
                                    sort={stockSort}
                                    setSort={setStockSort} />

                    <label className="shopItem__filterWrapper">
                        <span className="shopItem__numberOfProducts">
                            Znaleziono produktów <span>{filteredProducts?.length}</span>
                        </span>
                    </label>
                </div>
            </div>

            <InfiniteScroll dataLength={page * 12}
                            next={nextPage}
                            height={600}
                            className="customScroll"
                            hasMore={filteredProducts.length > filteredProductsToRender.length}
                            loader={<span><Loader /></span>}
                            endMessage={<span></span>}>
                {!loadingProducts ? filteredProductsToRender?.map((item, index) => {
                    return <TableProductsRow item={item}
                                             index={index}
                                             galleries={galleries}
                                             openProductGallery={openProductGallery}
                                             setOptionsMenu={setOptionsMenu} />
                }) : <Loader />}
            </InfiniteScroll>
        </main>
    </div>
};

export default UserProducts;
