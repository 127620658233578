import React from 'react';
import plusIcon from '../static/img/add.svg'
import trashIcon from '../static/img/x-button.svg'

const ImageDropdown = ({image, imageIndex, removeImage, handleFileUpload}) => {
    return <div className="label">
        <div className={image ? "filesUploadLabel center" : "filesUploadLabel filesUploadLabel--noBorder center"}>
            {!image ? <div className="filesUploadLabel__inner">
                Dodaj zdjęcie
                <img className="img" src={plusIcon} alt="dodaj-pliki" />
            </div> : <div className="filesUploadLabel__profileImage">
                <button className="removeProfileImageBtn" onClick={() => { removeImage(imageIndex); }}>
                    <img className="img" src={trashIcon} alt="usun" />
                </button>
                <img className="img"
                     src={typeof image === "string" ? image : window.URL.createObjectURL(image)}
                     alt="zdjecie-profilowe" />
            </div>}
            <input className="input input--file"
                   type="file"
                   multiple={false}
                   onChange={(e) => { handleFileUpload(imageIndex, e.target.files); }} />
        </div>
    </div>
};

export default ImageDropdown;
