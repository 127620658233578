import React from 'react';
import backArrow from '../static/img/back-arrow.svg';

const SubmodulesMenuHeader = ({currentModule, setCurrentModule, moduleObject}) => {
  return <div className="center">
    <button className="backBtn" onClick={() => { setCurrentModule(-1); }}>
      <img className="icon" src={backArrow} alt="powrot" />
      Powrót
    </button>
    {currentModule !== -1 ? <div className="moduleItem">
      <img className="icon" src={moduleObject.icon} alt="ikonka" />
      <span className="header">
          {moduleObject.header}
      </span>
    </div> : ''}
  </div>
}

export default SubmodulesMenuHeader;
