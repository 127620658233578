import adminIcon from '../static/img/working.svg'
import shopIcon from '../static/img/shop.svg'
import productsIcon from '../static/img/products.svg'
import editorIcon from '../static/img/editor.svg'
import deliveryIcon from '../static/img/delivery.svg'
import posIcon from '../static/img/point-of-service.svg'
import syncIcon from '../static/img/sync.svg'
import shippingIcon from '../static/img/pakowanie.svg'
import cmsBlockIcon from '../static/img/construction.svg'
import cronIcon from '../static/img/clipboard.svg'
import idoSellUpdate from '../static/img/updated.svg'
import htmlIcon from '../static/img/html.svg'
import updateAllProducts from '../static/img/cashback.svg'
import imageIcon from '../static/img/image-icon.svg'
import allegroIcon from '../static/img/allegro.svg'
import parametersIcon from '../static/img/parameters.svg'

const modules = [
    {
        icon: adminIcon,
        header: 'Administrator sklepu',
        submodules: [
            {
                icon: shopIcon,
                header: 'Lista sklepów i konfiguracja',
                link: '/twoje-sklepy'
            },
            {
                icon: syncIcon,
                header: 'Synchronizacja danych',
                submodules: [
                    {
                        icon: updateAllProducts,
                        header: 'Wymuś pobranie lub aktualizacje całej bazy produktowej z Idosell',
                        action: 4,
                        tooltip: 'Skutkuje zrealizowaniem relatywnie długiej operacji (ok 3 min. na każde tysiąc produktów) oraz skutkuje nadpisaniem wszystkich danych w bazie Extendommerce danymi podchodzącymi z IdoSell'
                    },
                    {
                        icon: productsIcon,
                        header: 'Wymuś pobranie najnowszych produktów z IdoSell',
                        action: 1,
                        tooltip: 'Pobiera tylko nowe produkty, które dotychczas nie zostały zsynchronizowane z IdoSell. Domyślnie korzystaj z tej opcji, jest szybsza'
                    },
                    {
                        icon: parametersIcon,
                        header: 'Wymuś pobranie parametrów z IdoSell',
                        action: 9
                    },
                    {
                        icon: cronIcon,
                        header: 'Moje zlecone operacje serwerowe CRON',
                        link: '/moje-operacje-cron'
                    },
                    {
                        icon: htmlIcon,
                        header: 'Generuj opis HTML towarów na podstawie CMS Extendommerce',
                        action: 3
                    },
                    {
                        icon: idoSellUpdate,
                        header: 'Aktualizuj dane towarów w IdoSell na podstawie danych z Extendommerce',
                        action: 2
                    },
                    {
                        icon: allegroIcon,
                        header: 'Aktualizuj aukcje w Allegro na podstawie danych z Extendommerce',
                        action: 8
                    },
                    {
                        icon: imageIcon,
                        header: 'Wykonaj kopię zapasową zdjęć',
                        action: 5
                    },
                    {
                        icon: deliveryIcon,
                        header: 'Wymuś pobranie zamówień z IdoSell',
                        action: 6
                    },
                    {
                        icon: shippingIcon,
                        header: 'Wymuś pobranie paczek z IdoSell',
                        action: 7
                    }
                ]
            },
            {
                icon: allegroIcon,
                header: 'Konta Allegro',
                link: '/konta-allegro'
            }
        ]
    },
    {
        icon: productsIcon,
        header: 'Zarządzanie towarami i opisami',
        submodules: [
            {
                icon: productsIcon,
                header: 'Twoje towary',
                link: '/twoje-produkty'
            },
            {
                icon: cmsBlockIcon,
                header: 'Predefiniowane bloki CMS',
                link: '/predefiniowane-bloki-cms'
            },
            {
                icon: editorIcon,
                header: 'Błyskawiczna i masowa edycja',
                link: '/blyskawiczna-edycja'
            }
        ]
    },
    {
        icon: deliveryIcon,
        header: 'Zarządzanie zamówieniami',
        submodules: [
            {
                icon: deliveryIcon,
                header: 'Twoje zamówienia',
                link: '/twoje-zamowienia'
            },
        ]
    },
    {
        icon: posIcon,
        header: 'Punkt kasowy POS',
        submodules: [

        ]
    },
    {
        icon: shippingIcon,
        header: 'Punkt stanowiska pakowania',
        link: '/punkt-stanowiska-pakowania'
    }
];

const error = 'Coś poszło nie tak... Prosimy spróbować później lub skontaktować się z administratorem systemu.';

const addNewPackerErrors = [
  'Wpisz nick pakowacza',
  'Pakowacz o podanym nicku już istnieje',
  'Coś poszło nie tak... Prosimy spróbować później'
]

const contextNames = [
  'idosell_pl', 'allegro', 'universal'
]

const emptyArrayReponse = {
    data: []
};

const ALLEGRO_CLIENT_ID = '3031291239914e568f09a8ad4061638d';
const ALLEGRO_REDIRECT_URI = 'https://backend.extendommerce.com/allegro/getToken';

export { modules, error, addNewPackerErrors, contextNames, emptyArrayReponse, ALLEGRO_CLIENT_ID, ALLEGRO_REDIRECT_URI }
