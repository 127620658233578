import React from 'react';
import { getDate } from '../helpers/others';
import videoIcon from '../static/img/video-camera.svg';

const TableCellCMSBlocks = ({item}) => {
  return <span className="productItem__col">
      <span className="key">
          CMS
      </span>
      <span className="value flex">
          {item.b_id ? <span className="value__blockName">
              {item.b_name}
          </span> : <span className="value__blockName value__blockName--red">
              Brak
          </span>}
        <a href={`/edytor-bloku-cms?product=${item.p_id}`}
           className="value__btn">
              {item.b_id ? 'Edytuj' : 'Dodaj'}
          </a>

        {item.b_id ? <>
              <span className="key key--second">
              Ostatnio edytowany
          </span>
          <span className="value__blockName value__blockName--second">
              {getDate(item.b_last_edit_datetime)}
          </span>
        </> : ''}

        <a href={`/video-edytor?product=${item.p_id}`}
           className="value__btn value__btn--video">
              <img className="img" src={videoIcon} alt="video" />
              Video
          </a>
      </span>
  </span>
};

export default TableCellCMSBlocks;
