import React, { useContext } from 'react';
import { MassEditionConfigurationContext } from './MassEditionConfiguration';

const ParamsToEditItem = ({index, label}) => {
  const { paramsToEdit, handleParamsToEditChange } = useContext(MassEditionConfigurationContext);

  return <label className="modal__option">
    <button className={paramsToEdit.includes(index) ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
            onClick={() => { handleParamsToEditChange(index); }}>

    </button>
    <span className="modal__option__desc">
      {label}
    </span>
  </label>
}

export default ParamsToEditItem;
