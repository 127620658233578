import React from 'react';

const ButtonBlueBorder = ({children, onClick}) => {
  return <button className="btn btn--copyContext"
                 onClick={onClick}>
    {children}
  </button>
};

export default ButtonBlueBorder;
