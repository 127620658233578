import React from 'react';
import ClearRangeCheckbox from './ClearRangeCheckbox';

const ArrayFilter = ({label, placeholder, newValue, setNewValue, addNewValueToFilter,
                       deleteValueFromFilter, filterList, clear, setClear}) => {
  const handleChange = (e) => {
    setNewValue(e.target.value);
  }

  const handleEnterSubmit = (e) => {
    if(e.key === 'Enter') {
      addNewValueToFilter();
    }
  }

  return <div className="shopItem__filterWrapper shopItem__filterWrapper--array">
    <label className="shopItem__filterWrapper--flex">
      <span className="key">
          {label}
      </span>
      <input className="input input--filter input--filter--standard"
             placeholder={placeholder}
             value={newValue}
             onKeyDown={handleEnterSubmit}
             onChange={handleChange} />
      <button className="btn btn--addToFilter"
              onClick={addNewValueToFilter}>
        +
      </button>

      <span className="shopItem__filterWrapper__array">
          {filterList.map((item, index) => {
            return <span className="shopItem__filterWrapper__array__item"
                         key={index}>
                  {item}
              <button className="btn--removeItemFromArrayFilter"
                      onClick={() => { deleteValueFromFilter(index); }}>
                      &times;
                  </button>
              </span>
          })}
      </span>
    </label>

    <ClearRangeCheckbox clear={clear}
                        setClear={setClear} />
  </div>
}

export default ArrayFilter;
