import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Loader from './Loader';
import CloseModalButton from './CloseModalButton';

const SynchronizationOptions = ({closeModal, loading, syncCMS, setSyncCMS, syncParams, setSyncParams,
                                    syncCodes, setSyncCodes, syncImages, setSyncImages,
                                    syncAllegro, setSyncAllegro, syncOption, setSyncOption,
                                    syncProductsList, setSyncProductsList, action}) => {
    const products = useSelector((state) => (state.products.allProducts));

    const [productsNames, setProductsNames] = useState([]);

    useEffect(() => {
        if(products?.length) {
            setProductsNames(products.map((item) => ({
                label: `${item.p_productName} (${item.p_id?.toString()})`,
                value: item.p_id?.toString()
            })));
        }
    }, [products]);

    return <div className="modal modal--synchronizationOptions"
                onClick={closeModal}>
        <div className="modal__inner"
             onClick={(e) => { e.stopPropagation(); }}>
            <CloseModalButton onClick={closeModal} />

            {loading ? <Loader /> : <>
                <div className="modal__left">
                    <h4 className="modal__part__header">
                        Wybierz dane, które aktualizować
                    </h4>
                    <label className="modal__option">
                        <button className={syncCMS ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncCMS(p => !p); }}>

                        </button>
                        <span className="modal__option__desc">
                        Opis rozbudowany
                    </span>
                        <span className="modal__option__details">
                         Generuj kod HTML dla IdoSell na podstawie edytora CMS
                    </span>
                    </label>
                    <label className="modal__option">
                        <button className={syncParams ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncParams(p => !p); }}>

                        </button>
                        <span className="modal__option__desc">
                        Parametry towarów
                    </span>
                    </label>
                    <label className="modal__option">
                        <button className={syncCodes ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncCodes(p => !p); }}>

                        </button>
                        <span className="modal__option__desc">
                        Kody kreskowe producenta
                    </span>
                    </label>
                    <label className="modal__option">
                        <button className={syncImages ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncImages(p => !p); }}>

                        </button>
                        <span className="modal__option__desc">
                        Zdjęcia
                    </span>
                        <span className="modal__option__details">
                         Obrazy do towaru
                    </span>
                    </label>
                    <label className="modal__option">
                        <button className={syncAllegro ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncAllegro(p => !p); }}>

                        </button>
                        <span className="modal__option__desc">
                        Opis dla aukcji Allegro
                    </span>
                        <span className="modal__option__details">
                         Generuj kod na podstawie edytora CMS
                    </span>
                    </label>
                </div>

                <div className="modal__right">
                    <h4 className="modal__part__header">
                        Wybierz towary, które aktualizować
                    </h4>
                    <label className="modal__option">
                        <button className={syncOption === 0 ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncOption(0); }}>

                        </button>
                        <span className="modal__option__desc">
                        Tylko te towary, które zostały zedytowane po stronie Extendommerce od czasu ostatniej synchronizacji
                    </span>
                    </label>
                    <label className="modal__option">
                        <button className={syncOption === 1 ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncOption(1); }}>

                        </button>
                        <span className="modal__option__desc">
                        Wszystkie towary z bazy
                    </span>
                        <span className="modal__option__details">
                        Zaznacz w przypadku, gdy istnieje ryzyko nadpisania wielu towarów z poziomu panelu IdoSell
                    </span>
                    </label>
                    <label className="modal__option">
                        <button className={syncOption === 2 ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
                                onClick={() => { setSyncOption(2); }}>

                        </button>
                        <span className="modal__option__desc">
                        Wskazane towary
                    </span>
                    </label>

                    {syncOption === 2 ? <div className="modal__productFinder">
                        <Select defaultValue={[]}
                                options={productsNames}
                                placeholder="Wybierz produkty"
                                value={syncProductsList}
                                onChange={setSyncProductsList}
                                isMulti />
                    </div> : ''}
                </div>

                <button className="btn btn--submit"
                        onClick={action}>
                    Aktualizuj dane
                </button>
            </>}
        </div>
    </div>
};

export default SynchronizationOptions;
