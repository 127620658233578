import React from 'react';

const AddAndSubtractFrameButtons = ({mainVideo, addMs, subtractMs}) => {
  return <div className={!mainVideo ? "opacity-0 h-0" : "editor__secondRow__buttons"}>
    <button className="currentTimeInput__btn btn--positive" onClick={addMs}>
      + 100 ms
    </button>
    <button className="currentTimeInput__btn btn--negative" onClick={subtractMs}>
      - 100 ms
    </button>
  </div>
};

export default AddAndSubtractFrameButtons;
