import React from 'react';
import { Oval } from 'react-loader-spinner';

const OvalLoader = () => {
  return <Oval height={15}
               width={15}
               color="#4fa94d"
               ariaLabel='oval-loading'
               secondaryColor="#4fa94d"
               strokeWidth={2}
               strokeWidthSecondary={2} />
}

export default OvalLoader;
