import React, { useEffect, useState } from 'react';
import LoggedUserHeader from '../components/LoggedUserHeader';
import BackButton from '../components/BackButton';
import AddPacker from '../components/AddPacker';
import AssignPackerToOrder from '../components/AssignPackerToOrder';
import PackagesFilter from '../components/PackagesFilter';
import { getPackersByTeam } from '../helpers/orders';
import withAccessRequired from '../hoc/withAccessRequired';
import PackagesPointHeader from '../components/PackagesPointHeader';
import { useSelector } from 'react-redux';

const PackagesPoint = () => {
  const teamId = useSelector((state) => (state.user.data.u_team_id));

  const [packers, setPackers] = useState([]);
  const [newPacker, setNewPacker] = useState('');
  const [currentPacker, setCurrentPacker] = useState(null);
  const [currentPackerId, setCurrentPackerId] = useState(0);
  const [addPackerStatus, setAddPackerStatus] = useState(0);
  const [sectionVisible, setSectionVisible] = useState(0);

  useEffect(() => {
    if((teamId && !packers?.length) || addPackerStatus === 1) {
      getPackersByTeam(teamId)
        .then((res) => {
          if(res?.status === 200) {
             setPackers(res?.data?.map((item) => {
               return {
                 label: item.nick_name,
                 value: item.id
               }
             }));
          }
        });
    }
  }, [teamId, addPackerStatus]);

  useEffect(() => {
    if(currentPacker?.value) {
      setCurrentPackerId(currentPacker.value);
    }
  }, [currentPacker]);

  return <div className="container container--shopList container--cronList">
    <LoggedUserHeader />
    <BackButton />

    <main className="main w">
      <PackagesPointHeader sectionVisible={sectionVisible}
                           setSectionVisible={setSectionVisible} />

      {sectionVisible === 0 ? <div className="packing__sectionWrapper">
        <AddPacker newPacker={newPacker}
                   setNewPacker={setNewPacker}
                   currentPackerId={currentPackerId}
                   currentPacker={currentPacker}
                   setCurrentPacker={setCurrentPacker}
                   addPackerStatus={addPackerStatus}
                   setAddPackerStatus={setAddPackerStatus}
                   packers={packers} />

        <AssignPackerToOrder currentPackerId={currentPackerId} />
      </div> : <PackagesFilter currentPackerId={currentPackerId} />}
    </main>
  </div>
}

export default withAccessRequired(PackagesPoint, 'packer_assignment_manager');
