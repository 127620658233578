import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiQueueSlice } from '../reducers/apiQueueSlice';
import notificationSound from '../static/sounds/positiveNotification.wav'
import errorSound from '../static/sounds/negativeNotification.wav'
import useSound from 'use-sound'
import ApiQueueView from './ApiQueueView';
import startQueue from '../helpers/startQueue';
import { getDateTime } from '../helpers/others';
import ErrorTextInfo from './ErrorTextInfo';

const AssignPackerToOrder = ({currentPackerId}) => {
  const [blockPossibilityToAssignAnotherPacker, setBlockPossibilityToAssignAnotherPacker] = useState(true);
  const [shippingNumber, setShippingNumber] = useState('');
  const [error, setError] = useState('');

  const [playNotificationSound] = useSound(notificationSound);
  const [playErrorSound] = useSound(errorSound);

  const apiQueue = useSelector((state) => (state.apiQueue.apiQueue));
  const dispatch = useDispatch();

  const validateShippingNumber = (e) => {
    e.preventDefault();

    if(!shippingNumber) {
      playErrorSound();
      setError('Wpisz numer paczki');
    }
    else if(!currentPackerId) {
      playErrorSound();
      setError('Wybierz pakowacza');
    }
    else {
      assignNewPackerToOrderByShippingNumber();
    }
  }

  const assignNewPackerToOrderByShippingNumber = () => {
    dispatch(apiQueueSlice.actions.add({
      url: 'external-system-orders/assignPackerToOrderByShippingNumber',
      payload: {
        packerId: currentPackerId,
        datetime: getDateTime(new Date()),
        shippingNumber,
        blockPossibilityToAssignAnotherPacker
      },
      status: 'request',
      errorValue: null
    }));

    setBlockPossibilityToAssignAnotherPacker(true);
    setShippingNumber('');

    playNotificationSound();
    setError('');
  }

  useEffect(() => {
    startQueue(apiQueue, dispatch);
  }, [apiQueue]);

  const submitOnEnter = (e) => {
    if(e.key === 'Enter') {
      validateShippingNumber(e);
    }
  }

  const handleShippingNumber = (e) => {
    setShippingNumber(e.target.value);
  }

  return <div className="packing__section packing__section--assignPackerToOrder">
    <h4 className="packing__header">
      Dodaj paczki
    </h4>

    <label className="modal__option">
      <button className={blockPossibilityToAssignAnotherPacker ? "modal__option__btn modal__option__btn--selected" : "modal__option__btn"}
              onClick={() => { setBlockPossibilityToAssignAnotherPacker(p => !p); }}>

      </button>
      <span className="modal__option__desc">
        Blokuj możliwość przypisania się drugiej osoby jako pakującej poniższą paczkę
      </span>
      <span className="modal__option__details">
        Ta opcja powyżej jest domyślnie zaznaczona i zaznacza się ponownie po każdym kolejnym skanowaniu.
        Jeśli pakujesz paczkę wspólnie z jakimś wujem - świadomie odznacz powyższy checkbox, bo tylko wtedy on
        będzie w stanie przypisać się do tej paczki!
      </span>
    </label>

    <form className="packing__form"
          onSubmit={validateShippingNumber}>
      <label>
        <input className="input input--shippingNumber"
               value={shippingNumber}
               onKeyDown={submitOnEnter}
               onChange={handleShippingNumber}
               placeholder="Wpisz numer paczki" />
      </label>
      <button type="submit"
              className="btn btn--scanShippingNumber">
        Przypisz mnie jako pakowacza
      </button>

      <ErrorTextInfo content={error} />
    </form>

    <ApiQueueView columnToExtractFromPayload="shippingNumber" />
  </div>
};

export default AssignPackerToOrder;
