import React, {useState} from 'react';
import useCloseOnEscapeClick from '../hooks/useCloseOnEscapeClick';

const ListModal = ({list, chooseItem, closeModalFunction, header, buttonLabel}) => {
    const [currentItem, setCurrentItem] = useState(-1);

    useCloseOnEscapeClick(closeModalFunction);

    return <div className="colorModal">
        <div className="colorModal__inner">
            <button className="colorModal__close" onClick={() => { closeModalFunction(); }}>
                &times;
            </button>
            <h3 className="colorModal__header">
                {!header ? 'Wybierz blok predefiniowany' : header}
            </h3>

            <div className="modal__list">
                {list?.map((item, index) => {
                    return <button className={index === currentItem ? "modal__listItem modal__listItem--selected" : "modal__listItem"}
                                   key={index}
                                   onClick={() => { setCurrentItem(index); }}>
                        {item.name}
                    </button>
                })}
            </div>

            <button className="btn btn--changeColor" onClick={() => { chooseItem(list[currentItem]); }}>
                {buttonLabel ? buttonLabel : 'Dodaj blok'}
            </button>
        </div>
    </div>
};

export default ListModal;
