import React from 'react';

const ClearRangeCheckbox = ({clear, setClear}) => {
  const toggleClear = () => {
    setClear(p => !p);
  }

  return <label className="label--checkbox label--checkbox--empty">
    <button onClick={toggleClear}
            className="btn--checkbox">
      {clear ? <span className="btn--checkbox__selected"></span> : ''}
    </button>
    Brak
  </label>
};

export default ClearRangeCheckbox;
