import React from 'react';
import arrowUpIcon from '../static/img/arrow-up.svg';

const ChangeSectionsOrder = ({moveBlockUp, moveBlockDown, index}) => {
  return <div className="blockEditor__section__header__type">
    <h4>
      Zmień kolejność sekcji:
    </h4>
    <button className="blockEditor__section__header__arrowBtn"
            onClick={() => { moveBlockUp(index); }}>
      <img className="img" src={arrowUpIcon} alt="wyzej" />
    </button>
    <button className="blockEditor__section__header__arrowBtn"
            onClick={() => { moveBlockDown(index); }}>
      <img className="img" src={arrowUpIcon} alt="wyzej" />
    </button>
  </div>
};

export default ChangeSectionsOrder;
