import React from 'react';

const InputMassEditionForm = ({label, skipToNextForm, value, setValue, tabIndex}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const handleKeyDown = (e) => {
    skipToNextForm(e, false, true);
  }

  return <div className="massEdition__section">
      <label>
        {label}:
        <input className="input input--massEdition"
               value={value}
               tabIndex={tabIndex}
               onKeyDown={handleKeyDown}
               onChange={handleChange}
               type="number"
               min={0} />
      </label>
      <button className="btn btn--massEditionConfirm"
              onClick={(e) => { skipToNextForm(e, true) }}>
        Zatwierdź
      </button>
    </div>
}

export default InputMassEditionForm;
