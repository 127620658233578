function displayVideoTimer(time) {
  if(time) {
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    let milliseconds = parseInt(seconds.toString().split('.')[1].substring(0, 3));

    if(minutes.toString().length === 1) {
      minutes = "0" + minutes;
    }

    if(Math.floor(seconds).toString().length === 1) {
      seconds = "0" + Math.floor(seconds);
    }
    else {
      seconds = Math.floor(seconds);
    }

    if(milliseconds.toString().length === 2) {
      milliseconds = "0" + milliseconds;
    }
    else if(milliseconds.toString().length === 1) {
      milliseconds = "00" + milliseconds;
    }

    return minutes + ':' + seconds + ":" + milliseconds;
  }
  else {
    return '';
  }
}

export default displayVideoTimer;
