import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  apiQueue: []
}

const apiQueueSlice = createSlice({
  name: 'apiQueue',
  initialState,
  reducers: {
    add: (state, action) => {
      console.log('add');

      state.apiQueue = [...state.apiQueue, {
        id: state.apiQueue.length ? state.apiQueue[state.apiQueue.length-1].id+1 : 1,
        ...action.payload
      }]
    },
    update: (state, action) => {
      state.apiQueue = state.apiQueue.map((item) => {
        if(item.id === action.payload.id) {
          return {
            ...item,
            status: action.payload.status,
            errorValue: action.payload.errorValue
          }
        }
        else {
          return item;
        }
      })
    },
    delete: (state, action) => {
      state.apiQueue = state.apiQueue.filter((item) => (item.id !== action.payload.id))
    }
  }
});

export default apiQueueSlice.reducer
export { apiQueueSlice }
