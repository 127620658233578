import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import './static/style/style.css'
import './static/style/mobile.css'
import 'react-tippy/dist/tippy.css'
import "react-datepicker/dist/react-datepicker.css";
import UserWrapper from "./components/UserWrapper";

function App() {
  return <Router>
    <Route exact path="/">
      <LoginPage />
    </Route>
    <Route path="/home">
      <UserWrapper page={1} />
    </Route>
    <Route path="/twoje-sklepy">
      <UserWrapper page={2} />
    </Route>
    <Route path="/twoje-produkty">
      <UserWrapper page={3} />
    </Route>
    <Route path="/edytor-karty-towaru">
      <UserWrapper page={4} />
    </Route>
    <Route path="/moje-operacje-cron">
      <UserWrapper page={5} />
    </Route>
    <Route path="/zmien-haslo">
      <UserWrapper page={6} />
    </Route>
    <Route path="/predefiniowane-bloki-cms">
      <UserWrapper page={7} />
    </Route>
    <Route path="/edytor-bloku-cms">
      <UserWrapper page={8} />
    </Route>
    <Route path="/podglad-bloku-cms">
      <UserWrapper page={9} />
    </Route>
    <Route path="/punkt-stanowiska-pakowania">
      <UserWrapper page={10} />
    </Route>
    <Route path="/blyskawiczna-edycja">
      <UserWrapper page={11} />
    </Route>
    <Route path="/video-edytor">
      <UserWrapper page={12} />
    </Route>
    <Route path="/twoje-zamowienia">
      <UserWrapper page={13} />
    </Route>
    <Route path="/konta-allegro">
      <UserWrapper page={14} />
    </Route>
    <Route path="/allegro-info">
      <UserWrapper page={15} />
    </Route>
  </Router>
}

export default App;
