import React, {useEffect, useState} from 'react';
import LoggedUserHeader from "../components/LoggedUserHeader";
import BackButton from "../components/BackButton";
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../components/Loader';
import { registerLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
import TableOrdersRow from '../components/TableOrdersRow';
import useFilterOrders from '../hooks/useFilterOrders';
import useClearString from '../hooks/useClearString';
import useClearArray from '../hooks/useClearArray';
import useClearDateRange from '../hooks/useClearDateRange';
import DateRangeFilter from '../components/DateRangeFilter';
import NumberRangeFilter from '../components/NumberRangeFilter';
import InputFilter from '../components/InputFilter';
import ArrayFilter from '../components/ArrayFilter';
import OrderStatusFilter from '../components/OrderStatusFilter';
registerLocale('pl', pl)

const orderStatuses = ['finished_ext', 'ready', 'packed', 'on_order', 'finished'].map((item) => ({
    label: item,
    value: item
}));

const UserOrders = () => {
    const ordersSelector = useSelector((state) => (state.orders.allOrders));
    const products = useSelector((state) => (state.products.allProducts));

    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filteredOrdersToRender, setFilteredOrdersToRender] = useState([]);
    const [page, setPage] = useState(1);
    const [idFilterValue, setIdFilterValue] = useState('');
    const [shopFilterValue, setShopFilterValue] = useState('');
    const [clearShop, setClearShop] = useState(false);
    const [clearId, setClearId] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(true)
    const [statusFilterValue, setStatusFilterValue] = useState(null);
    const [clearStatus, setClearStatus] = useState(false);
    const [productsFilterValue, setProductsFilterValue] = useState([]);
    const [newProductFilterValue, setNewProductFilterValue] = useState('');
    const [clearProduct, setClearProduct] = useState(false);
    const [packersFilterValue, setPackersFilterValue] = useState([]);
    const [packagesFilterValue, setPackagesFilterValue] = useState([]);
    const [newPackerFilterValue, setNewPackerFilterValue] = useState('');
    const [newPackageFilterValue, setNewPackageFilterValue] = useState('');
    const [clearPacker, setClearPacker] = useState(false);
    const [clearPackage, setClearPackage] = useState(false);
    const [receivedDateStart, setReceivedDateStart] = useState(null);
    const [receivedDateEnd, setReceivedDateEnd] = useState(null);
    const [packingDateStart, setPackingDateStart] = useState(null);
    const [packingDateEnd, setPackingDateEnd] = useState(null);
    const [orderValueStart, setOrderValueStart] = useState(0);
    const [orderValueEnd, setOrderValueEnd] = useState(999999);
    const [orderWeightStart, setOrderWeightStart] = useState(0);
    const [orderWeightEnd, setOrderWeightEnd] = useState(999999);
    const [orderVolumeStart, setOrderVolumeStart] = useState(0);
    const [orderVolumeEnd, setOrderVolumeEnd] = useState(999999);
    const [clearReceivedDate, setClearReceivedDate] = useState(false);
    const [clearPackingDate, setClearPackingDate] = useState(false);
    const [clearOrderValue, setClearOrderValue] = useState(false);
    const [clearOrderWeight, setClearOrderWeight] = useState(false);
    const [clearOrderVolume, setClearOrderVolume] = useState(false);

    const filters = { idFilterValue, shopFilterValue,
        packersFilterValue, packagesFilterValue, receivedDateStart, receivedDateEnd,
        packingDateStart, packingDateEnd, orderValueStart, orderValueEnd,
        orderWeightStart, orderWeightEnd, orderVolumeStart, orderVolumeEnd,
        statusFilterValue, productsFilterValue, clearShop };

    useFilterOrders(orders, setFilteredOrders, filters);

    useEffect(() => {
        if(ordersSelector?.length && products?.length) {
            setOrders(ordersSelector?.map((item) => {
                const orderProducts = item.products;
                let orderWeight = 0;
                let orderVolume = 0;

                for(const product of orderProducts) {
                    const productObject = products.find((item) => {
                        return item.p_external_id === parseInt(product.productId);
                    });

                    orderWeight += productObject.p_product_weight_g ? productObject.p_product_weight_g : 0;
                    let productVolume = (productObject.p_product_height * productObject.p_product_width_mm * productObject.p_product_length_mm) / 1000;
                    orderVolume += productVolume;
                }

                return {
                    ...item,
                    orderWeight,
                    orderVolume
                }
            }));
        }
    }, [ordersSelector, products]);

    useEffect(() => {
        setFilteredOrders(orders);
    }, [orders]);

    useEffect(() => {
        if(filteredOrders?.length) {
            setFilteredOrdersToRender(prevState => {
                return [...prevState, ...filteredOrders.slice((page-1) * 12, page * 12)]
            });
            setLoadingOrders(false);
        }
    }, [page, filteredOrders]);

    useEffect(() => {
        setPage(1);
        setFilteredOrdersToRender([]);
    }, Object.values(filters));

    useClearString(clearShop, setShopFilterValue);
    useClearString(clearId, setIdFilterValue);
    useClearArray(clearProduct, setProductsFilterValue);
    useClearArray(clearPacker, setPackersFilterValue);
    useClearArray(clearPackage, setPackagesFilterValue);
    useClearDateRange(clearReceivedDate, setReceivedDateStart, setReceivedDateEnd);
    useClearDateRange(clearPackingDate, setPackingDateStart, setPackingDateEnd);
    useClearDateRange(clearOrderValue, setOrderValueStart, setOrderValueEnd);

    const addNewValueToProductFilter = () => {
        if(newProductFilterValue !== '') {
            setProductsFilterValue(prevState => ([...prevState, newProductFilterValue]));
            setNewProductFilterValue('');
        }
    }

    const addNewValueToPackerFilter = () => {
        if(newPackerFilterValue !== '') {
            setPackersFilterValue(prevState => ([...prevState, newPackerFilterValue]));
            setNewPackerFilterValue('');
        }
    }

    const addNewValueToPackageFilter = () => {
        if(newPackageFilterValue !== '') {
            setPackagesFilterValue(prevState => ([...prevState, newPackageFilterValue]));
            setNewPackageFilterValue('');
        }
    }

    const deleteValueFromProductFilter = (i) => {
        setProductsFilterValue(prevState => (prevState.filter((_, index) => (index !== i))));
    }

    const deleteValueFromPackersFilter = (i) => {
        setPackersFilterValue(prevState => (prevState.filter((_, index) => (index !== i))));
    }

    const deleteValueFromPackagesFilter = (i) => {
        setPackagesFilterValue(prevState => (prevState.filter((_, index) => (index !== i))));
    }

    const nextPage = () => {
        setPage(prevState => (prevState+1));
    }

    return <div className="container container--shopList">
        <LoggedUserHeader />
        <BackButton module={2} />

        <main className="main w">
            <h1 className="main__header">
                Lista Twoich zamówień
            </h1>

            <div className="columnsFilterSection">
                <h4 className="columnsFilterSection__header">
                    Filtry:
                </h4>
                <div className="shopItem shopItem--product shopItem--product--filters shopItem--order--filters flex">
                    <InputFilter label={'Sklep'}
                                 placeholder={'Nazwa sklepu'}
                                 type={'text'}
                                 value={shopFilterValue}
                                 setValue={setShopFilterValue}
                                 clear={clearShop}
                                 setClear={setClearShop} />
                    <InputFilter label={'Nr zamówienia z zewn. systemu'}
                                 placeholder={'Nr zamówienia z zewn. systemu'}
                                 type={'number'}
                                 value={idFilterValue}
                                 setValue={setIdFilterValue}
                                 clear={clearId}
                                 setClear={setClearId} />

                    <OrderStatusFilter label={'Status zamówienia'}
                                       statuses={orderStatuses}
                                       value={statusFilterValue}
                                       setValue={setStatusFilterValue}
                                       clear={clearStatus}
                                       setClear={setClearStatus} />

                    <ArrayFilter label={'Towary'}
                                 placeholder={'Przypisane towary'}
                                 newValue={newProductFilterValue}
                                 setNewValue={setNewProductFilterValue}
                                 addNewValueToFilter={addNewValueToProductFilter}
                                 deleteValueFromFilter={deleteValueFromProductFilter}
                                 filterList={productsFilterValue}
                                 clear={clearProduct}
                                 setClear={setClearProduct} />
                    <ArrayFilter label={'Pakowacze'}
                                 placeholder={'Przypisani pakowacze'}
                                 newValue={newPackageFilterValue}
                                 setNewValue={setNewPackageFilterValue}
                                 addNewValueToFilter={addNewValueToPackerFilter}
                                 deleteValueFromFilter={deleteValueFromPackersFilter}
                                 filterList={packersFilterValue}
                                 clear={clearPacker}
                                 setClear={setClearPacker} />
                    <ArrayFilter label={'Paczki'}
                                 placeholder={'Przypisane paczki'}
                                 newValue={newPackageFilterValue}
                                 setNewValue={setNewPackageFilterValue}
                                 addNewValueToFilter={addNewValueToPackageFilter}
                                 deleteValueFromFilter={deleteValueFromPackagesFilter}
                                 filterList={packagesFilterValue}
                                 clear={clearPackage}
                                 setClear={setClearPackage} />

                    <DateRangeFilter label={'Data zł. zamówienia'}
                                     start={receivedDateStart}
                                     setStart={setReceivedDateStart}
                                     end={receivedDateEnd}
                                     setEnd={setReceivedDateEnd}
                                     clear={clearReceivedDate}
                                     setClear={setClearReceivedDate} />
                    <DateRangeFilter label={'Data spakowania'}
                                     start={packingDateStart}
                                     setStart={setPackingDateStart}
                                     end={packingDateEnd}
                                     setEnd={setPackingDateEnd}
                                     clear={clearPackingDate}
                                     setClear={setClearPackingDate} />

                    <NumberRangeFilter label={'Wartość zamówienia'}
                                       start={orderValueStart}
                                       setStart={setOrderValueStart}
                                       end={orderValueEnd}
                                       setEnd={setOrderValueEnd}
                                       clear={clearOrderValue}
                                       setClear={setClearOrderValue} />
                    <NumberRangeFilter label={'Waga zamówienia'}
                                       start={orderWeightStart}
                                       setStart={setOrderWeightStart}
                                       end={orderWeightEnd}
                                       setEnd={setOrderWeightEnd}
                                       clear={clearOrderWeight}
                                       setClear={setClearOrderWeight} />
                    <NumberRangeFilter label={'Objętość zamówienia'}
                                       start={orderVolumeStart}
                                       setStart={setOrderVolumeStart}
                                       end={orderVolumeEnd}
                                       setEnd={setOrderVolumeEnd}
                                       clear={clearOrderVolume}
                                       setClear={setClearOrderVolume} />

                    <label className="shopItem__filterWrapper shopItem__filterWrapper--lastRow">
                        <span className="shopItem__numberOfProducts">
                            Znaleziono zamówień <span>{filteredOrders?.length}</span>
                        </span>
                    </label>
                </div>
            </div>

            <InfiniteScroll dataLength={page * 12}
                            next={nextPage}
                            height={600}
                            className="customScroll"
                            hasMore={filteredOrders.length > filteredOrdersToRender.length}
                            loader={<span>
                                <Loader />
                            </span>}
                            endMessage={<span></span>}>
                {!loadingOrders ? filteredOrdersToRender?.map((item, index) => {
                    return <TableOrdersRow item={item}
                                           key={index} />
                }) : <Loader />}
            </InfiniteScroll>
        </main>
    </div>
}

export default UserOrders;
