import { useEffect, useState } from 'react';
import { getShopsByUser } from '../helpers/shop';

const useUserShops = () => {
  const [shops, setShops] = useState([]);
  const [shopChoices, setShopChoices] = useState([]);

  useEffect(() => {
    getShopsByUser()
      .then((res) => {
        if(res?.status === 200) {
          setShops(res?.data);
        }
      });
  }, []);

  useEffect(() => {
    if(shops?.length) {
      setShopChoices(shops.map((item, index) => ({
        label: item.name,
        value: index
      })));
    }
  }, [shops]);

  return { shops, shopChoices }
}

export default useUserShops;
