import React from 'react';
import errorIcon from '../static/img/x-button.svg'
import CloseModalButton from './CloseModalButton';

const ErrorModal = ({closeModal, text}) => {
    return <div className="modal modal--shopPicker"
                onClick={closeModal}>
        <div className="modal__inner"
             onClick={(e) => { e.stopPropagation(); }}>
            <CloseModalButton onClick={closeModal} />

            <img className="modalImg" src={errorIcon} alt="error" />

            <h4 className="modal__subheader">
                {text}
            </h4>
        </div>
    </div>
};

export default ErrorModal;
