import React from 'react';
import ParamsToEditItem from './ParamsToEditItem';

const MassEditionConfigurationContext = React.createContext(null);

const MassEditionConfiguration = ({paramsToEdit, paramsLabels, setParamsToEdit, setSectionVisible}) => {
  const handleParamsToEditChange = (n) => {
    if(paramsToEdit.includes(n)) {
      setParamsToEdit(prevState => (prevState.filter((item) => (item !== n))));
    }
    else {
      setParamsToEdit(prevState => ([...prevState, n]));
    }
  }

  return <div className="packing__section">
    <p className="massEdition__text">
      Wybierz dane towarów, które chcesz w następnym kroku masowo edytować:
    </p>

    <MassEditionConfigurationContext.Provider value={{
      paramsToEdit, handleParamsToEditChange
    }}>
      <>
        {paramsLabels.map((item, index) => {
          return <ParamsToEditItem label={paramsLabels[index].label}
                                   index={index} />
        })}
      </>
    </MassEditionConfigurationContext.Provider>

    <button className="btn btn--massEdition"
            onClick={() => { setSectionVisible(1); }}>
      Przejdź do masowej edycji
    </button>
  </div>
};

export default MassEditionConfiguration;
export { MassEditionConfigurationContext };
