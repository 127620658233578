import { useEffect } from 'react';

const useFilterProducts = (products, setFilteredProducts, filters, predefinedBlocks) => {
  const getPredefinedBlockById = (id) => {
    return predefinedBlocks.find((item) => (item.id === id))?.name;
  }

  useEffect(() => {
    let newFilteredProducts = products;

    const { idFilterValue, shopFilterValue, nameFilterValue, codeFilterValue,
      producerFilterValue, categoriesFilterValue, blocksFilterValue,
      clearShop, clearName, clearCode, clearProducer, clearCategory, clearBlock } = filters;

    if(idFilterValue) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return parseInt(item.p_external_id) === parseInt(idFilterValue);
      });
    }
    if(shopFilterValue) {
      const shopFilterParts = shopFilterValue.toLowerCase().split(' ');

      newFilteredProducts = newFilteredProducts.filter((item) => {
        const shopNameParts = item.w_name.toLowerCase().split(' ');

        // Filter products with match for every part (not necessary in order)
        return shopFilterParts.findIndex((item) => {
          const filterPart = item;
          return shopNameParts.findIndex((item) => {
            return item.includes(filterPart);
          }) === -1;
        }) === -1;
      });
    }
    if(nameFilterValue) {
      const nameFilterParts = nameFilterValue.toLowerCase().split(' ');

      newFilteredProducts = newFilteredProducts.filter((item) => {
        const productParts = item.p_productName.toLowerCase().split(' ');

        // Filter products with match for every part (not necessary in order)
        return nameFilterParts.findIndex((item) => {
          const filterPart = item;
          return productParts.findIndex((item) => {
            return item.includes(filterPart);
          }) === -1;
        }) === -1;
      });
    }
    if(codeFilterValue) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.p_productUniversalSizeProducerCode?.toLowerCase()?.includes(codeFilterValue?.toLowerCase());
      });
    }
    if(producerFilterValue) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.p_producerName?.toLowerCase()?.includes(producerFilterValue?.toLowerCase());
      });
    }
    if(categoriesFilterValue.length) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        let categoriesMatched = 0;

        const categoriesNamesParts = item.categories.map((item) => {
          return item.split(' ');
        }); // [['laptop', 'duzy'], ['fajerwerki', 'na', 'sylwestra']]

        for(const category of categoriesFilterValue) {
          const categoryFilterParts = category.toLowerCase().split(' '); // ['duz', 'lap']

          const categoryFound = categoriesNamesParts.findIndex((item) => {
            return item.filter((item) => {
              const categoryNamePart = item;

              return categoryFilterParts.findIndex((item) => {
                return categoryNamePart.toLowerCase().includes(item.toLowerCase());
              }) !== -1;
            }).length === categoryFilterParts.length;
          }) !== -1;

          if(categoryFound) {
            categoriesMatched++;
          }
        }

        return (categoriesMatched === categoriesFilterValue.length) && (categoriesNamesParts.length !== 0);
      });
    }
    if(blocksFilterValue.length) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        let blocksMatched = 0;

        const predefinedBlockNamesParts = item.predefinedBlocks.map((item) => {
          return getPredefinedBlockById(item).split(' ');
        }); // [['blok', '1'], ['blok', '2']]

        for(const block of blocksFilterValue) {
          const blockFilterParts = block.toLowerCase().split(' '); // ['1', 'blo']

          const blockFound = predefinedBlockNamesParts.findIndex((item) => {
            return item.filter((item) => {
              const blockNamePart = item;

              return blockFilterParts.findIndex((item) => {
                return blockNamePart.toLowerCase().includes(item.toLowerCase());
              }) !== -1;
            }).length === blockFilterParts.length;
          }) !== -1;

          if(blockFound) {
            blocksMatched++;
          }
        }

        return (blocksMatched === blocksFilterValue.length) && (predefinedBlockNamesParts.length !== 0);
      });
    }

    if(clearShop) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.w_name === '';
      });
    }
    if(clearName) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.productName === '';
      });
    }
    if(clearCode) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.p_productUniversalSizeProducerCode === '';
      });
    }
    if(clearProducer) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.p_producerName === '';
      });
    }
    if(clearCategory) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.categories?.length === 0;
      });
    }
    if(clearBlock) {
      newFilteredProducts = newFilteredProducts.filter((item) => {
        return item.predefinedBlocks?.length === 0;
      });
    }

    setFilteredProducts(newFilteredProducts);

  }, Object.values(filters));
}

export default useFilterProducts;
