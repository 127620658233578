import React, {useState} from 'react';
import userIcon from '../static/img/logged-user-icon.svg'
import {logoutUser} from "../helpers/user";

const LoggedUserHeader = () => {
    const [userMenuDropdown, setUserMenuDropdown] = useState(false);

    const toggleDropdownMenu = () => {
        setUserMenuDropdown(p => !p);
    }

    return <header className="loggedUserHeader flex">
        <a className="loggedUserHeader__logo"
           href="/">
            Extendommerce
        </a>

        <div className="rel">
            <button className="loggedUserHeader__userBtn"
                    onClick={toggleDropdownMenu}>
                <img className="img" src={userIcon} alt="user" />
            </button>
            {userMenuDropdown ? <div className="loggedUserHeader__dropdownMenu">
                <a className="loggedUserHeader__dropdownMenu__item"
                        href="/zmien-haslo">
                    Zmień hasło
                </a>
                <button className="loggedUserHeader__dropdownMenu__item"
                        onClick={logoutUser}>
                    Wyloguj się
                </button>
            </div> : ''}
        </div>
    </header>
}

export default LoggedUserHeader;
