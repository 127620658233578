import React from 'react';

const ParametersEdition = ({parameters, updateParametersNames, updateParametersValues}) => {
  return <div className="parametersEdition">
    <h4 className="parametersEdition__header">
      Edycja parametrów
    </h4>

    {parameters.map((item, index) => {
      return <div className="parametersEdition__item"
                  key={index}>
        <input className="input input--parameters"
               value={item.name}
               onChange={(e) => { updateParametersNames(index, e.target.value); }} />

        <input className="input input--parameters"
               value={item.value}
               onChange={(e) => { updateParametersValues(index, e.target.value); }} />
      </div>
    })}
  </div>
};

export default ParametersEdition;
