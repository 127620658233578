import React from 'react';
import Select from "react-select";
import Loader from "./Loader";
import CloseModalButton from './CloseModalButton';

const ShopAndAllegroPicker = ({closeModal, shopChoices, currentShop, handleSelect,
                        action, loading, buttonLabel, allegroChoices, currentAllegro, handleSelectAllegro}) => {
    return <div className="modal modal--shopPicker"
                onClick={closeModal}>
        <div className="modal__inner"
             onClick={(e) => { e.stopPropagation(); }}>
            <CloseModalButton onClick={closeModal} />

            {loading ? <Loader /> : <div className="shopEditor">
                {allegroChoices?.length ? <label className="label">
                    Wybierz sklep:
                    <Select options={shopChoices}
                            placeholder="Wybierz sklep"
                            value={shopChoices[currentShop]}
                            onChange={handleSelect}
                            isSearchable={true} />
                </label> : ''}

                {allegroChoices?.length ? <label className="label">
                    Wybierz konto Allegro:
                    <Select options={allegroChoices}
                            placeholder="Wybierz konto allegro"
                            value={allegroChoices[currentAllegro]}
                            onChange={handleSelectAllegro}
                            isSearchable={true} />
                </label> : <h4 className="noAllegroAccountsInfo">
                    Nie masz podłączonego konta Allegro. Przejdź do dodania konta Allegro do
                    swojego konta w Extendommerce <a href="/konta-allegro" className="simpleLink">TUTAJ</a>.
                </h4>}

                {allegroChoices?.length ? <button className="btn btn--submit"
                                                  onClick={action}>
                    {!buttonLabel ? 'Wymuś pobranie najnowszych produktów' : buttonLabel}
                </button> : ''}
            </div>}
        </div>
    </div>
};

export default ShopAndAllegroPicker;
