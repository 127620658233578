import React, { useEffect, useRef, useState } from 'react';
import { isElementInArray } from '../helpers/others';
import Type1Block from './Type1Block';
import iconBold from '../static/img/bold.svg';
import iconItalic from '../static/img/italic.svg';
import iconUnderline from '../static/img/underline.svg';
import iconBulletList from '../static/img/list.svg';
import iconNumberList from '../static/img/numbered-list.svg';
import h1Icon from '../static/img/h1.svg'
import h2Icon from '../static/img/h2.svg'
import Type2Block from './Type2Block';
import Type3Block from './Type3Block';
import Type4Block from './Type4Block';
import Type5Block from './Type5Block';
import Type6Block from './Type6Block';
import Select from 'react-select'
import { useSelector } from 'react-redux';

const textStylingIcons = [iconBold, iconItalic, iconUnderline, iconBulletList, iconNumberList, h1Icon, h2Icon];

const SingleBlockWrapper = ({block, n, changeTexts, changeImages, removeImage, readOnly}) => {
  const [componentToRender, setComponentToRender] = useState(null);
  const [currentStyling, setCurrentStyling] = useState([]);
  const [predefinedBlocks, setPredefinedBlocks] = useState([]);
  const [selectedPredefinedBlock, setSelectedPredefinedBlock] = useState(null);
  const [editorType, setEditorType] = useState(0);

  const allBlocks = useSelector((state) => (state.richBlocks.allBlocks));

  const editorRef = useRef(null);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      detectKeyShortcut(e);
    });
  }, []);

  useEffect(() => {
    if(allBlocks) {
      setPredefinedBlocks(allBlocks.filter((item) => {
        return item.is_predefined;
      }).map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      }));
    }
  }, [allBlocks]);

  useEffect(() => {
    switch(n) {
      case 1:
        setComponentToRender(<Type1Block block={block}
                                         editorType={editorType}
                                         readOnly={readOnly}
                                         changeTexts={changeTexts}
                                         editor={editorRef} />);
        break;
      case 2:
        setComponentToRender(<Type2Block block={block}
                                         readOnly={readOnly}
                                         removeImage={removeImage}
                                         changeImages={changeImages} />);
        break;
      case 3:
        setComponentToRender(<Type3Block block={block}
                                         editorType={editorType}
                                         readOnly={readOnly}
                                         editor={editorRef}
                                         changeTexts={changeTexts}
                                         removeImage={removeImage}
                                         changeImages={changeImages} />);
        break;
      case 4:
        setComponentToRender(<Type4Block block={block}
                                         editorType={editorType}
                                         readOnly={readOnly}
                                         editor={editorRef}
                                         changeTexts={changeTexts}
                                         removeImage={removeImage}
                                         changeImages={changeImages} />);
        break;
      case 5:
        setComponentToRender(<Type5Block block={block}
                                         readOnly={readOnly}
                                         removeImage={removeImage}
                                         changeImages={changeImages} />);
        break;
      case 6:
        setComponentToRender(<Type6Block block={block}
                                         readOnly={readOnly}
                                         changeTexts={changeTexts} />);
        break;
      default:
        break;
    }
  }, [n, block, editorType]);

  const toggleStyling = (type) => {
    setCurrentStyling(prevState => {
      if(isElementInArray(type, prevState) && prevState.length) {
        return prevState.filter((item) => (item !== type));
      }
      else {
        return [...prevState, type];
      }
    });
  }

  const detectKeyShortcut = (e) => {
    if(e.ctrlKey) {
      if(e.keyCode === 66) toggleStyling(1);
      if(e.keyCode === 73) toggleStyling(2);
      if(e.keyCode === 85) toggleStyling(3);
    }
  }

  const chooseStyling = (type) => {
    if(isElementInArray(type, currentStyling)) {
      setCurrentStyling(prevState => (prevState.filter((item) => (item !== type))));
    }
    else {
      if(type === 4) {
        setCurrentStyling(prevState => ([...prevState.filter((item) => (item !== 5)), type]));
      }
      else if(type === 5) {
        setCurrentStyling(prevState => ([...prevState.filter((item) => (item !== 4)), type]));
      }
      else {
        setCurrentStyling(prevState => ([...prevState, type]));
      }
    }

    switch(type) {
      case 1:
        document.execCommand('bold', false, null);
        editorRef?.current?.focus();
        break;
      case 2:
        document.execCommand('italic', false, null);
        editorRef?.current?.focus();
        break;
      case 3:
        document.execCommand('underline', false, null);
        editorRef?.current?.focus();
        break;
      case 4:
        document.execCommand('insertUnorderedList', false, null);
        editorRef?.current?.focus();
        break;
      case 5:
        document.execCommand('insertOrderedList', false, null);
        editorRef?.current?.focus();
        break;
      case 6:
        document.execCommand('formatBlock', false, '<h1>');
        editorRef?.current?.focus();
        break;
      case 7:
        document.execCommand('formatBlock', false, '<h2>');
        editorRef?.current?.focus();
        break;
      default:
        break;
    }
  }

  const injectPredefinedBlock = () => {
    if(selectedPredefinedBlock) {
      changeTexts(0, `[PREDEFINED_BLOCK@${selectedPredefinedBlock.value}]`, true);
    }
  }

  return <>
    <div className="blockEditor__section__textStyling">
      <div className="blockEditor__section__header__type">
        <h4>
          Edycja tekstu:
        </h4>
        {textStylingIcons.map((item, index) => {
          return <button className={isElementInArray(index+1, currentStyling) ? "blockEditor__section__header__type__item blockEditor__section__header__type__item--current" : "blockEditor__section__header__type__item"}
                         key={index}
                         onClick={() => { chooseStyling(index + 1); }}>
            <img className="img" src={item} alt="typ-1" />
          </button>
        })}

        <h4 className="predefinedBlockFilterHeader">
          Wstrzyknij blok predefiniowany
        </h4>
        <div className="blockEditor__section__header__type__selectWrapper">
          <Select defaultValue={[]}
                  options={predefinedBlocks}
                  placeholder="Wybierz blok"
                  value={selectedPredefinedBlock}
                  onKeyDown={(e) => { if(e.key === 'Enter') injectPredefinedBlock(); }}
                  onChange={setSelectedPredefinedBlock} />
        </div>
        <button className="btn btn--injectPredefinedBlock"
                onClick={() => { injectPredefinedBlock(); }}>
          Wstrzyknij
        </button>
      </div>
      <div className="blockEditor__section__editorType">
        <button className={editorType === 0 ? "btn btn--editorType btn--editorType--selected" : "btn btn--editorType"}
                onClick={() => { setEditorType(0); }}>
          Tryb wizualny
        </button>
        <button className={editorType === 1 ? "btn btn--editorType btn--editorType--selected" : "btn btn--editorType"}
                onClick={() => { setEditorType(1); }}>
          Tryb HTML
        </button>
      </div>
    </div>

    {componentToRender}
  </>
};

export default SingleBlockWrapper;
