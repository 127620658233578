import React, { useEffect, useState } from 'react';
import LoggedUserHeader from '../components/LoggedUserHeader';
import BackButton from '../components/BackButton';
import useUrlParam from '../hooks/useUrlParam';
import { error as err } from '../helpers/content';

const AllegroConfirmLoginPage = () => {
  const param = useUrlParam('success');
  const [content, setContent] = useState('');

  useEffect(() => {
    if(param === 'true') {
      setContent('Twoje konto Allegro zostało pomyślnie połączone z Extendommerce');
    }
    else if(param === 'false') {
      setContent(err);
    }
  }, [param]);

  return <div className="container container--shopList">
    <LoggedUserHeader />
    <BackButton module={2} />

    <main className="main w">
      <h2 className="allegroInfoHeader">
        {content}
      </h2>

      <a href="/home" className="btn btn--backHomeAllegro">
        Wróć na stronę główną
      </a>
    </main>
  </div>
};

export default AllegroConfirmLoginPage;
