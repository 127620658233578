import React, {useEffect, useRef, useState} from 'react';
import { addVideo, getVideoHtml, updateVideo } from '../helpers/video';
import Loader from '../components/Loader';
import LoggedUserHeader from '../components/LoggedUserHeader';
import BackButton from '../components/BackButton';
import { useSelector } from 'react-redux';
import { copyToClipboard, getQueryParam, refreshPage, scrollToTop } from '../helpers/others';
import settings from '../static/settings';
import SuccessTextInfoWithBorder from '../components/SuccessTextInfoWithBorder';
import useResizeCanvas from '../hooks/useResizeCanvas';
import { error as err } from '../helpers/content';
import VideoUploader from '../components/VideoUploader';
import ImageUploader from '../components/ImageUploader';
import ChooseFrameHeader from '../components/ChooseFrameHeader';
import AddAndSubtractFrameButtons from '../components/AddAndSubtractFrameButtons';
import displayVideoTimer from '../helpers/displayVideoTimer';

const VideoEditor = () => {
  const [videoId, setVideoId] = useState(null);
  const [mainVideo, setMainVideo] = useState(null);
  const [mainVideoFile, setMainVideoFile] = useState(null);
  const [snippetVideo, setSnippetVideo] = useState(null);
  const [snippetVideoFile, setSnippetVideoFile] = useState(null);
  const [placeholder, setPlaceholder] = useState(null);
  const [placeholderFile, setPlaceholderFile] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentTimeSnippet, setCurrentTimeSnippet] = useState(0);
  const [updateMode, setUpdateMode] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [clearData, setClearData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [generatedHtml, setGeneratedHtml] = useState('');
  const [productId, setProductId] = useState(0);
  const [productName, setProductName] = useState('');
  const [hasVideo, setHasVideo] = useState(false);
  const [mainVideoData, setMainVideoData] = useState({});
  const [snippetVideoData, setSnippetVideoData] = useState({});
  const [placeholderImageData, setPlaceholderImageData] = useState({});
  const [frameType, setFrameType] = useState(0);

  const mainVideoRef = useRef(null);
  const snippetVideoRef = useRef(null);
  const placeholderRef = useRef(null);
  const placeholderInputRef = useRef(null);
  const canvasMain = useRef(null);
  const canvasSnippet = useRef(null);

  const allProducts = useSelector((state) => (state.products.allProducts));
  const teamId = useSelector((state) => (state.user.data.u_team_id));

  useResizeCanvas('canvasMain');
  useResizeCanvas('canvasSnippet');

  useEffect(() => {
    const productParam = getQueryParam('product');

    if(productParam && allProducts) {
      const id = parseInt(getQueryParam('product'));

      const productObject = allProducts.find((item) => {
        return item.p_id === id;
      });

      setProductId(id);
      setProductName(productObject?.p_productName);
      setHasVideo(!!productObject?.p_full_video);

      setMainVideoData(productObject?.p_full_video ? JSON.parse(productObject.p_full_video) : {});
      setSnippetVideoData(productObject?.p_snippet_video ? JSON.parse(productObject.p_snippet_video) : {});
      setPlaceholderImageData(productObject?.p_placeholder_image ? JSON.parse(productObject.p_placeholder_image) : {});
    }
  }, [allProducts]);

  useEffect(() => {
    if(hasVideo && productId) {
      const productObject = allProducts.find((item) => {
        return item.p_id === productId;
      });

      setMainVideo(`${settings.API_URL}/${JSON.parse(productObject.p_full_video)['0']}`);
      if(productObject.p_snippet_video) {
        setSnippetVideo(`${settings.API_URL}/${JSON.parse(productObject.p_snippet_video)['0']}`);
      }
      setPlaceholder(`${settings.API_URL}/${JSON.parse(productObject.p_placeholder_image)['0']}`);

      setUpdateMode(true);
    }
  }, [hasVideo, productId]);

  useEffect(() => {
    if(mainVideoRef) {
      const step = () => {
        if(canvasMain?.current) {
          const ctx = canvasMain.current.getContext("2d");
          ctx.drawImage(mainVideoRef.current, 0, 0, canvasMain.current.width, canvasMain.current.height)
          requestAnimationFrame(step)
        }
      }

      requestAnimationFrame(step);

      mainVideoRef.current.addEventListener('play', () => {
        requestAnimationFrame(step);
      });
    }
  }, [mainVideoRef, mainVideo]);

  useEffect(() => {
    if(snippetVideoRef) {
      const step = () => {
        const ctx = canvasSnippet.current.getContext("2d");
        ctx.drawImage(snippetVideoRef.current, 0, 0, canvasSnippet.current.width, canvasSnippet.current.height);
        requestAnimationFrame(step);
      }

      requestAnimationFrame(step);

      snippetVideoRef.current.addEventListener('play', () => {
        requestAnimationFrame(step);
      });
    }
  }, [snippetVideoRef, snippetVideo]);

  useEffect(() => {
    mainVideoRef.current.setAttribute('src', mainVideo);
  }, [mainVideo]);

  useEffect(() => {
    if(clearData) {
      setTimeout(() => {
        setClearData(false);
      }, 1000);
    }
  }, [clearData]);

  useEffect(() => {
    if(uploadStatus) {
      setError("");
      scrollToTop();
    }
  }, [uploadStatus]);

  useEffect(() => {
    if(error) {
      setUploadStatus("");
    }
  }, [error]);

  useEffect(() => {
    if(generatedHtml) {
      setTimeout(() => {
        setGeneratedHtml('');
      }, 2000);
    }
  }, [generatedHtml]);

  const handleFileUpload = (e, stateFunc, stateFileFunc) => {
    const file = e.target.files[0];
    const fileUrl = window.URL.createObjectURL(file);
    stateFileFunc(file);
    stateFunc(fileUrl);
  }

  const changeMsBy = (val) => {
    if(frameType === 0) {
      const newTime = currentTime + val;
      setCurrentTime(newTime);
      mainVideoRef.current.currentTime = newTime;
    }
    else {
      const newTime = currentTimeSnippet + val;
      setCurrentTimeSnippet(newTime);
      snippetVideoRef.current.currentTime = newTime;
    }
  }

  const addMs = () => {
    changeMsBy(0.1);
  }

  const subtractMs = () => {
    changeMsBy(-0.1);
  }

  const clearVideoInput = () => {
    setMainVideo(null);
    setMainVideoFile(null);
    mainVideoRef.current.value = null;

    clearCanvasInput(canvasMain);
  }

  const clearSnippetVideoInput = () => {
    setSnippetVideo(null);
    setSnippetVideoFile(null);
    snippetVideoRef.current.value = null;

    clearCanvasInput(canvasSnippet);
  }

  const clearCanvasInput = (canvas) => {
    const ctxSnippet = canvas.current.getContext("2d");
    ctxSnippet.strokeStyle = "blue";
    ctxSnippet.rect(0, 0, canvas.width, canvas.height);
    ctxSnippet.fill();
  }

  const clearPlaceholderInput = () => {
    setPlaceholderFile(null);
    setPlaceholder(null);
    placeholderRef.current.value = null;
  }

  const handleSubmit = () => {
    let actionFunction, params;

    if(updateMode) {
      if(validateUpdate()) {
        actionFunction = updateVideo;
        params = [videoId, mainVideoFile, snippetVideoFile, placeholderFile, teamId, productId,
          mainVideoData, snippetVideoData, placeholderImageData];
      }
      else {
        scrollToTop();
      }
    }
    else {
      if(validateAdd()) {
        actionFunction = addVideo;
        params = [mainVideoFile, snippetVideoFile, placeholderFile, teamId, productId];
      }
      else {
        scrollToTop();
      }
    }

    setSubmitting(true);
    actionFunction(...params)
      .then((res) => {
        setSubmitting(false);
        if(res) {
          refreshPage();
        }
        else {
          setUploadStatus(err);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        setUploadStatus(err);
      });
  }

  const validateAdd = () => {
    if(!(mainVideoFile && placeholderFile)) {
      setError("Musisz dodać główny plik video oraz placeholder");
      return false;
    }
    return true;
  }

  const validateUpdate = () => {
    if(!((mainVideoFile || mainVideo) && (placeholderFile || placeholder))) {
      setError("Musisz dodać główny plik video oraz placeholder");
      return false;
    }
    return true;
  }

  const convertCanvasToBlob = () => {
    if(frameType === 0 && canvasMain) {
      convertCurrentCanvasToBlob(canvasMain);
    }
    else if(frameType === 1 && canvasSnippet) {
      convertCurrentCanvasToBlob(canvasSnippet);
    }
  }

  const convertCurrentCanvasToBlob = (canvas) => {
    canvas.current.toBlob( (blob) => {
      const file = new File( [ blob ], "klatka.png" );
      const dT = new DataTransfer();
      dT.items.add( file );
      const fileUrl = window.URL.createObjectURL(file);
      setPlaceholder(fileUrl);
      setPlaceholderFile(file);
    });
  }

  const generateHtml = () => {
    getVideoHtml(productId)
      .then((res) => {
        if(res?.status === 200) {
          copyToClipboard(res?.data);
          setGeneratedHtml('Wygenerowany HTML został skopiowany');
        }
      });
  }

  return <div className="container container--shopList">
    <LoggedUserHeader />
    <BackButton />

    {generatedHtml ? <span className="info info--success info--successWithBorder info--fixed">
                         {generatedHtml}
                     </span> : ''}

    <div className="videoEditorContainer">
      <div className="content">
        <div className="main w">
          <h1 className="main__header main__header--flex main__header--cms">
            Edytor video dla: {productName}

            {hasVideo ? <button className="btn btn--htmlPreview"
                                onClick={generateHtml}>
              Skopiuj HTML
            </button> : ''}

            <SuccessTextInfoWithBorder content={uploadStatus} />
          </h1>
        <div className="row editor__files">
          <VideoUploader label={'Główny plik video'}
                         video={mainVideo}
                         setVideo={setMainVideo}
                         setVideoFile={setMainVideoFile}
                         setCurrentTime={setCurrentTime}
                         clearVideoInput={clearVideoInput}
                         videoRef={mainVideoRef}
                         handleFileUpload={handleFileUpload} />

          <VideoUploader label={'Snippet video'}
                         video={snippetVideo}
                         setVideo={setSnippetVideo}
                         setVideoFile={setSnippetVideoFile}
                         setCurrentTime={setCurrentTimeSnippet}
                         clearVideoInput={clearSnippetVideoInput}
                         videoRef={snippetVideoRef}
                         handleFileUpload={handleFileUpload} />

          <ImageUploader label={'Placeholder'}
                         image={placeholder}
                         setImage={setPlaceholder}
                         setImageFile={setPlaceholderFile}
                         clearImageInput={clearPlaceholderInput}
                         imageRef={placeholderRef}
                         imageInputRef={placeholderInputRef}
                         handleFileUpload={handleFileUpload} />
        </div>

          <div className="row editor__secondRow">
            <ChooseFrameHeader frameType={frameType}
                               setFrameType={setFrameType} />

            <div className="canvasWrapper">
              <canvas className={frameType === 0 ? '' : 'h-0'} ref={canvasMain} id="canvasMain"></canvas>
              <canvas className={frameType === 1 ? '' : 'h-0'} ref={canvasSnippet} id="canvasSnippet"></canvas>
            </div>

            <label className={!mainVideo ? "opacity-0 h-0" : ""}>
              Ustaw odtwarzacz
              <input className="currentTimeInput"
                     readOnly={true}
                     value={displayVideoTimer(currentTime)}
              />
            </label>

            <AddAndSubtractFrameButtons addMs={addMs}
                                        subtractMs={subtractMs}
                                        mainVideo={mainVideo} />

            <button className="btn btn--convertCanvasToBlobBtn"
                    onClick={() => { convertCanvasToBlob(placeholderInputRef); }}>
              Pobierz zdjęcie z aktualnej klatki
            </button>
        </div>

        {submitting ? <div className="loader">
          <Loader />
        </div> : <button className="btn btn--submit btn--submitVideo"
                         onClick={handleSubmit}>
          {updateMode ? "Zaktualizuj video" : "Dodaj video"}
        </button>}
      </div>
      </div>
    </div>
  </div>
};

export default VideoEditor;
