import React, { useEffect, useState } from 'react';
import InputPrimary from './InputPrimary';
import SubmitButton from './SubmitButton';
import { createAllegroAccount, updateAllegroAccount } from '../helpers/allegro';
import { getLoggedUserId } from '../helpers/others';
import ErrorTextInfo from './ErrorTextInfo';
import SuccessTextInfo from './SuccessTextInfo';

const AddAllegroShopForm = ({websiteId, setUpdateAccountsList, accountToEdit}) => {
  const [allegroAccountId, setAllegroAccountId] = useState(0);
  const [name, setName] = useState('');
  const [updateMode, setUpdateMode] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if(accountToEdit) {
      setUpdateMode(true);

      setAllegroAccountId(accountToEdit.id);
      setName(accountToEdit.name);
    }
    else {
      setUpdateMode(false);
      setName('');
    }
  }, [accountToEdit]);

  const handleSubmit = () => {
    if(name) {
      if(updateMode) {
        updateAllegroAccount(allegroAccountId, websiteId, name)
          .then((res) => {
            setSuccess('Dane konta zostały zaktualizowane');
            setUpdateAccountsList(p => !p);
          })
          .catch(() => {
            setError(true);
          });
      }
      else {
        createAllegroAccount(getLoggedUserId(), websiteId, name)
          .then((res) => {
            setSuccess('Konto Allegro zostało dodane');
            setUpdateAccountsList(p => !p);
          })
          .catch(() => {
            setError(true);
          });
      }
    }
  }

  useEffect(() => {
    if(success) {
      setTimeout(() => {
        setSuccess('');
      }, 3000);

      if(!updateMode) {
        setName('');
      }
    }
  }, [success]);

  return <div className="allegro__form">
    <InputPrimary label={'Nazwa sklepu'}
                  inputClass={'input--color input--big'}
                  value={name}
                  setValue={setName} />

    {error ? <ErrorTextInfo content={error} /> :
      (success ? <SuccessTextInfo content={success} /> :
        <SubmitButton content={updateMode ? 'Zaktualizuj sklep' : 'Dodaj sklep'}
                      onClick={handleSubmit} />)}
  </div>
}

export default AddAllegroShopForm;
