import axios from 'axios';
import { getConfigWithAuthHeader, getLoggedUserUsername } from './others';

const getPackersByTeam = (id) => {
  return axios.get(`/packers/getPackersByTeam/${id}`);
}

const addNewPacker = (teamId, packerNick) => {
  return axios.post('/packers/createNewPackerUser', {
    teamId, packerNick
  });
}

const fetchPackagesAssignedToSelectedUser = (packerId, type, numberOfHours = null) => {
  let dateFrom = new Date();

  switch(type) {
    case 'TODAY':
      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      break;
    case 'LAST_24_HOURS':
      dateFrom.setHours(dateFrom.getHours() - 24);
      break;
    case 'LAST_12_HOURS':
      dateFrom.setHours(dateFrom.getHours() - 12);
      break;
    case 'LAST_N_HOURS':
      dateFrom.setHours(dateFrom.getHours() - numberOfHours);
      break;
    default:
      dateFrom = new Date('2000-01-01 00:00:00');
      break;
  }

  return axios.post('/packers/fetchPackagesAssignedToSelectedUser', {
    packer_id: packerId,
    date_from: dateFrom,
    date_to: new Date()
  });
}

const getAllPackagesAssignedToSelectedUser = (packerId) => {
  return axios.post('/packers/fetchPackagesAssignedToSelectedUser', {
    packer_id: packerId,
    date_from: new Date('2000-01-01 00:00:00'),
    date_to: new Date()
  });
}

const getOrdersByUser = () => {
  try {
    return axios.get(`/external-system-orders/getOrdersByUser/${getLoggedUserUsername()}`, getConfigWithAuthHeader());
  }
  catch(e) {
    return {
      data: []
    }
  }
}

export { getPackersByTeam, addNewPacker, fetchPackagesAssignedToSelectedUser,
  getAllPackagesAssignedToSelectedUser, getOrdersByUser }
