import React from 'react';

const SubmitButton = ({onClick, content, extraClasses}) => {
  return <button className={extraClasses ? `btn btn--submit ${extraClasses}` : `btn btn--submit`}
                 onClick={onClick}>
    {content}
  </button>
};

export default SubmitButton;
