import React, { useEffect, useState } from 'react';
import ImageDropdown from "./ImageDropdown";
import { getImageById } from '../helpers/richBlocks';
import setImageUrl from '../helpers/setImageUrl';

const Type2Block = ({block, changeImages, removeImage, readOnly}) => {
    const [img1, setImg1] = useState('');

    useEffect(() => {
        if(block) {
            if(typeof block.imagesArray[0] !== 'number') {
              setImg1(block.imagesArray[0]);
            }
            else {
              getImageById(block.imagesArray[0])
                .then((res) => {
                  if(res?.data) {
                    setImg1(setImageUrl(res?.data.file_name));
                  }
                })
            }
        }
    }, [block]);

    return <div className="blockEditor__block">
        {!readOnly ? <ImageDropdown handleFileUpload={changeImages}
                                    removeImage={removeImage}
                                    imageIndex={0}
                                    image={img1}
                                    block={block} /> : <figure>
            <img className="img" src={img1} alt="img" />
        </figure> }
    </div>
};

export default Type2Block;
