import axios from "axios";
import { getConfigWithAuthHeader, getLoggedUserUsername } from './others';

const getShopsByUser = () => {
    return axios.get(`/users-websites-relations/getShopsByUser/${getLoggedUserUsername()}`,
      getConfigWithAuthHeader());
}

const updateShop = (id, name, url, api_user, api_password,
                    api_key, api_key_restriction, image_hosting) => {
    return axios.patch(`/websites/update`, {
        id, name, url, api_user, api_password,
        api_key, api_key_restriction, image_hosting
    });
}

export { getShopsByUser, updateShop }
