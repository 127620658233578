import React from 'react';
import { Circles } from 'react-loader-spinner'

const LoadingPage = () => {
    return <div className="container container--login center">
        <Circles color="#0A73FE" height={50} width={50} />
    </div>
};

export default LoadingPage;
