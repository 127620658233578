import React from 'react';
import CloseModalButton from './CloseModalButton';

const InfoModal = ({closeModal, icon, text}) => {
    return <div className="modal modal--shopPicker"
                onClick={closeModal}>
        <div className="modal__inner"
             onClick={(e) => { e.stopPropagation(); }}>
            <CloseModalButton onClick={closeModal} />

            {icon ? <img className="img img--modal" src={icon} alt="icon" /> : ''}

            <h3 className="text text--modal">
                {text}
            </h3>

            <button className="btn btn--submit" onClick={closeModal}>
                Wróć do menu
            </button>
        </div>
    </div>
};

export default InfoModal;
