import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { createUniqueArray, groupBy, removeFalseValues } from '../helpers/others';
import { getOrdersByUser } from '../helpers/orders';

const initialState = {
  allOrders: []
}

const fetchAllOrders = createAsyncThunk('orders/getOrdersByUser', async () => {
  const response = await getOrdersByUser();
  return response.data;
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrders.fulfilled, (state, action) => {
        const responseData = action.payload;

        state.allOrders = Object.entries(groupBy(responseData, 'o_id'))
          .map((item) => (item[1]))
          .map((item) => {
            const order = item[0];

            // orderWeight and orderVolume will be calculated in UserOrders component
            return {
              id: order.o_id,
              websiteId: order.w_id,
              websiteUrl: order.w_url,
              websiteName: order.w_name,
              externalSystemId: order.o_external_id,
              status: order.o_external_system_status,
              products: JSON.parse(order.o_products_results),
              packers: removeFalseValues(createUniqueArray(item.map((item) => (item.packers_nick_name)))),
              packages: removeFalseValues(createUniqueArray(item.map((item) => (item.packages_deliveryShippingNumber)))),
              orderDate: order.o_external_system_received_date,
              packingDate: order.o_external_system_order_change_date,
              orderValue: order.o_order_products_cost100
            }
        })
    });
  }
});

export { fetchAllOrders };
export default ordersSlice.reducer;
