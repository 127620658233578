import { configureStore } from "@reduxjs/toolkit"
import richBlocksReducer from './reducers/richBlocksSlice'
import productsReducer from './reducers/productsSlice'
import apiQueueReducer from './reducers/apiQueueSlice'
import ordersReducer from './reducers/ordersSlice'
import userReducer from './reducers/userSlice'

export const store = configureStore({
    reducer: {
        richBlocks: richBlocksReducer,
        products: productsReducer,
        apiQueue: apiQueueReducer,
        orders: ordersReducer,
        user: userReducer
    }
});
